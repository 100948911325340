import { Controller, useFormContext } from 'react-hook-form'

import { Card, TextField, BlockStack } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'

const CampaignName = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <div id="campaignName">
      <Card>
        <BlockStack gap="400">
          <CardHeader title={t('campaignsDetailsCardTitle')} description={t('campaignsDetailsCardDescription')} />
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('campaignsFieldName')}
                maxLength={256}
                labelHidden
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error?.message}
              />
            )}
          />
        </BlockStack>
      </Card>
    </div>
  )
}

export { CampaignName }
