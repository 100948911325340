// React, React Query
import { buildAPIUrl } from '@/common/functions'
import { FetchFulfillmentOrdersResponse } from '@/common/types'
import { keepPreviousData, queryOptions } from '@tanstack/react-query'

const useFetchOrderFulfillments = ({ orderId }: { orderId: string }) => {
  // The keys for storing our data in the cache
  const queryKey = `${orderId}-fulfillments`

  // The URLs for our API endpoints
  const requestURL = buildAPIUrl('graphql')

  const queryFunction = async (): Promise<FetchFulfillmentOrdersResponse> => {
    return fetch(requestURL, {
      body: JSON.stringify({
        query: `query ($id: ID!) {
            order(id: $id) {
              legacyResourceId
              fulfillmentOrders(first: 250) {
                nodes {
                  id
                  status
                  assignedLocation {
                    name
                  }
                  deliveryMethod {
                    methodType
                  }
                  lineItems(first: 250) {
                    nodes {
                      id
                      sku
                      productTitle
                      variantTitle
                      image {
                        altText
                        url
                      }
                      remainingQuantity
                      totalQuantity
                    }
                  }
                }
              }
            }
          }`,
        variables: {
          id: orderId,
        },
      }),
      method: 'POST',
    })
      .then((res) => res.json())
      .then((res) => res.message.data)
  }

  const queryOpt = queryOptions({
    queryKey: [queryKey],
    queryFn: queryFunction,
    staleTime: 1000 * 60,
    placeholderData: keepPreviousData,
    select: (data) => {
      // Remove all the line items with 0 quantity (assuming they have been cancelled or refunded)
      if (data) {
        data.order.fulfillmentOrders.nodes.forEach((fulfillmentOrder) => {
          fulfillmentOrder.lineItems.nodes = fulfillmentOrder.lineItems.nodes.filter((lineItem) => lineItem.totalQuantity > 0)
        })
      }
      return data
    },
  })

  return {
    queryFunction,
    queryOptions: queryOpt,
  }
}

export { useFetchOrderFulfillments }
