import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, FormLayout } from '@shopify/polaris'
import { ColorPicker } from '@/components/shared/ColorPicker'
import { useTranslation } from 'react-i18next'

const CollectionWidgetSettings = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <FormLayout>
      <FormLayout.Group>
        <Controller
          name="purchaseOptionBadgeBackgroundColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayPurchaseOptionBadgeBackgroundColorLabel')}
              placeholder="#000000"
              defaultColor="#000000"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
            />
          )}
        />

        <Controller
          name="purchaseOptionBadgeTextColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayPurchaseOptionBadgeTextColorLabel')}
              placeholder="#ffffff"
              defaultColor="#ffffff"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
            />
          )}
        />
      </FormLayout.Group>
      <Controller
        name="showPreorderCollectionBadge"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <Checkbox
            label={t('displayShowPreorderCollectionBadgeLabel')}
            helpText={t('displayShowPreorderCollectionBadgeDescription')}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isSubmitting}
          />
        )}
      />
      <Controller
        name="showBackorderCollectionBadge"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <Checkbox
            label={t('displayShowBackorderCollectionBadgeLabel')}
            helpText={t('displayShowBackorderCollectionBadgeDescription')}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isSubmitting}
          />
        )}
      />
    </FormLayout>
  )
}

export { CollectionWidgetSettings }
