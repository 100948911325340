import { Box, BlockStack, Card, Grid, Layout, Tabs } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { BisButtonWidgetSettings } from './BisButtonWidgetSettings'
import { BisButtonWidgetPreview } from './BisButtonWidgetPreview'
import { BisModalWidgetSettings } from './BisModalWidgetSettings'
import { BisModalWidgetPreview } from './BisModalWidgetPreview'
import { BisModalWidgetSettingsSuccess } from './BisModalWidgetSettingsSuccess'
import { BisModalWidgetSettingsError } from './BisModalWidgetSettingsError'
import { useState } from 'react'

const BisWidget = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(0)

  const TABS = [
    { id: '0', content: t('displayBisTabStandard') },
    { id: '1', content: t('displayBisTabSuccess') },
    { id: '2', content: t('displayBisTabError') },
  ]

  return (
    <Layout.Section>
      <Box paddingBlockStart="200" paddingBlockEnd="400" paddingInline="0">
        <SectionTitle title={t('displayBisTitle')} description={t('displayBisDescription')} />
        <BlockStack gap="400">
          <Grid columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <Disabled isDisabled={isDisabled}>
                  <BisButtonWidgetSettings />
                </Disabled>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <BisButtonWidgetPreview />
            </Grid.Cell>
          </Grid>
          <Grid columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card padding="0">
                <Tabs tabs={TABS} selected={activeTab} onSelect={setActiveTab} fitted></Tabs>
                <Box padding="400">
                  <Disabled isDisabled={isDisabled}>
                    {activeTab === 0 && <BisModalWidgetSettings />}
                    {activeTab === 1 && <BisModalWidgetSettingsSuccess />}
                    {activeTab === 2 && <BisModalWidgetSettingsError />}
                  </Disabled>
                </Box>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              {activeTab === 0 && <BisModalWidgetPreview status="default" />}
              {activeTab === 1 && <BisModalWidgetPreview status="success" />}
              {activeTab === 2 && <BisModalWidgetPreview status="error" />}
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </Box>
    </Layout.Section>
  )
}

export { BisWidget }
