import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const BackorderSettingsFallback = () => {
  const { t } = useTranslation()
  return (
    <SkeletonPage title={t('backordersDashTitle')} primaryAction backAction>
      <Layout>
        <Layout.AnnotatedSection title={t('backorderSelectionTypeTitle')} description={t('backorderSelectionTypeDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('backorderProductsTitle')} description={t('backorderProductsIncludedDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('backorderInventoryPolicyTitle')} description={t('backorderInventoryPolicyDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}

export { BackorderSettingsFallback }
