import { BlockStack, Box, Button, Collapsible, InlineStack, ProgressBar, SkeletonBodyText, Spinner, Text } from '@shopify/polaris'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'
import type { ColorBackgroundAlias } from '@shopify/polaris-tokens'
import { LineChart, TooltipOptions } from '@shopify/polaris-viz'
import type { XAxisOptions, YAxisOptions } from '@shopify/polaris-viz-core'
import { Fragment, ReactNode, useState } from 'react'
import { Link } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@/common/functions'

/**
 * Reusable wrapper for the metric bar
 * @returns React.ReactNode
 */
type MetricBarProps = {
  blocks: React.ReactNode[]
  chart?: React.ReactNode
}

const MetricBar = ({ blocks, chart }: MetricBarProps) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const activeBlocks = blocks.filter((b) => !!b) // remove non-items

  return (
    <BlockStack gap="400">
      <InlineStack gap="400" blockAlign="stretch" align="space-between">
        <InlineStack gap="800" blockAlign="start" align="start" wrap={true}>
          {activeBlocks.map((block, index) => {
            if (block) {
              return (
                <Fragment key={index}>
                  {block}
                  {/* {index !== activeBlocks.length - 1 && <SeparatorBlock />} */}
                </Fragment>
              )
            }
          })}
        </InlineStack>
        {chart && (
          <BlockStack align="center">
            <Button variant="tertiary" onClick={() => setIsExpanded(!isExpanded)} icon={isExpanded ? ChevronUpIcon : ChevronDownIcon} />
          </BlockStack>
        )}
      </InlineStack>
      {chart && (
        <Collapsible open={isExpanded} id="chart">
          {chart}
        </Collapsible>
      )}
    </BlockStack>
  )
}

/**
 * Reusable block wrapper
 * @returns React.ReactNode
 */
type MetricBlockProps = {
  title: string
  children?: React.ReactNode
}

const MetricBlock = ({ title, children }: MetricBlockProps) => {
  return (
    <Box>
      <BlockStack gap="100">
        <MetricBlockHeader title={title} />
        {children}
      </BlockStack>
    </Box>
  )
}

/**
 * Reusable block title
 * @returns React.ReactNode
 */
type MetricBlockHeaderProps = {
  title: string
}

const MetricBlockHeader = ({ title }: MetricBlockHeaderProps) => {
  return (
    <Text as="span" variant="bodySm">
      {title}
    </Text>
  )
}

/**
 * Generic separator beteween blocks
 * @returns React.ReactNode
 */
const SeparatorBlock = () => {
  return <Box minHeight="100%" width="1px" background="bg-fill-transparent-active"></Box>
}

/**
 * Display the status alongside an indicator
 * @returns React.ReactNode
 */

type StatusBlockProps = {
  title: string
  status: string
}

const StatusBlock = ({ title, status }: StatusBlockProps) => {
  const { t } = useTranslation()

  let circleColor: ColorBackgroundAlias = 'bg-fill-inverse-active'
  let statusText = t('campaignStatusDraft')

  switch (status) {
    case 'ACTIVE':
      circleColor = 'bg-fill-success'
      statusText = t('campaignStatusActive')
      break
    case 'SCHEDULED':
      circleColor = 'bg-fill-info-active'
      statusText = t('campaignStatusScheduled')
      break
    case 'PROCESSING':
    case 'PROCESSING_PRODUCTS':
    case 'PROCESSING_CAMPAIGN':
      circleColor = 'bg-fill-warning'
      statusText = t('campaignStatusProcessing')
      break
    case 'DRAFT':
      circleColor = 'bg-fill-inverse-active'
      statusText = t('campaignStatusDraft')
      break
    case 'ARCHIVED':
      circleColor = 'bg-fill-inverse-active'
      statusText = t('campaignStatusArchived')
      break
    default:
      break
  }

  return (
    <MetricBlock title={title}>
      <InlineStack gap="200" align="start">
        <Box borderRadius="full" width="10px" minHeight="10px" background={circleColor} />
        <Text as="p" variant="headingMd">
          {statusText}
        </Text>
      </InlineStack>
    </MetricBlock>
  )
}

/**
 * Display the current plan with a link to upgrade
 * @returns React.ReactNode
 */

type PlanBlockProps = {
  title: string
  currentPlan?: string
  showPlanLink?: boolean
}

const PlanBlock = ({ currentPlan, showPlanLink = true, title }: PlanBlockProps) => {
  const { t } = useTranslation()

  return (
    <MetricBlock title={title}>
      {currentPlan ? (
        <Text as="p" variant="headingMd">
          {currentPlan}
        </Text>
      ) : (
        <SkeletonBodyText lines={1} />
      )}
      {currentPlan && showPlanLink && <Link to="/plans">{t('usageStatsChangePlanCta')}</Link>}
    </MetricBlock>
  )
}

/**
 * Display the used and total progress with a progress bar
 * @returns React.ReactNode
 */

type ProgressBlockProps = {
  title: string
  used?: number
  total?: number
}

const ProgressBlock = ({ title, used, total }: ProgressBlockProps) => {
  // Dank, but TS can't seem to reckon with this otherwise?
  let progressTone: 'highlight' | 'primary' | 'success' | 'critical' = 'success'
  let progress = used ?? 0

  if (total && total > 0) {
    if (progress / total <= 0.5) {
      progressTone = 'success'
    } else if (progress / total <= 0.7) {
      progressTone = 'highlight'
    } else {
      progressTone = 'critical'
    }
  }

  const Label = () => {
    if (used === undefined) {
      return (
        <Spinner size="small" />
        // <Text as="p" variant="headingMd">
        //   -
        // </Text>
      )
    }

    const usedFormatted = formatNumber(used)
    const totalFormatted = total ? formatNumber(total) : undefined

    return (
      <Text as="p" variant="headingMd">
        {usedFormatted} {totalFormatted && `/ ${totalFormatted}`}
      </Text>
    )
  }

  return (
    <MetricBlock title={title}>
      <BlockStack gap="200">
        <Label />
        <ProgressBar progress={total && total > 0 ? (progress / total) * 100 : 0} size="small" tone={progressTone} />
      </BlockStack>
    </MetricBlock>
  )
}

/**
 * Display a single value
 * @returns React.ReactNode
 */

type ValueBlockProps = {
  title: string
  value: string | ReactNode
}

const ValueBlock = ({ title, value }: ValueBlockProps) => {
  return (
    <MetricBlock title={title}>
      <Text as="span" variant="bodyLg" fontWeight="semibold">
        {value}
      </Text>
    </MetricBlock>
  )
}

/**
 * Display a single value
 * @returns React.ReactNode
 */
type ChartProps = {
  data: any
  xAxisOptions?: Partial<XAxisOptions>
  yAxisOptions?: Partial<YAxisOptions>
  tooltipOptions?: Partial<TooltipOptions>
}

const Chart = ({ data, xAxisOptions, yAxisOptions, tooltipOptions }: ChartProps) => {
  return <LineChart showLegend={false} theme="lineTheme" data={data} xAxisOptions={xAxisOptions} yAxisOptions={yAxisOptions} tooltipOptions={tooltipOptions} />
}

MetricBar.Separator = SeparatorBlock
MetricBar.ProgressBlock = ProgressBlock
MetricBar.PlanBlock = PlanBlock
MetricBar.StatusBlock = StatusBlock
MetricBar.ValueBlock = ValueBlock
MetricBar.Chart = Chart

export { MetricBar }
