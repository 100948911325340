import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, EmptySearchResult, IndexTable, Text } from '@shopify/polaris'
import { useSuspenseQuery } from '@tanstack/react-query'
import { bisListContactQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { formatDateForShopify } from '@/common/datetime'
import type { EmailEventType } from '@/common/types'
import { EmailEventStatusBadge } from '@/components/shared/EmailEventStatusBadge'

type TableRowProps = {
  type: EmailEventType
  created_at: string
}

const BackInStockEmailEventsTable = ({ contactId }: { contactId: string }) => {
  const { t } = useTranslation()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { data } = useSuspenseQuery(bisListContactQueryOptions({ id: contactId }))

  const rows: TableRowProps[] = useMemo(
    () =>
      data?.email_send?.resendEvents
        ?.sort(function (a, b) {
          if (a.created_at < b.created_at) return -1
          if (a.created_at > b.created_at) return 1
          return 0
        })
        .map((event) => ({
          type: (event.type as EmailEventType) || 'email.sent',
          created_at: formatDateForShopify(event.created_at, settings.timezone),
        })) || [],
    [data, settings.timezone, t]
  )

  const resourceName = {
    singular: t('backInStockEmailEventsTableResourceName'),
    plural: t('backInStockEmailEventsTableResourceNamePlural'),
  }

  const EmptySearchResultMarkup = (
    <EmptySearchResult title={t('backInStockEmailEventsTableEmptyTitle')} description={t('backInStockEmailEventsTableEmptyDescription')} withIllustration />
  )

  return (
    <Card padding="0">
      <IndexTable
        selectable={false}
        resourceName={resourceName}
        itemCount={rows.length}
        headings={[{ title: t('backInStockEmailEventsTableFieldType') }, { title: t('backInStockEmailEventsTableFieldCreatedAt') }]}
        emptyState={EmptySearchResultMarkup}
      >
        {rows.map((row, index) => (
          <TableRow key={`${row.type}-${index}`} {...row} index={index} />
        ))}
      </IndexTable>
    </Card>
  )
}

const TableRow: React.FC<TableRowProps & { index: number }> = ({ type, created_at, index }) => (
  <IndexTable.Row id={`${type}-${index}`} position={index}>
    <IndexTable.Cell>
      <Text as="span" alignment="start">
        <EmailEventStatusBadge status={type} />
      </Text>
    </IndexTable.Cell>
    <IndexTable.Cell>
      <Text as="span">{created_at}</Text>
    </IndexTable.Cell>
  </IndexTable.Row>
)

export { BackInStockEmailEventsTable }
