import React, { createContext, useContext } from 'react'
import { Banner, BlockStack } from '@shopify/polaris'

const DisabledContext = createContext(false)

interface Props {
  children: React.ReactNode
  isDisabled: boolean
  isLowerOpacity?: boolean
  message?: string | JSX.Element
}

const Disabled = ({ children, isDisabled = false, isLowerOpacity = true, message }: Props) => {
  const isParentDisabled = useContext(DisabledContext)

  return (
    <DisabledContext.Provider value={isDisabled || isParentDisabled}>
      <BlockStack gap="400">
        {isDisabled && message && <Banner tone="info">{message}</Banner>}
        <div style={isDisabled && isLowerOpacity && !isParentDisabled ? { pointerEvents: 'none', opacity: 0.6 } : {}}>{children}</div>
      </BlockStack>
    </DisabledContext.Provider>
  )
}

export { Disabled }
