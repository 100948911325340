import { useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'

import { Card, DatePicker, Icon, Popover, TextField } from '@shopify/polaris'
import type { Range } from '@shopify/polaris'
import { CalendarIcon } from '@shopify/polaris-icons'

import dayjs from '@/common/datetime'
import { useTranslation } from 'react-i18next'

type Props = {
  label: string
  date: string | null
  displayTimezone?: string
  onChange: (date: string) => void
  disabled?: boolean
  error?: FieldError | undefined
}

const DatePickerField = ({ label, date: initialDate, displayTimezone = 'UTC', error, disabled = false, onChange }: Props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  // If there's no date, default to today
  const date = initialDate ?? dayjs().tz(displayTimezone).startOf('day').toISOString()

  // We need to get start of date at the stores
  //const date = initialDate ?? dayjs().tz(displayTimezone, true).startOf('day').toISOString()
  const dateAtStore = dayjs(date).tz(displayTimezone)

  const [{ month, year }, setVisibleDate] = useState({
    month: dateAtStore.get('month'),
    year: dateAtStore.get('year'),
  })

  const displayValue = initialDate ? dateAtStore.format('YYYY-MM-DD') : t('genericSelectDate')

  function handleMonthChange(month: number, year: number) {
    setVisibleDate({ month, year })
  }

  function handleDateSelection({ end }: Range) {
    const adjustedTime = dayjs(end).tz(displayTimezone, true).set('hour', dateAtStore.hour()).set('minute', dateAtStore.minute()).set('second', 0)

    onChange(adjustedTime.tz('UTC').toISOString())
    setVisible(false)
  }

  useEffect(() => {
    if (date) {
      setVisibleDate({
        month: dateAtStore.get('month'),
        year: dateAtStore.get('year'),
      })
    }
  }, [date])

  return (
    <Popover
      active={visible}
      autofocusTarget="none"
      preferredAlignment="left"
      fullWidth
      preferInputActivator={false}
      preferredPosition="below"
      preventCloseOnChildOverlayClick
      onClose={() => setVisible(false)}
      activator={
        <TextField
          role="combobox"
          label={label}
          prefix={<Icon source={CalendarIcon} />}
          value={displayValue}
          onFocus={() => setVisible(true)}
          onChange={() => {}}
          autoComplete="off"
          disabled={disabled}
          error={error?.message}
        />
      }
    >
      <Card>
        <DatePicker
          month={month}
          year={year}
          selected={initialDate ? new Date(dayjs(dateAtStore).tz(dayjs.tz.guess(), true).toISOString()) : undefined}
          onMonthChange={handleMonthChange}
          onChange={handleDateSelection}
        />
      </Card>
    </Popover>
  )
}

export { DatePickerField }
