import { buildAPIUrl } from '@/common/functions'
import { widgetQueryOptions } from '@/common/queryOptions'
import { MutateWidgetPayload } from '@/common/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useMutateWidget = (uuid: string | undefined) => {
  const queryClient = useQueryClient()
  const searchParams = new URLSearchParams()

  if (uuid) searchParams.set('widgetId', uuid)

  const requestURL = buildAPIUrl('widget', searchParams)

  return useMutation({
    mutationFn: (data: Partial<MutateWidgetPayload> = {}) =>
      fetch(requestURL, {
        body: JSON.stringify({
          ...data,
          uuid,
        }),
        method: 'PUT',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error updating widget'
          }
        })
        .then((res) => res.message),
    onSettled() {
      // TODO: Remove this once undefined case is resolved
      if (uuid) {
        queryClient.invalidateQueries({
          queryKey: widgetQueryOptions({
            widgetId: uuid,
          }).queryKey,
        })
      }
    },
  })
}
