import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'

import { Layout, Banner } from '@shopify/polaris'

import { zodResolver } from '@hookform/resolvers/zod'

import { FetchWidgetResponse, MutateWidgetPayload } from '@/common/types'

import { z } from 'zod'
import { useMutateWidget } from '@/hooks/useMutateWidget'
import { useTranslation } from 'react-i18next'
import { SaveBar } from '@shopify/app-bridge-react'
import { PreorderWidget } from './PreorderWidget'
import { BackorderWidget } from './BackorderWidget'
import { CollectionWidget } from './CollectionWidget'
import { useMutateSettings } from '@/hooks/useMutateSettings'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { settingsQueryOptions } from '@/common/queryOptions'
import useScrollToError from '@/hooks/useScrollToError'
import { BisWidget } from './BisWidget'

type Props = {
  data?: FetchWidgetResponse
  campaignId?: string
  isDisabled: boolean
  sections: ('preorder' | 'backorder' | 'collection' | 'bis')[]
}

const schema = z.object({
  addToCartButtonText: z.string().trim().optional().nullable().default(''),
  addToCartButtonTextColor: z.string().optional().nullable().default(''),
  addToCartButtonBackgroundColor: z.string().optional().nullable().default(''),
  sellingPlanBadgeTextColor: z.string().nullable().default(''),
  sellingPlanBadgeBackgroundColor: z.string().nullable().default(''),
  fulfilmentDateNote: z.string().trim().optional().nullable().default(''),
  partialPaymentNote: z.string().trim().optional().nullable().default(''),
  comingSoonAddToCartButtonText: z.string().trim().optional().nullable().default(''),
  soldOutAddToCartButtonText: z.string().trim().optional().nullable().default(''),
  comingSoonNote: z.string().trim().optional().nullable().default(''),
  soldOutNote: z.string().trim().optional().nullable().default(''),
  backorderAddToCartButtonText: z.string().trim().optional().nullable().default(''),
  backorderAddToCartButtonTextColor: z.string().optional().nullable().default(''),
  backorderAddToCartButtonBackgroundColor: z.string().optional().nullable().default(''),
  backorderNote: z.string().trim().optional().nullable().default(''),
  showPreorderCollectionBadge: z.boolean().default(true),
  showBackorderCollectionBadge: z.boolean().default(true),
  purchaseOptionBadgeTextColor: z.string().optional().nullable().default(''),
  purchaseOptionBadgeBackgroundColor: z.string().optional().nullable().default(''),
  bisButtonTextColor: z.string().optional().nullable().default(''),
  bisButtonBackgroundColor: z.string().optional().nullable().default(''),
  bisButtonText: z.string().trim().optional().nullable().default(''),
  bisModalTextColor: z.string().optional().nullable().default(''),
  bisModalBackgroundColor: z.string().optional().nullable().default(''),
  bisModalTitle: z.string().optional().nullable().default(''),
  bisModalDescription: z.string().optional().nullable().default(''),
  bisModalEmailPlaceholder: z.string().optional().nullable().default(''),
  bisModalMarketingConsentLabel: z.string().optional().nullable().default(''),
  bisFormSuccessMessage: z.string().optional().nullable().default(''),
  bisFormErrorMessage: z.string().optional().nullable().default(''),
})

export type FormSchema = z.infer<typeof schema>

const DisplayForm = ({ data, campaignId, isDisabled, sections }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  // Mutation to modify the campaign schedule
  const settingsMutation = useMutateSettings()

  // Mutation to modify the widget settings
  const mutateWidget = useMutateWidget(data?.uuid)

  // Local state to immediately hide banner on dismiss
  const [isPreviewEstimationLocallyDismissed, setIsPreviewEstimationLocallyDismissed] = useState(false)

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutateAsync({ dismissFlags })
  }

  const form = useForm<FormSchema>({
    defaultValues: schema.parse(data ?? {}),
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  useEffect(() => {
    reset(schema.parse(data ?? {}))
  }, [data])

  const [setCanFocus] = useScrollToError(form.formState.errors)

  // Create our actions
  const onSubmit: SubmitHandler<FormSchema> = async (formValues) => {
    try {
      await mutateWidget.mutateAsync(formValues, {
        onSuccess: (data) => {
          reset(schema.parse(data))

          queryClient.invalidateQueries({
            queryKey: settingsQueryOptions().queryKey,
          })

          shopify.toast.show(t('displayUpdatedToast'), {
            duration: 2000,
          })
          return data
        },
      })
    } catch (error) {
      console.log(error)
      shopify.toast.show(t('genericErrorToast'), {
        duration: 2000,
        isError: true,
      })
    }
  }

  const onError: SubmitErrorHandler<MutateWidgetPayload> = (e) => {
    console.log('error', e)
    setCanFocus(true)

    shopify.toast.show(t('genericErrorToast'), {
      duration: 2000,
      isError: true,
    })
  }

  const showPreorderSettings = sections.includes('preorder')
  const showBackorderSettings = sections.includes('backorder')
  const showCollectionSettings = sections.includes('collection')
  const showBisSettings = sections.includes('bis')

  return (
    <FormProvider {...form}>
      <SaveBar id="save-bar" open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={mutateWidget.isPending}
          loading={mutateWidget.isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={mutateWidget.isPending}></button>
      </SaveBar>

      {!isPreviewEstimationLocallyDismissed && settings.dismissFlags?.previewEstimation !== true && !campaignId && (
        <Layout.Section>
          <Banner
            tone="info"
            onDismiss={() => {
              onDismiss({ ...settings.dismissFlags, previewEstimation: true })
              setIsPreviewEstimationLocallyDismissed(true)
            }}
          >
            {t('displayPreviewBanner')}
          </Banner>
        </Layout.Section>
      )}

      {showBackorderSettings && <BackorderWidget isDisabled={mutateWidget.isPending || isDisabled} />}
      {showPreorderSettings && <PreorderWidget isDisabled={mutateWidget.isPending || isDisabled} />}
      {showCollectionSettings && <CollectionWidget isDisabled={mutateWidget.isPending || isDisabled} />}
      {showBisSettings && <BisWidget isDisabled={mutateWidget.isPending || isDisabled} />}
    </FormProvider>
  )
}

export { DisplayForm }
