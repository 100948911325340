import { BEACON_ID } from '@/common/constants'
import { settingsQueryOptions, reportsUsageQueryOptions } from '@/common/queryOptions'
import { useQuery } from '@tanstack/react-query'

export const useBeacon = () => {
  const { data: settings } = useQuery(settingsQueryOptions())
  const { data: usageData } = useQuery(reportsUsageQueryOptions())

  const hasBeacon = window.Beacon('info')

  const registerBeacon = () => {
    window.Beacon('config', { hideFABOnMobile: true })
    window.Beacon('init', BEACON_ID)
  }

  const populateBeacon = () => {
    window.Beacon('identify', {
      'shop-id': shopify.config.shop,
      'app-plan': usageData?.plan.name,
      'shop-timezone': settings?.timezone,
    })
  }

  return {
    hasBeacon,
    registerBeacon,
    populateBeacon,
  }
}
