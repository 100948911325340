import { formatSecondsAsTime } from '@/common/datetime.ts'
import { Campaign } from '@/common/types'
import { Checklist } from '@/components/shared/Checklist'
import { useChecklist } from '@/hooks/useChecklist'
import { Layout } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const stages = [
  {
    id: 'PROCESSING',
    statuses: ['PROCESSING'],
  },
  {
    id: 'UPDATING_PRODUCTS',
    statuses: ['PROCESSING_PRODUCTS'],
  },
  {
    id: 'UPDATING_CAMPAIGN',
    statuses: ['PROCESSING_CAMPAIGN'],
  },
  {
    id: 'COMPLETE',
    statuses: ['PROCESSING_COMPLETE'],
  },
]

/**
 * Manage the status checkist for a campaign
 * @returns
 */
const CampaignPublishingQueue = ({
  status,
  productCount,
  isContinueSellingManaged,
}: {
  status: Campaign['status']
  productCount: number
  isContinueSellingManaged: boolean
}) => {
  const { t } = useTranslation()
  const [inFlow, setInFlow] = useState<boolean>(false)
  const { items, addItems, updateItem, clearItems, title, setTitle, description, setDescription, isAllItemsComplete } = useChecklist()
  const estProductTime = formatSecondsAsTime(isContinueSellingManaged ? productCount * 1.6 + 5 : 5)

  const onDismiss = () => {
    setInFlow(false)
  }

  /**
   * Check the current status and update the checklist item status based on if they came before or after the current status
   */
  const updateListItemStatus = () => {
    const statusIndex = stages.findIndex((stage) => stage.statuses.includes(status))

    items.forEach(({ id }) => {
      const itemIndex = stages.findIndex((stage) => stage.id === id)

      switch (true) {
        case itemIndex < statusIndex:
        case statusIndex === -1:
          updateItem(id, { status: 'COMPLETE' })
          break
        case itemIndex === statusIndex:
          updateItem(id, { status: 'IN_PROGRESS' })
          break
        default:
          updateItem(id, { status: 'INCOMPLETE' })
          break
      }
    })
  }

  /**
   * Populate the checklist based on the current flow
   */
  useEffect(() => {
    clearItems()

    setTitle(t('campaignsChecklistUpdatingTitle'))
    setDescription(t('campaignsChecklistProcessingDescription'))
    addItems([
      {
        id: 'PROCESSING',
        title: t('campaignsChecklistItemProcessing'),
        status: 'INCOMPLETE',
      },
      {
        id: 'UPDATING_PRODUCTS',
        title: t('campaignsChecklistItemUpdatingProducts', {
          count: productCount,
          eta: estProductTime,
        }),
        status: 'INCOMPLETE',
      },
      {
        id: 'UPDATING_CAMPAIGN',
        title: t('campaignsChecklistItemUpdatingCampaign'),
        status: 'INCOMPLETE',
      },
      {
        id: 'COMPLETE',
        title: t('campaignsChecklistItemUpdated'),
        status: 'INCOMPLETE',
      },
    ])
  }, [])

  /**
   * If we don't know the flow, determine it based on the status
   */
  useEffect(() => {
    // If the backorderStatus is in any of the status arrays from the stages variable, set inFlow true
    const isInFlow = stages.some((stage) => stage.statuses.includes(status))
    if (isInFlow) setInFlow(true)
  }, [status])

  useEffect(() => {
    updateItem('UPDATING_PRODUCTS', {
      title: t('campaignsChecklistItemUpdatingProducts', {
        count: productCount || 0,
        eta: estProductTime,
      }),
    })
  }, [productCount])

  useEffect(() => {
    // We don't have a flow or items
    if (!items.length) return

    if (isAllItemsComplete) {
      setTitle(t('campaignsChecklistUpdatedTitle'))
      setDescription('')
    } else {
      setTitle(t('campaignsChecklistUpdatingTitle'))
      setDescription(t('campaignsChecklistProcessingDescription'))
    }

    updateListItemStatus()
  }, [status, items.length, isAllItemsComplete])

  if (!inFlow) return null

  return (
    <Layout.Section>
      <Checklist title={title} description={description} items={items} onDismiss={onDismiss} />
    </Layout.Section>
  )
}

export { CampaignPublishingQueue }
