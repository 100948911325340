import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { Layout } from '@shopify/polaris'
import { SaveBar } from '@shopify/app-bridge-react'
import { z } from 'zod'
import { useMutateBisSettings } from '@/hooks/useMutateBisSettings'
import { useTranslation } from 'react-i18next'
import { Disabled } from '../shared/Disabled'
import useScrollToError from '@/hooks/useScrollToError'
import { useQueryClient, useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query'
import { bisSettingQueryOptions, settingsQueryOptions, shopifyResourcePickerQueryOptions } from '@/common/queryOptions'
import { ProductSettings } from './ProductSettings'
import { ProductSelection } from './ProductSelection'
import { BatchSettings } from './BatchSettings'
import { SendMethod } from './SendMethod'
import { MutateBISPayload } from '@/common/types'
import { useNavigate } from '@tanstack/react-router'
import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = z.object({
  bisProducts: z
    .array(
      z.object({
        id: z.string(),
        title: z.string(),
        featuredImage: z
          .object({
            url: z.string(),
          })
          .optional(),
        variants: z
          .array(
            z.object({
              id: z.string(),
            })
          )
          .optional(),
        totalVariants: z.number().optional(),
      })
    )
    .default([]),
  bisSelectionType: z.enum(['INCLUDE', 'EXCLUDE']).default('EXCLUDE'),
  isAutomaticSend: z.boolean().default(true),
  automaticSendThreshold: z.number().default(1).nullable(),
  batchMultiplier: z.number().default(5),
  batchDelay: z.number().default(240),
  sortingMethod: z.enum(['OLDEST', 'NEWEST']).default('NEWEST'),
})

export type FormSchema = z.infer<typeof schema>

const BackInStockSettingsForm = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const [{ data: bisSettings }, { data: shopifyProducts }] = useSuspenseQueries({
    queries: [bisSettingQueryOptions({ uuid: settings.bisSettingsId }), shopifyResourcePickerQueryOptions({ productIds: settings.bisProductIds })],
  })

  const defaultValues = {
    bisProducts: shopifyProducts,
    bisSelectionType: settings.bisSelectionType,
    isAutomaticSend: bisSettings.isAutomaticSend,
    automaticSendThreshold: bisSettings.automaticSendThreshold,
    batchMultiplier: bisSettings.batchMultiplier,
    batchDelay: bisSettings.batchDelay,
    sortingMethod: bisSettings.sortingMethod,
  } satisfies FormSchema

  const form = useForm<FormSchema>({
    defaultValues: schema.parse(defaultValues ?? {}),
    mode: 'onChange',
    resolver: zodResolver(
      schema.refine(
        (data) => {
          console.log(data)
          if (data.isAutomaticSend) {
            return data.automaticSendThreshold && data.automaticSendThreshold >= 1
          }

          return true
        },
        {
          message: t('backInStockValidationSendThreshold'),
          path: ['automaticSendThreshold'],
        }
      )
    ),
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = form

  const selectionType = watch('bisSelectionType')

  const mutateSettings = useMutateBisSettings()

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onError: SubmitErrorHandler<FormSchema> = () => {
    setCanFocus(true)

    shopify.toast.show(t('genericErrorToast'), {
      duration: 2000,
      isError: true,
    })
  }

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    // Grab the product IDs from the form
    const { bisProducts, bisSelectionType, ...formValues } = data

    // Build up the mutation payload
    let payload = {
      bisSelectionType: bisSelectionType,
      bisProductIds: bisProducts.map((product) => product.id),
      bis_setting: {
        uuid: settings.bisSettingsId,
        ...formValues,
      },
    } satisfies MutateBISPayload

    try {
      await mutateSettings.mutateAsync(payload, {
        onSuccess: (data) => {
          shopify.saveBar.hide('bis-save-bar')

          console.log('queryKey', bisSettingQueryOptions({ uuid: settings.bisSettingsId }).queryKey)

          queryClient.invalidateQueries({
            queryKey: settingsQueryOptions().queryKey,
          })

          queryClient.invalidateQueries({
            queryKey: bisSettingQueryOptions({ uuid: settings.bisSettingsId }).queryKey,
          })

          queryClient.invalidateQueries({
            queryKey: ['shopify-resource-picker'],
          })

          shopify.toast.show(t('settingsUpdatedToast'), {
            duration: 2000,
          })

          navigate({ to: '/back-in-stock' })

          return data
        },
      })
    } catch (error) {
      console.log(error)
      shopify.toast.show(t('genericErrorToast'), {
        duration: 2000,
        isError: true,
      })
    }
  }

  useEffect(() => {
    if (isDirty) {
      shopify.saveBar.show('bis-save-bar')
    } else {
      shopify.saveBar.hide('bis-save-bar')
    }
  }, [isDirty])

  return (
    <FormProvider {...form}>
      <SaveBar id="bis-save-bar">
        <button
          variant="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={mutateSettings.isPending}
          loading={mutateSettings.isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={mutateSettings.isPending}></button>
      </SaveBar>
      <Layout.AnnotatedSection title={t('backInStockSelectionTypeTitle')} description={t('backInStockSelectionTypeDescription')}>
        <Disabled isDisabled={mutateSettings.isPending}>
          <ProductSettings />
        </Disabled>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={t('backInStockProductsTitle')}
        description={selectionType === 'EXCLUDE' ? t('backInStockProductsExcludedDescription') : t('backInStockProductsIncludedDescription')}
      >
        <Disabled isDisabled={mutateSettings.isPending}>
          <ProductSelection />
        </Disabled>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={t('backInStockSendTitle')} description={t('backInStockSendDescription')}>
        <Disabled isDisabled={mutateSettings.isPending}>
          <SendMethod />
        </Disabled>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={t('backInStockDeliveryTitle')} description={t('backInStockDeliveryDescription')}>
        <Disabled isDisabled={mutateSettings.isPending}>
          <BatchSettings />
        </Disabled>
      </Layout.AnnotatedSection>
    </FormProvider>
  )
}

export { BackInStockSettingsForm }
