import { buildAPIUrl } from '@/common/functions'
import { settingsQueryOptions } from '@/common/queryOptions'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useMutateVerifyDns = () => {
  const queryClient = useQueryClient()

  const requestURL = buildAPIUrl('domain/verify')

  return useMutation({
    mutationFn: () =>
      fetch(requestURL, {
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error checking DNS status'
          }
        })
        .then((res) => res.message),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: settingsQueryOptions().queryKey,
        exact: true,
      })
    },
  })
}
