import { buildAPIUrl } from '@/common/functions'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteWidget = (uuid: string | undefined) => {
  const queryClient = useQueryClient()
  const searchParams = new URLSearchParams()
  if (uuid) searchParams.set('id', uuid)

  const requestURL = buildAPIUrl('campaignWidget', searchParams)

  return useMutation({
    mutationFn: () =>
      fetch(requestURL, {
        method: 'DELETE',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error deleting widget'
          }
        })
        .then((res) => res.message),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [`campaign`],
      })
    },
  })
}
