import { createRootRouteWithContext, Outlet, ScrollRestoration, Link } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { Trans, useTranslation } from 'react-i18next'
import { FooterHelp, Frame } from '@shopify/polaris'
import type { QueryClient } from '@tanstack/react-query'
import { useBeacon } from '@/hooks/useBeacon'
import { NavMenu } from '@shopify/app-bridge-react'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: Component,
})

function Component() {
  const { t } = useTranslation()

  const { hasBeacon, registerBeacon, populateBeacon } = useBeacon()

  if (!hasBeacon) {
    registerBeacon()
  }

  populateBeacon()

  return (
    <Frame>
      <NavMenu>
        <Link to="/" rel="home">
          {t('navDashboard')}
        </Link>
        <Link to="/preorders">{t('navPreorders')}</Link>
        <Link to="/backorders">{t('navBackorders')}</Link>
        <Link to="/back-in-stock">{t('navBackInStock')}</Link>
        <Link to="/display">{t('navDisplay')}</Link>
        <Link to="/settings">{t('navSettings')}</Link>
        <Link to="/plans">{t('navAppPlans')}</Link>
        <Link to="/emails">{t('navEmailTemplates')}</Link>
        <Link to="/localization">{t('navLocalization')}</Link>
      </NavMenu>
      <ScrollRestoration />
      <Outlet />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
      <div>
        <FooterHelp align="center">
          <Trans t={t} i18nKey="footerMessage">
            .
            <a target="_blank" href="https://www.shopside.com.au">
              .
            </a>
          </Trans>
        </FooterHelp>
      </div>
    </Frame>
  )
}
