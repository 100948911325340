import { buildAPIUrl, stripGids } from '@/common/functions'
import {
  FetchBISSettingsResponse,
  FetchSettingsResponse,
  FetchOrdersResponse,
  FetchShopifyCampaignProducts,
  FetchCampaignResponse,
  FetchDNSResponse,
  FetchInstallStatusResponse,
  FetchCampaignUnavailableProductsResponse,
  AppPlan,
  FetchEmailTemplatesResponse,
  FinancialStatus,
  FulfillmentStatus,
  Campaign,
} from './types'

const SHOPIFY_GRAPHQL_API_URL = 'shopify:admin/api/2024-04/graphql.json'

// Campaign functions
export interface CampaignQueryParams {
  uuid?: string
  status?: string
  page?: number
  limit?: number
}

export interface CampaignQueryResponse {
  data: Campaign[]
  total: number
  hasNext: boolean
}

export const campaignQueryFunction = async (params?: CampaignQueryParams): Promise<CampaignQueryResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.uuid) searchParams.set('id', params.uuid)
  if (params?.status) searchParams.set('status', params.status)
  if (params?.page) searchParams.set('page', params.page.toString())
  if (params?.limit) searchParams.set('limit', params.limit.toString())

  const response = await fetch(buildAPIUrl(`campaign`, searchParams))
  const data = await response.json()
  return data.message
}

export interface CampaignBySellingPlanIdQueryParams {
  sellingPlanGroupId: string
}
export const campaignBySellingPlanIdQueryFunction = async (params: CampaignBySellingPlanIdQueryParams): Promise<FetchCampaignResponse> => {
  const searchParams = new URLSearchParams()
  searchParams.set('id', params.sellingPlanGroupId)

  const response = await fetch(buildAPIUrl(`campaign/bySellingPlanGroupId`, searchParams))
  const data = await response.json()
  return data.message
}

export interface CampaignOrdersQueryParams {
  uuid?: string
  financialStatus?: FinancialStatus
  fulfillmentStatus?: FulfillmentStatus
  page?: number
  limit?: number
}
export const campaignOrdersQueryFunction = async (params?: CampaignOrdersQueryParams): Promise<FetchOrdersResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.uuid) searchParams.set('id', params.uuid)
  if (params?.financialStatus) searchParams.set('financialStatus', params.financialStatus)
  if (params?.fulfillmentStatus) searchParams.set('fulfillmentStatus', params.fulfillmentStatus)
  if (params?.page) searchParams.set('page', params.page.toString())
  searchParams.set('limit', params?.limit?.toString() ?? '20')

  const response = await fetch(buildAPIUrl(`campaign/orders`, searchParams))
  const data = await response.json()
  return data.message
}

export interface CampaignReportsOrdersQueryParams {
  uuid?: string
  to?: string
  from?: string
}

export interface CampaignReportsOrdersQueryResponse {
  currency: string
  data: { key: string; orders: number; amount: number }[]
}

export const campaignReportsOrdersQueryFunction = async (params?: CampaignReportsOrdersQueryParams): Promise<CampaignReportsOrdersQueryResponse[]> => {
  const searchParams = new URLSearchParams()
  if (params?.uuid) searchParams.set('id', params.uuid)
  if (params?.to) searchParams.set('to', params.to)
  if (params?.from) searchParams.set('from', params.from)

  const response = await fetch(buildAPIUrl(`campaign/reports/orders`, searchParams))
  const data = await response.json()
  if (!data.status) throw new Error(data.message)
  return data.message
}

export interface ReportsUnitsSoldQueryParams {
  uuid: string
}

export interface ReportsUnitsSoldQueryResponse {
  data: {
    shopId: string
    productId: string
    variantId: string
    productName: string
    variantName: string | null
    currency: string
    total: number
    qty: number
    status: string
  }[]
  hasNext: boolean
  total: number
}

export const reportsUnitsSoldQueryFunction = async (params: ReportsUnitsSoldQueryParams): Promise<ReportsUnitsSoldQueryResponse> => {
  const searchParams = new URLSearchParams()
  searchParams.set('id', params.uuid)
  const response = await fetch(buildAPIUrl(`reports/unitsSold`, searchParams))
  const data = await response.json()
  return data.message
}

export interface CampaignUnavailableProductsQueryParams {
  uuid: string
  to?: string
  from: string
}
export const campaignUnavailableProductsQueryFunction = async (
  params: CampaignUnavailableProductsQueryParams
): Promise<FetchCampaignUnavailableProductsResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.uuid) searchParams.set('id', params.uuid)
  if (params?.to) searchParams.set('to', params.to)
  if (params?.from) searchParams.set('from', params.from)

  const response = await fetch(buildAPIUrl(`campaign/unavailableProducts`, searchParams))
  const data = await response.json()
  return data.message
}

// Backorder-related functions

export interface BackordersQueryParams {
  financialStatus?: FinancialStatus
  fulfillmentStatus?: FulfillmentStatus
  page?: number
  limit?: number
}
export const backordersQueryFunction = async (params?: BackordersQueryParams): Promise<FetchOrdersResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.financialStatus) searchParams.set('financialStatus', params.financialStatus)
  if (params?.fulfillmentStatus) searchParams.set('fulfillmentStatus', params.fulfillmentStatus)
  if (params?.page) searchParams.set('page', params.page.toString())
  searchParams.set('limit', params?.limit?.toString() ?? '20')

  const response = await fetch(buildAPIUrl(`backorders`, searchParams))
  const data = await response.json()
  return data.message
}

export interface ReportsBackordersQueryParams {
  uuid?: string
  to?: string
  from?: string
}

export interface ReportsBackordersQueryResponse {
  currency: string
  data: { key: string; orders: number; amount: number }[]
}

export const reportsBackordersQueryFunction = async (params?: ReportsBackordersQueryParams): Promise<ReportsBackordersQueryResponse[]> => {
  const searchParams = new URLSearchParams()
  if (params?.uuid) searchParams.set('id', params.uuid)
  if (params?.to) searchParams.set('to', params.to)
  if (params?.from) searchParams.set('from', params.from)

  const response = await fetch(buildAPIUrl(`reports/backorders`, searchParams))
  const data = await response.json()
  if (!data.status) throw new Error(data.message)
  return data.message
}

// Shop-related functions

export const settingsCheckAppEmbedQueryFunction = async (): Promise<boolean> => {
  const response = await fetch(buildAPIUrl('settings/checkAppEmbed'))
  const data = await response.json()
  return data.message
}

export const checkoutProfilesQueryFunction = async (): Promise<string> => {
  const query = `query {
    checkoutProfiles(first: 10, query: "is_published:true") {
      nodes {
        id
      }
    }
  }`
  const response = await fetch(SHOPIFY_GRAPHQL_API_URL, {
    method: 'POST',
    body: JSON.stringify({ query }),
  })
  const data = await response.json()
  const id = data.data.checkoutProfiles.nodes[0]?.id
  if (!id) throw new Error('No checkout profile found')
  return id.split('/').pop() as string
}

export const dnsQueryFunction = async (): Promise<FetchDNSResponse> => {
  const response = await fetch(buildAPIUrl('domain'))
  const data = await response.json()
  return data.message
}

// TODO: This one doesn't return message?
export const installStatusQueryFunction = async (): Promise<FetchInstallStatusResponse> => {
  const searchParams = new URLSearchParams()
  searchParams.set('redirect', 'false')

  const response = await fetch(buildAPIUrl('auth', searchParams))
  return response.json()
}

export interface AppPlanQueryParams {
  code?: string
}

export type AppPlanQueryResponse = {
  message: AppPlan[]
  status: boolean
}

export const appPlanQueryFunction = async (params?: AppPlanQueryParams): Promise<AppPlanQueryResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.code) searchParams.set('appCode', params.code)

  const response = await fetch(buildAPIUrl('appPlan', searchParams))
  const data = await response.json()

  //if (!data.status) throw new Error(data.message)

  return data
}

export const emailTemplatesQueryFunction = async (): Promise<FetchEmailTemplatesResponse> => {
  const response = await fetch(buildAPIUrl('emailTemplates'))
  const data = await response.json()
  return data.message
}

export const localisationQueryFunction = async () => {
  const response = await fetch(buildAPIUrl('localisation'))
  const data = await response.json()
  return data.message
}

export const settingsQueryFunction = async (): Promise<FetchSettingsResponse> => {
  const response = await fetch(buildAPIUrl('settings'))
  const data = await response.json()
  return data.message
}

export type ReportsUsageQueryResponse = {
  from: string
  to: string
  orders: {
    current: number
    limit: number
  }
  emails: {
    current: number
    limit: number
  }
  sms: {
    current: number
    limit: number
  }
  plan: AppPlan
}

export const reportsUsageQueryFunction = async (): Promise<ReportsUsageQueryResponse> => {
  const response = await fetch(buildAPIUrl('reports/usage'))
  const data = await response.json()
  return data.message
}

export interface WidgetQueryParams {
  widgetId: string
}
export const widgetQueryFunction = async (params: WidgetQueryParams) => {
  const searchParams = new URLSearchParams()
  searchParams.set('id', params.widgetId)

  const response = await fetch(buildAPIUrl(`widget`, searchParams))
  const data = await response.json()
  return data.message
}

export interface BisSettingQueryParams {
  uuid: string
}
export const bisSettingQueryFunction = async (params: BisSettingQueryParams): Promise<FetchBISSettingsResponse> => {
  const searchParams = new URLSearchParams()
  searchParams.set('id', params.uuid)

  const requestURL = buildAPIUrl('bisSetting', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface ReportsBisQueryParams {
  to?: string
  from?: string
}

export interface BisReportQueryResponse {
  currency: string
  data: {
    key: string
    amount: number
    orders: number
  }[]
}

export const reportsBisQueryFunction = async (params?: ReportsBisQueryParams): Promise<BisReportQueryResponse[]> => {
  const searchParams = new URLSearchParams()
  if (params?.to) searchParams.set('to', params.to.toString())
  if (params?.from) searchParams.set('from', params.from.toString())

  const requestURL = buildAPIUrl('reports/bis', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface ReportsBisListQueryParams {
  to?: string
  from?: string
}

export interface ReportsBisListQueryResponse {
  key: string
  conversions: number
  sends: number
  signups: number
}

export const reportsBisListQueryFunction = async (params?: ReportsBisListQueryParams): Promise<ReportsBisListQueryResponse[]> => {
  const searchParams = new URLSearchParams()
  if (params?.to) searchParams.set('to', params.to.toString())
  if (params?.from) searchParams.set('from', params.from.toString())

  const requestURL = buildAPIUrl('reports/bisList', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface ReportsBisAggregateMetricsQueryParams {
  productId?: string
}

export interface BisReportProductQueryResponse {
  signups: number
  sent: number
  opens: number
  totalOrders: number
  totalAmount: number
  conversionRate: number
}

export const reportsBisAggregateMetricsQueryFunction = async (params?: ReportsBisAggregateMetricsQueryParams): Promise<BisReportProductQueryResponse[]> => {
  const searchParams = new URLSearchParams()
  if (params?.productId) searchParams.set('id', params.productId.toString())

  const requestURL = buildAPIUrl('reports/bisAggregateMetrics', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface ReportsBisProductsQueryParams {
  page?: number
  limit?: number
}

export interface BisProductsQueryResponse {
  data: {
    productId: string
    signups: number
    waiting: number
    sent: number
    totalAmount: number
    conversionRate: number
  }[]
  total: number
  hasNext: boolean
}

export const reportsBisProductsQueryFunction = async (params?: ReportsBisProductsQueryParams): Promise<BisProductsQueryResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.page) searchParams.set('page', params.page.toString())
  if (params?.limit) searchParams.set('limit', params.limit.toString())

  const requestURL = buildAPIUrl('reports/bisProducts', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface ReportsBisVariantsQueryParams {
  id: string
  page?: number
  limit?: number
}

export interface ReportsBisVariantsQueryResponse {
  data: {
    productId?: string
    variantId: string
    signups: number
    waiting: number
    sent: number
    totalAmount: number
    conversionRate: number
  }[]
  total: number
  hasNext: boolean
}

export const reportsBisVariantsQueryFunction = async (params: ReportsBisVariantsQueryParams): Promise<ReportsBisVariantsQueryResponse> => {
  const searchParams = new URLSearchParams()
  if (params?.page) searchParams.set('page', params.page.toString())
  if (params?.limit) searchParams.set('limit', params.limit.toString())
  if (params.id) searchParams.set('id', params.id)

  const requestURL = buildAPIUrl('reports/bisVariants', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface BisListQueryParams {
  id: string
  page?: number
  limit?: number
  sendStatus?: 'NOT_SENT' | 'DELIVERED' | 'OPENED' | 'CLICKED' | 'BOUNCED'
}

export interface BisContactsQueryResponse {
  data: {
    apiData: {
      customer: {
        id: string
        legacyResourceId: string
        firstName: string | null
        lastName: string | null
        email: string
        emailMarketingConsent: {
          consentUpdatedAt: string | null
          marketingOptInLevel: string
          marketingState: string
        }
      }
      variant: {
        id: string
        product: {
          id: string
          featuredImage: string | null
          title: string
        }
        title: string
      }
    }
    contact: {
      uuid: string
      shopId: string
      productId: string
      variantId: string
      email_send_id: string | null
      email_send_status: 'QUEUED' | 'REQUESTED' | 'SENT' | 'DELIVERED' | 'DELIVERY_DELAYED' | 'COMPLAINED' | 'BOUNCED' | 'OPENED' | 'CLICKED' | null
      sentAt: string | null
      createdAt: string
      customerId: string
      orderId: string | null
    }
  }[]
  total: number
  hasNext: boolean
}

export const bisListQueryFunction = async (params: BisListQueryParams): Promise<BisContactsQueryResponse> => {
  const searchParams = new URLSearchParams()

  searchParams.set('id', params.id)

  if (params.page) searchParams.set('page', params.page.toString())
  if (params.sendStatus) searchParams.set('sendStatus', params.sendStatus)

  searchParams.set('limit', params.limit?.toString() ?? '20')

  const requestURL = buildAPIUrl('bisList', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface BisListContactQueryParams {
  id: string
}

export interface BisListContactQueryResponse {
  uuid: string
  shopId: string
  productId: string
  variantId: string
  sentAt: string
  createdAt: string
  customerId: string
  customer: {
    email: string
    emailMarketingConsent: {
      consentUpdatedAt: null
      marketingOptInLevel: 'SINGLE_OPT_IN' | 'CONFIRMED_OPT_IN' | 'UNKNOWN'
      marketingState: 'INVALID' | 'NOT_SUBSCRIBED' | 'PENDING' | 'REDACTED' | 'SUBSCRIBED' | 'UNSUBSCRIBED'
    }
    firstName: string | null
    id: string
    lastName: string | null
    legacyResourceId: string
  }
  email_send: {
    uuid: string
    shopId: string
    emailId: string
    status: 'QUEUED' | 'REQUESTED' | 'SENT' | 'DELIVERED' | 'DELIVERY_DELAYED' | 'COMPLAINED' | 'BOUNCED' | 'OPENED' | 'CLICKED' | null
    resendId: string
    resendEvents: {
      type: 'email.sent' | 'email.delivered' | 'email.delivery_delayed' | 'email.complained' | 'email.bounced' | 'email.opened' | 'email.clicked'
      created_at: string
    }[]
    createdAt: string
    sendTo: string
    contextId: string
    email: {
      uuid: string
      shopId: string
      emailTemplateId: string
      parentType: string
      parentId: string
      createdAt: string
      queueStatus: string
      messagesQueuedCount: number
    }
    apiData: {
      object: string
      id: string
      to: string[]
      from: string
      created_at: string
      subject: string
      bcc: null
      cc: null
      reply_to: string[]
      last_event: string
      html: string
      text: null
      scheduled_at: null
    }
  } | null
  bis: any //TODO
}

export const bisListContactQueryFunction = async (params: BisListContactQueryParams): Promise<BisListContactQueryResponse> => {
  const searchParams = new URLSearchParams()

  searchParams.set('id', params.id)

  const requestURL = buildAPIUrl('bisListContact', searchParams)

  return fetch(requestURL)
    .then((res) => res.json())
    .then((res) => res.message)
}

export interface BisRestocksQueryParams {
  id: string
  page?: number
  limit?: number
}

export interface BisRestocksQueryResponse {
  data: {
    uuid: string
    shopId: string
    productId: string
    isAutomaticSend: boolean
    status: 'ERROR' | 'COMPLETE' | 'SENDING' | 'SCANNING' | 'WAITING_NEXT_BATCH'
    errorCode: string | null
    lastError: string | null
    currentSendCount: number
    completedAt: string
    nextBatchScheduledAt: string | null
    createdAt: string
  }[]
  total: number
  hasNext: boolean
}

export const bisRestocksQueryFunction = async (params: BisRestocksQueryParams): Promise<BisRestocksQueryResponse> => {
  const searchParams = new URLSearchParams()

  searchParams.set('id', params.id)

  if (params?.page) searchParams.set('page', params.page.toString())
  if (params?.limit) searchParams.set('limit', params.limit.toString())

  const response = await fetch(buildAPIUrl('bisRestocks', searchParams))
  const data = await response.json()

  if (!data.status) throw new Error("Couldn't fetch restocks")

  return data.message
}

export interface ShopifyResourcePickerQueryParams {
  productIds: string[] | null
  variantIds?: string[]
}
export const shopifyResourcePickerQueryFunction = async (params: ShopifyResourcePickerQueryParams): Promise<FetchShopifyCampaignProducts> => {
  const strippedProductIds = stripGids(params.productIds ?? [])
  const strippedVariantIds = stripGids(params.variantIds ?? [])

  const productQueryFilter = strippedProductIds.map((id) => `(id:${id})`).join(' OR ')
  const variantQueryFilter = strippedVariantIds.map((id) => `(id:${id})`).join(' OR ')

  const query = `
    query GetCampaignProducts($productsQuery: String!, $productVariantsQuery: String!) {
      products(first: ${strippedProductIds.length}, query: $productsQuery) {
        nodes {
          id
          title
          featuredImage {
            url
          }
          variantsCount {
            count
          }
        }
      }
      productVariants(first: ${strippedVariantIds.length}, query: $productVariantsQuery) {
        nodes {
          id
          title
          product {
            id
            title
            featuredImage {
              url
            }
            variantsCount {
              count
            }
          }
        }
      }
    }
  `

  return fetch(SHOPIFY_GRAPHQL_API_URL, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables: {
        productsQuery: productQueryFilter,
        productVariantsQuery: variantQueryFilter,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data)
}

export interface ShopifyProductQueryParams {
  productId: string
}

export interface ShopifyProductQueryResponse {
  product: {
    id: string
    title: string
    featuredImage: {
      altText: string
      url: string
    }
  }
}

export const _shopifyProductQueryFunction = async (params: ShopifyProductQueryParams): Promise<ShopifyProductQueryResponse> => {
  const query = `
   query getProduct($productId: ID!) {
      product(id: $productId) {
        id
        title
        featuredImage {
          url
          altText
        }
      }
    }
  `

  return fetch(SHOPIFY_GRAPHQL_API_URL, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables: {
        productId: params.productId,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data)
}

export interface ShopifyGetProductsQueryParams {
  productIds: string[]
}

export interface ShopifyProductsQueryResponse {
  nodes: {
    id: string
    title: string
    totalInventory: number
    featuredImage: {
      url: string
    }
  }[]
}

export const shopifyGetProductsQueryFunction = async (params: ShopifyGetProductsQueryParams): Promise<ShopifyProductsQueryResponse> => {
  const query = `
   query getProducts($productIds: [ID!]!) {
      nodes(ids: $productIds) {
        ... on Product {
          id
          title
          totalInventory
          featuredImage {
            url
          }
        }
      }
    }
  `

  return fetch(SHOPIFY_GRAPHQL_API_URL, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables: {
        productIds: params.productIds,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data)
}

export interface ShopifyProductVariantsQueryParams {
  variantGids: string[]
}

export interface ShopifyProductVariantsQueryResponse {
  nodes: {
    id: string
    title: string
    image: {
      url: string
    }
  }[]
}

export const shopifyProductVariantsQueryFunction = async (params: ShopifyProductVariantsQueryParams): Promise<ShopifyProductVariantsQueryResponse> => {
  const query = `
   query getProductVariants($variants: [ID!]!) {
      nodes(ids: $variants) {
        ... on ProductVariant {
          id
          title
          image {
            url
          }
        }
      }
    }
  `

  return fetch(SHOPIFY_GRAPHQL_API_URL, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables: {
        variants: params.variantGids,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data)
}
