import { createFileRoute, useNavigate } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { settingsQueryOptions } from '@/common/queryOptions'
import { BackorderSettingsFallback } from '@/components/backorders/BackorderSettingsFallback'
import { useSuspenseQuery } from '@tanstack/react-query'
import { BackorderStatusBadge } from '@/components/shared/BackorderStatusBadge'
import { BackorderForm } from '@/components/backorders/BackorderForm'

export const Route = createFileRoute('/backorders/settings')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <BackorderSettingsFallback />
  },
  component: Index,
})

function Index() {
  const { t } = useTranslation()
  const navigate = useNavigate({ from: '/backorders' })

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  return (
    <PageFrame
      title={t('backorderSettingsTitle')}
      subtitle={t('backorderSettingsSubtitle')}
      titleMetadata={<BackorderStatusBadge backorderEnabled={!!settings.backorderEnabled} status={settings.backorderStatus} withProgress={false} />}
      backAction={{
        content: t('backordersDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/backorders` })
        },
      }}
    >
      <BackorderForm />
    </PageFrame>
  )
}
