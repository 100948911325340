import { Banner, BlockStack, Box, Card, Checkbox, Select, Text, TextField } from '@shopify/polaris'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const BisModalWidgetPreview = ({ status }: { status: 'default' | 'success' | 'error' }) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()

  const bisButtonText = watch('bisButtonText')
  const bisButtonTextColor = watch('bisButtonTextColor')
  const bisButtonBackgroundColor = watch('bisButtonBackgroundColor')
  const bisModalBackgroundColor = watch('bisModalBackgroundColor')
  const bisModalTextColor = watch('bisModalTextColor')
  const bisModalTitle = watch('bisModalTitle')
  const bisModalDescription = watch('bisModalDescription')
  const bisModalMarketingConsentLabel = watch('bisModalMarketingConsentLabel')
  const bisModalEmailPlaceholder = watch('bisModalEmailPlaceholder')
  const bisFormSuccessMessage = watch('bisFormSuccessMessage')
  const bisFormErrorMessage = watch('bisFormErrorMessage')

  const defaultBody = () => {
    return (
      <div
        style={{
          color: '#000000',
        }}
      >
        <Select label={t('displayBisFormVariantPlaceholder')} labelHidden placeholder={t('displayBisFormVariantPlaceholder')} onChange={() => {}} />
        <Box minHeight="10px" />
        <TextField
          autoComplete="on"
          label={t('displayBisFormEmailPlaceholderPlaceholder')}
          labelHidden
          placeholder={bisModalEmailPlaceholder ? bisModalEmailPlaceholder : t('displayBisFormEmailPlaceholderPlaceholder')}
          readonly
          value=""
          onChange={() => {}}
        />
        <Box minHeight="10px" />
        <div
          style={{
            color: bisModalTextColor ?? '#000000',
          }}
        >
          <Checkbox label={bisModalMarketingConsentLabel ? bisModalMarketingConsentLabel : t('displayBisFormMarketingConsentPlaceholder')} />
          <Box minHeight="20px" />
          <div
            style={{
              cursor: 'pointer',
              padding: '0.8rem',
              border: bisButtonBackgroundColor ? undefined : '1px solid #000',
              backgroundColor: bisButtonBackgroundColor ? bisButtonBackgroundColor : '#000000',
              color: bisButtonTextColor ? bisButtonTextColor : '#ffffff',
            }}
          >
            <Text as="span" variant="bodyLg" alignment="center">
              {bisButtonText ? bisButtonText : t('displayBisButtonPlaceholder')}
            </Text>
          </div>
        </div>
      </div>
    )
  }
  const successBody = () => {
    return (
      <Banner tone="success" hideIcon>
        <p>{bisFormSuccessMessage ? bisFormSuccessMessage : t('displayBisFormSuccessMessagePlaceholder')}</p>
      </Banner>
    )
  }
  const errorBody = () => {
    return (
      <Banner tone="critical" hideIcon>
        <p>{bisFormErrorMessage ? bisFormErrorMessage : t('displayBisFormErrorMessagePlaceholder')}</p>
      </Banner>
    )
  }

  return (
    <BlockStack gap="400">
      <Card background="bg-fill-tertiary">
        <div
          style={{
            fontSize: '14px',
            padding: '1rem',
            borderRadius: '4px',
            background: bisModalBackgroundColor ? bisModalBackgroundColor : '#ffffff',
            color: bisModalTextColor ? bisModalTextColor : '#000000',
          }}
        >
          <Box>
            <Text as="h4" variant="headingLg">
              {bisModalTitle ? bisModalTitle : t('displayBisModalTitlePlaceholder')}
            </Text>
            <Box minHeight="20px" />
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <Text as="p" variant="bodyLg">
                {bisModalDescription ? bisModalDescription : t('displayBisModalDescriptionPlaceholder')}
              </Text>
            </div>
            <Box minHeight="20px" />
            {status === 'default' && defaultBody()}
            {status === 'success' && successBody()}
            {status === 'error' && errorBody()}
          </Box>
        </div>
      </Card>
    </BlockStack>
  )
}

export { BisModalWidgetPreview }
