import { buildAPIUrl } from '@/common/functions'
import { appPlanQueryOptions, reportsUsageQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useMutatePlan = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ appId, appCode }: { appId?: string | null; appCode?: string }) => {
      const searchParams = new URLSearchParams()
      if (appId) searchParams.set('id', appId)
      if (appCode) searchParams.set('appCode', appCode)

      const requestURL = buildAPIUrl('appPlan', searchParams)

      return fetch(requestURL, {
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error updating plan'
          }
        })
        .then((res) => res.message)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: reportsUsageQueryOptions().queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: appPlanQueryOptions().queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: settingsQueryOptions().queryKey,
      })
    },
  })
}
