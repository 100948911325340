import { createFileRoute } from '@tanstack/react-router'

import { Layout } from '@shopify/polaris'
import { PageFrame } from '@/components/shared/PageFrame'
import { UsageStats } from '@/components/shared/UsageStats'
import { useTranslation } from 'react-i18next'
import { PlanSelector } from '@/components/plans/PlanSelector'
import { appPlanQueryOptions, reportsUsageQueryOptions } from '@/common/queryOptions'
import { PlansFallback } from '@/components/plans/PlansFallback'

export const Route = createFileRoute('/plans')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(reportsUsageQueryOptions())
      queryClient.ensureQueryData(appPlanQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <PlansFallback />
  },
  component: Index,
})

function Index() {
  const { t } = useTranslation()

  return (
    <PageFrame title={t('appPlansTitle')} subtitle={t('appPlansSubtitle')}>
      <UsageStats />
      <Layout.Section>
        <PlanSelector />
      </Layout.Section>
    </PageFrame>
  )
}
