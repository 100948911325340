import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { campaignQueryOptions, localisationQueryOptions, settingsQueryOptions, widgetQueryOptions } from '@/common/queryOptions'
import { CampaignDisplayFallback } from '@/components/preorders/CampaignDisplayFallback'
import { useSuspenseQuery } from '@tanstack/react-query'
import { DisplayForm } from '@/components/display/DisplayForm'
import { useCreateWidget } from '@/hooks/useCreateWidget'
import { Banner, Layout } from '@shopify/polaris'
import { useDeleteWidget } from '@/hooks/useDeleteWidget'
import { CampaignStatusBadge } from '@/components/shared/CampaignStatusBadge'

export const Route = createFileRoute('/preorders/$uuid/display')({
  loader: async ({ params, context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(settingsQueryOptions())
      queryClient.ensureQueryData(localisationQueryOptions())

      const {
        data: [campaign],
      } = await queryClient.ensureQueryData(campaignQueryOptions({ uuid: params.uuid }))

      queryClient.ensureQueryData(
        widgetQueryOptions({
          widgetId: campaign.widgetId,
        })
      )
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <CampaignDisplayFallback />
  },
  component: Index,
})

function Index() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // We use the UUID from the URL to identify the campaign
  const { uuid } = useParams({ strict: false }) as { uuid: string }

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const {
    data: {
      data: [campaign],
    },
  } = useSuspenseQuery(campaignQueryOptions({ uuid: uuid }))
  const { data: widgetSettings } = useSuspenseQuery(
    widgetQueryOptions({
      widgetId: campaign.widgetId,
    })
  )

  const createWidget = useCreateWidget(uuid)
  const deleteWidget = useDeleteWidget(uuid)

  const isGlobalWidget = settings.widgetId === widgetSettings.uuid

  const getPrimaryAction = () => {
    if (isGlobalWidget) {
      return {
        content: t('campaignDisplayCreateCta'),
        loading: createWidget.isPending,
        onAction: async () => {
          createWidget.mutate()
        },
      }
    } else {
      return {
        content: t('campaignDisplayResetCta'),
        loading: deleteWidget.isPending,
        destructive: true,
        onAction: async () => {
          deleteWidget.mutate()
        },
      }
    }
  }

  return (
    <PageFrame
      title={campaign.name}
      subtitle={t('campaignDisplaySubtitle')}
      titleMetadata={<CampaignStatusBadge status={campaign.status} withProgress={false} />}
      primaryAction={getPrimaryAction()}
      backAction={{
        content: campaign.name,
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/preorders/$uuid`, params: { uuid } })
        },
      }}
    >
      {isGlobalWidget && (
        <Layout.Section>
          <Banner title={t('displayGlobalBannerTitle')} tone="info">
            <p>{t('displayGlobalBannerDescription')}</p>
          </Banner>
        </Layout.Section>
      )}

      <DisplayForm data={widgetSettings} campaignId={uuid} isDisabled={isGlobalWidget} sections={['preorder']} />
    </PageFrame>
  )
}
