import { Box, BlockStack, Card, Grid, Layout } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { BackorderWidgetPreview } from './BackorderWidgetPreview'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { BackorderWidgetSettings } from './BackorderWidgetSettings'

const BackorderWidget = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const { t } = useTranslation()

  return (
    <Layout.Section>
      <Box paddingBlockStart="200" paddingBlockEnd="400" paddingInline="0">
        <SectionTitle title={t('displayBackorderTitle')} description={t('displayBackorderDescription')} />
        <BlockStack gap="400">
          <Grid columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <Disabled isDisabled={isDisabled}>
                  <BackorderWidgetSettings />
                </Disabled>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <BackorderWidgetPreview />
              </Card>
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </Box>
    </Layout.Section>
  )
}

export { BackorderWidget }
