import { Badge } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const BackInStockStatusBadge = ({ enabled = false }) => {
  const { t } = useTranslation()

  let label: string
  let tone: 'info' | 'success' | 'warning' | 'attention' | 'critical' | undefined

  if (enabled) {
    label = t('backInStockStatusActive')
    tone = 'success'
  } else {
    label = t('backInStockStatusInactive')
    tone = undefined
  }

  return label ? <Badge tone={tone}>{label}</Badge> : null
}

export { BackInStockStatusBadge }
