import { FormLayout, TextField } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { ColorPicker } from '../shared/ColorPicker'

const BisButtonWidgetSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <FormLayout>
      <Controller
        name="bisButtonText"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisButtonLabel')}
            placeholder={t('displayBisButtonPlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error?.message}
          />
        )}
      />
      <FormLayout.Group>
        <Controller
          name="bisButtonBackgroundColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayBisButtonBackgroundColorLabel')}
              placeholder="#000000"
              defaultColor="#000000"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
              allowInherit={true}
            />
          )}
        />

        <Controller
          name="bisButtonTextColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayBisButtonTextColorLabel')}
              placeholder="#ffffff"
              defaultColor="#ffffff"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
              allowInherit={true}
            />
          )}
        />
      </FormLayout.Group>
    </FormLayout>
  )
}

export { BisButtonWidgetSettings }
