import { MetricBar } from '@/components/shared/MetricBar'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { chartTitleFormatter, chartTooltipTitleFormatter, chartValueFormatter, formatNumber } from '@/common/functions.ts'
import { DataSeries } from '@shopify/polaris-viz-core'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQuery } from '@tanstack/react-query'
import { reportsBackordersQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Card } from '@shopify/polaris'
import { TooltipContent } from '@shopify/polaris-viz'

const BackorderChart = () => {
  const [i18n] = useI18n()
  const { t } = useTranslation()

  const [filterStartDate, _] = useState<string | undefined>(undefined)
  const [filterEndDate] = useState<string | undefined>(undefined)

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { data: backorderReport } = useSuspenseQuery(
    reportsBackordersQueryOptions({
      from: filterStartDate,
      to: filterEndDate,
    })
  )

  const chartData: DataSeries[] = []
  let ordersTotal = 0

  backorderReport.length &&
    backorderReport.map((group) => {
      chartData.push({
        /*isComparison: group.currency !== settingsQuery.data?.currency,*/
        name: t('backorderMetricsRevenue', {
          currency: group.currency,
        }),
        data: group.data.map((d) => {
          return {
            key: d.key,
            value: d.amount,
          }
        }),
      })

      // Keep track of total orders (across all currencies)
      ordersTotal += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
    })

  return (
    <Card padding="400">
      <MetricBar
        blocks={[
          ...[
            backorderReport.length === 0 && (
              <MetricBar.ValueBlock
                title={t('backorderMetricsRevenue', {
                  currency: settings.currency,
                })}
                value={i18n.formatCurrency(0, { currency: settings.currency })}
              />
            ),
          ],
          ...backorderReport.map((group) => (
            <MetricBar.ValueBlock
              title={t('backorderMetricsRevenue', {
                currency: group.currency,
              })}
              value={i18n.formatCurrency(
                group.data.reduce((total, current) => total + (current.amount ?? 0), 0),
                { currency: group.currency }
              )}
            />
          )),
          <MetricBar.ValueBlock title={t('backorderMetricsPreorders')} value={formatNumber(ordersTotal)} />,
        ]}
        chart={
          <MetricBar.Chart
            data={chartData}
            xAxisOptions={{
              labelFormatter: chartTitleFormatter,
            }}
            yAxisOptions={{
              labelFormatter: (value) => chartValueFormatter(value, settings.currency),
            }}
            tooltipOptions={{
              titleFormatter: chartTooltipTitleFormatter,
              renderTooltipContent: (data) => {
                return (
                  <TooltipContent
                    theme="Light"
                    data={[
                      {
                        name: chartTooltipTitleFormatter(data?.title ?? ''),
                        data: data.data[0].data.map((d) => {
                          const label = d.key.toString()
                          const lineCurrency = label.split('(').pop()?.replace(')', '') ?? settings.currency
                          const formattedValue = chartValueFormatter(d.value, lineCurrency, 2)
                          return {
                            isComparison: d.isComparison,
                            isHidden: d.isHidden,
                            color: d.color,
                            key: label,
                            value: formattedValue,
                          }
                        }),
                        shape: 'Line',
                      },
                    ]}
                  />
                )
              },
            }}
          />
        }
      ></MetricBar>
    </Card>
  )
}

export { BackorderChart }
