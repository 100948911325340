import { buildAPIUrl } from '@/common/functions'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type MutateCampaignSchedulePayload = {
  action: 'PUBLISH' | 'SCHEDULED' | 'UNPUBLISH' | 'CANCEL' | 'DELAY_FULFILLMENT' | 'ARCHIVE'
  fulfillmentDate?: string
}

export const useMutateCampaignSchedule = (uuid: string) => {
  const queryClient = useQueryClient()
  const searchParams = new URLSearchParams()
  if (uuid) searchParams.set('id', uuid)

  const requestURL = buildAPIUrl('campaign/action', searchParams)

  return useMutation({
    scope: {
      id: requestURL,
    },
    mutationFn: (data: MutateCampaignSchedulePayload) => {
      const payload = { ...data, id: uuid }
      return fetch(requestURL, {
        body: JSON.stringify(payload),
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error running campaign action'
          }
        })
        .then((res) => res.message)
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['campaign'],
      })

      // if (uuid) {
      //   queryClient.invalidateQueries({
      //     queryKey: [`campaign`, { uuid }],
      //   })
      // }
    },
  })
}
