import { ReactNode, useState } from 'react'
import { BlockStack, Card, Text, InlineStack, Button, Box, ButtonGroup, Icon, Popover, ActionList } from '@shopify/polaris'
import { MenuHorizontalIcon, XSmallIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

type ExtendedButton = {
  label?: string
  props: {
    url?: string
    external?: boolean
    onClick?: () => void
    disabled?: boolean
    loading?: boolean
  }
}

export type DismissableCardProps = {
  title: string
  description: string
  illustration?: ReactNode
  primaryCta?: ExtendedButton
  secondaryCta?: ExtendedButton
  isDismissDisabled: boolean
  onDismiss: () => void
  className?: string
}

export const DismissableCard = ({
  title,
  description,
  illustration,
  primaryCta,
  secondaryCta,
  isDismissDisabled,
  onDismiss,
  className,
}: DismissableCardProps) => {
  const { t } = useTranslation()
  const [isDismissed, setIsDismissed] = useState(false)
  const [popoverActive, setPopoverActive] = useState(false)

  if (isDismissed) {
    return null
  }

  return (
    <Card padding="0">
      <div className="overflowMenu">
        {onDismiss && (
          <Popover
            active={popoverActive}
            onClose={() => setPopoverActive(!popoverActive)}
            activator={
              <InlineStack>
                <Button onClick={() => setPopoverActive(!popoverActive)} variant="tertiary" size="slim" icon={MenuHorizontalIcon} />
              </InlineStack>
            }
          >
            <ActionList
              actionRole="menuitem"
              items={[
                {
                  content: t('genericDismiss'),
                  disabled: isDismissDisabled,
                  onAction: () => {
                    onDismiss()
                    setIsDismissed(true)
                    setPopoverActive(false)
                  },
                  prefix: <Icon tone="subdued" source={XSmallIcon} />,
                },
              ]}
            />
          </Popover>
        )}
      </div>
      <div className={`dismissCardWrapper ${className}`}>
        <div className="dismissCard">
          <Box padding="400">
            <BlockStack gap="400">
              <BlockStack gap="200">
                {title && (
                  <Text as="h3" variant="headingMd">
                    <span>{title}</span>
                  </Text>
                )}
                {description && (
                  <Text as="p" variant="bodyMd">
                    {description}
                  </Text>
                )}
              </BlockStack>
              {primaryCta && (
                <InlineStack align="start">
                  <ButtonGroup>
                    <Button accessibilityLabel={primaryCta.label} {...primaryCta.props}>
                      {primaryCta.label}
                    </Button>
                    {secondaryCta && (
                      <Button variant="plain" accessibilityLabel={secondaryCta.label} {...secondaryCta.props}>
                        {secondaryCta.label}
                      </Button>
                    )}
                  </ButtonGroup>
                </InlineStack>
              )}
            </BlockStack>
          </Box>

          {illustration && <div className="illustration">{illustration}</div>}
        </div>
      </div>
    </Card>
  )
}
