import { Box, Card, Icon, InlineStack, SkeletonDisplayText, Text, Tooltip } from '@shopify/polaris'
import { SearchResourceIcon } from '@shopify/polaris-icons'

import { Link } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

const MetricCard = ({
  heading,
  value,
  url,
  tooltip,
  children,
}: {
  heading: string
  value: string
  url?: string
  tooltip: string
  isLoading: boolean
  children: React.ReactNode
}) => {
  const { t } = useTranslation()
  return (
    <Card>
      <InlineStack align="space-between" blockAlign="start">
        <Box>
          <Tooltip content={tooltip} hasUnderline>
            <Text as="span" variant="bodyMd" fontWeight="medium">
              {heading}
            </Text>
          </Tooltip>
        </Box>
        {url && (
          <Link to={url}>
            <Icon source={SearchResourceIcon} tone="subdued" accessibilityLabel={t('genericViewDetails')} />
          </Link>
        )}
      </InlineStack>
      {!value ? (
        <Box paddingBlockStart="100">
          <SkeletonDisplayText size="large" />
        </Box>
      ) : (
        <Box paddingBlockStart="100">
          <Text as="p" variant="headingLg">
            {value}
          </Text>
        </Box>
      )}
      <Box paddingBlock="400">{children}</Box>
    </Card>
  )
}

export { MetricCard }
