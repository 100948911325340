import { BlockStack, Text } from '@shopify/polaris'
import NotFoundIllustration from '@/illustrations/not-found.svg?react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <div className="error-page">
      <div className="error-page__illustration">
        <div className="illustration" style={{ width: '240px' }}>
          <NotFoundIllustration />
        </div>
      </div>
      <div className="error-page__content">
        <BlockStack gap="600">
          <Text as="h3" variant="headingXl">
            {t('notFoundTitle')}
          </Text>
          <Text as="p" variant="bodyLg" tone="subdued">
            {t('notFoundDescription')}
          </Text>
        </BlockStack>
      </div>
    </div>
  )
}

export { NotFound }
