import { Banner, Layout } from '@shopify/polaris'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { settingsQueryOptions } from '@/common/queryOptions'
import { useMutateSettings } from '@/hooks/useMutateSettings'

const LegacyThemeBanner = () => {
  const { t } = useTranslation()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const settingsMutation = useMutateSettings()

  // Local state to immediately hide on dismiss
  const [isLocallyDismissed, setIsLocallyDismissed] = useState(false)

  // If we're legacy, haven't dismissed previously, haven't dismissed locally
  const isVisible = settings.themeVersion === 'legacy' && !settings.dismissFlags?.legacyTheme && !isLocallyDismissed

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutateAsync({ dismissFlags })
  }

  if (!isVisible) return null

  return (
    <Layout.Section>
      <Banner
        title={t('legacyThemeBannerTitle')}
        tone="warning"
        onDismiss={() => {
          onDismiss({ ...settings.dismissFlags, legacyTheme: true })
          setIsLocallyDismissed(true)
        }}
        action={{
          content: t('legacyThemeBannerAction'),
          onAction: () => {
            window.Beacon('toggle')
          },
        }}
        secondaryAction={{
          content: t('genericLearnMore'),
          external: true,
          url: 'https://help.shopify.com/en/manual/online-store/themes/managing-themes/versions',
        }}
      >
        <p>{t('legacyThemeBannerDescription')}</p>
      </Banner>
    </Layout.Section>
  )
}

export { LegacyThemeBanner }
