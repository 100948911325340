import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'

import { z } from 'zod'
import { PreOrderDelayed } from './PreOrderDelayed'
import { MutateEmailTemplatesPayload, MutateEmailTemplatesResponse } from '@/common/types'
import { useMutateEmailTemplates } from '@/hooks/useMutateEmailTemplates'
import { useTranslation } from 'react-i18next'
import { SaveBar } from '@shopify/app-bridge-react'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { emailTemplatesQueryOptions } from '@/common/queryOptions'
import useScrollToError from '@/hooks/useScrollToError'
import { produce } from 'immer'
import { BackInStock } from './BackInStock'

const schema = z.object({
  preOrderDelayed: z.object({
    subject: z.string().trim().nullable().default(null),
    title: z.string().trim().nullable().default(null),
    body: z.string().trim().nullable().default(null),
    primaryButtonText: z.string().trim().nullable().default(null),
    hasCustomTemplate: z.boolean().default(false),
    templateHtml: z.string().trim().nullable().default(null),
  }),
  backInStock: z.object({
    subject: z.string().trim().nullable().default(null),
    title: z.string().trim().nullable().default(null),
    body: z.string().trim().nullable().default(null),
    primaryButtonText: z.string().trim().nullable().default(null),
    hasCustomTemplate: z.boolean().default(false),
    templateHtml: z.string().trim().nullable().default(null),
  }),
})

export type FormSchema = z.infer<typeof schema>

const EmailForm = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data } = useSuspenseQuery(emailTemplatesQueryOptions())

  const form = useForm<z.infer<typeof schema>>({
    defaultValues: schema.parse(data ?? {}),
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const mutateEmailTemplates = useMutateEmailTemplates()

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onSubmit: SubmitHandler<FormSchema> = async (formValues) => {
    const payload = produce<FormSchema, MutateEmailTemplatesPayload>(formValues, (draft) => {
      draft.preOrderDelayed.uuid = data.preOrderDelayed.uuid
      draft.backInStock.uuid = data.backInStock.uuid
    }) as MutateEmailTemplatesPayload // TODO: Really shouldn't need this as assertion

    await mutateEmailTemplates.mutateAsync(payload, {
      onSuccess: (data: MutateEmailTemplatesResponse) => {
        reset(schema.parse(data))

        queryClient.invalidateQueries({
          queryKey: emailTemplatesQueryOptions().queryKey,
        })

        shopify.toast.show(t('emailTemplatesUpdatedToast'), {
          duration: 2000,
        })

        return data
      },
    })
  }

  const onError: SubmitErrorHandler<FormSchema> = () => {
    setCanFocus(true)

    shopify.toast.show(t('genericErrorToast'), {
      duration: 2000,
      isError: true,
    })
  }

  return (
    <FormProvider {...form}>
      <SaveBar id="save-bar" open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={mutateEmailTemplates.isPending}
          loading={mutateEmailTemplates.isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={mutateEmailTemplates.isPending}></button>
      </SaveBar>
      <PreOrderDelayed defaultTemplateHtml={data.preOrderDelayed.defaultTemplateHtml} isDisabled={mutateEmailTemplates.isPending} />
      <BackInStock defaultTemplateHtml={data.backInStock.defaultTemplateHtml} isDisabled={mutateEmailTemplates.isPending} />
    </FormProvider>
  )
}

export { EmailForm }
