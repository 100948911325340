import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from '@tanstack/react-router'

import { BlockStack, Layout } from '@shopify/polaris'

import { zodResolver } from '@hookform/resolvers/zod'

import { FetchCampaignResponse, MutateCampaignPayload } from '@/common/types'

import { CampaignName } from './CampaignName'
import { ProductSelection } from './CampaignProducts'
import { CampaignDuration } from './CampaignDuration'
import { CampaignFulfillment } from './CampaignFulfillment'
import { CampaignPayment } from './CampaignPayment'
import { CampaignTrigger } from './CampaignTrigger'
import { useMutateCampaign } from '@/hooks/useMutateCampaign'

import dayjs from '@/common/datetime'

import merge from 'ts-deepmerge'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import { CampaignInventoryPolicy } from './CampaignInventoryPolicy'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { SaveBar } from '@shopify/app-bridge-react'
import { useEffect } from 'react'
import { campaignQueryOptions, settingsQueryOptions, shopifyResourcePickerQueryOptions } from '@/common/queryOptions'
import { Disabled } from '../shared/Disabled'
import { CampaignStockLimit } from './CampaignStockLimit'
import useScrollToError from '@/hooks/useScrollToError'

const sellingPlanFullSchema = z.object({
  uuid: z.string().optional(),
  isActive: z.boolean(),
  name: z.string().trim(),
  hasDiscount: z.boolean(),
  discountType: z.enum(['PERCENTAGE', 'FIXED']),
  discountAmount: z.number().nullish(),
  partialType: z.enum(['PERCENTAGE', 'FIXED']),
  partialAmount: z.number(),
})

const sellingPlanPartialSchema = z.object({
  uuid: z.string().optional(),
  isActive: z.boolean(),
  name: z.string().trim().nullish(),
  partialType: z.enum(['PERCENTAGE', 'FIXED']),
  partialAmount: z.number().nullish(),
  hasDiscount: z.boolean(),
  discountType: z.enum(['PERCENTAGE', 'FIXED']),
  discountAmount: z.number().nullish(),
  finalPaymentTrigger: z.enum(['TIME_AFTER_CHECKOUT', 'EXACT_TIME']),
  paymentDueDate: z.string().nullish(),
  numberOfDays: z.number().min(0).nullish(),
})

const productSchema = z.object({
  id: z.string(),
  title: z.string(),
  featuredImage: z
    .object({
      url: z.string(),
    })
    .optional(),
  variants: z.array(
    z.object({
      id: z.string(),
    })
  ),
  totalVariants: z.number().optional(),
})

// Main schema
const schema = z.object({
  isPublished: z.boolean(),
  isFulfillmentDelayedPending: z.boolean(),
  name: z.string().trim(),
  preorderTrigger: z.enum(['ALWAYS', 'IN_STOCK']),
  startDate: z.string(),
  hasEndDate: z.boolean(),
  endDate: z.string().nullable(),
  inventoryReserve: z.enum(['ON_SALE', 'ON_FULFILLMENT']),
  fulfillmentTrigger: z.enum(['ASAP', 'EXACT_TIME', 'UNKNOWN']),
  fulfillmentDate: z.string().nullish(),
  hasStockLimit: z.boolean(),
  stockLimit: z.number().nullish(),
  isContinueSellingManaged: z.boolean(),
  sellingPlans: z.object({
    full: sellingPlanFullSchema,
    partial: sellingPlanPartialSchema,
  }),
  products: z.array(productSchema).nullable(),
})

export type FormSchema = z.infer<typeof schema>
export type CampaignFormFieldsWithDeleted = FormSchema & { deleted: { products: FormSchema['products'] } }

const DISABLED_STATUSES = ['PROCESSING', 'PROCESSING_PRODUCTS', 'PROCESSING_CAMPAIGN', 'ARCHIVED']

const CampaignForm = ({ campaignId, campaign }: { campaignId?: string; campaign?: FetchCampaignResponse }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const mutateCampaign = useMutateCampaign(campaignId)

  const isEditing = !!campaignId
  const isDisabled = ((campaign && DISABLED_STATUSES.includes(campaign.status)) || mutateCampaign.isPending) ?? false

  // Settings data
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const defaultValues = {
    name: '',
    isPublished: false,
    isFulfillmentDelayedPending: false,
    startDate: dayjs().tz(settings.timezone).startOf('day').toISOString(),
    hasEndDate: false,
    endDate: null,
    preorderTrigger: 'ALWAYS',
    inventoryReserve: 'ON_SALE',
    fulfillmentTrigger: 'EXACT_TIME',
    fulfillmentDate: null,
    hasStockLimit: false,
    stockLimit: null,
    isContinueSellingManaged: false,
    products: [],
    sellingPlans: {
      full: {
        name: '',
        isActive: false,
        hasDiscount: false,
        discountType: 'PERCENTAGE',
        discountAmount: null,
        partialType: 'PERCENTAGE',
        partialAmount: 100,
      },
      partial: {
        name: '',
        isActive: false,
        partialType: 'PERCENTAGE',
        partialAmount: 0,
        hasDiscount: false,
        discountType: 'PERCENTAGE',
        discountAmount: null,
        finalPaymentTrigger: 'EXACT_TIME',
        paymentDueDate: null,
        numberOfDays: null,
      },
    },
  } satisfies FormSchema

  // Shopify product lookup
  const [productIds, variantIds] = campaign ? getProductAndVariantIds(campaign.products) : [[], []]

  const { data: campaignProducts } = useSuspenseQuery(
    shopifyResourcePickerQueryOptions({
      productIds: productIds ?? [],
      variantIds: variantIds ?? [],
    })
  )

  // Default values for the form
  const defaultFormValues = campaign
    ? merge.withOptions({ allowUndefinedOverrides: false }, defaultValues, {
        isPublished: campaign.isPublished,
        name: campaign.name,
        preorderTrigger: campaign.preorderTrigger,
        startDate: campaign.startDate,
        hasEndDate: campaign.hasEndDate,
        endDate: campaign.endDate,
        hasStockLimit: campaign.hasStockLimit,
        stockLimit: campaign.stockLimit,
        isContinueSellingManaged: campaign.isContinueSellingManaged,
        isFulfillmentDelayedPending: campaign.isFulfillmentDelayedPending,
        inventoryReserve: campaign.inventoryReserve,
        fulfillmentTrigger: campaign.fulfillmentTrigger,
        fulfillmentDate: campaign.fulfillmentDate,
        products: campaignProducts,
        sellingPlans: {
          full: campaign.sellingPlans.full,
          partial: campaign.sellingPlans.partial,
        },
      })
    : defaultValues

  const productLimit = settings.productLimit ?? 250 // TODO: Move this out of here

  const form = useForm<FormSchema>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(
      schema
        .refine(
          (data) => {
            // Name required
            return !!data.name
          },
          {
            // Custom error message
            message: t('validationNameRequired'),
            path: ['name'], // Specify that this error is for the name field
          }
        )
        .refine(
          (data) => {
            // startDate required
            return !!data.startDate
          },
          {
            // Custom error message
            message: t('validationStartDateRequired'),
            path: ['startDate'], // Specify that this error is for the startDate field
          }
        )
        .refine(
          (data) => {
            // If hasEndDate is true, endDate must not be null or undefined
            if (data.hasEndDate) {
              return data.endDate != null
            }
            // If hasEndDate is false, validation passes regardless of endDate
            return true
          },
          {
            // Custom error message
            message: t('validationEndDateRequired'),
            path: ['endDate'], // Specify that this error is for the endDate field
          }
        )
        .refine(
          (data) => {
            // If both startDate and endDate are defined, endDate must be after startDate
            if (data.startDate && data.endDate && data.hasEndDate) {
              return new Date(data.endDate) > new Date(data.startDate)
            }
            // If either startDate or endDate is not defined, validation passes
            return true
          },
          {
            // Custom error message
            message: t('validationEndDateAfterStartDate'),
            path: ['endDate'], // Specify that this error is for the endDate field
          }
        )
        .refine(
          (data) => {
            // Either productIds or variantIds needs to have at least one element
            return data.products && data.products.length > 0
          },
          {
            // Custom error message
            message: t('validationProductsRequired'),
            path: ['products'], // Specify that this error is for the productIds and variantIds fields
          }
        )
        .refine(
          (data) => {
            if (!data.products) {
              return false
            }

            const totalProductsAndVariants = data.products.reduce((acc, product) => {
              const isAllVariants = product.variants.length === 0

              acc += isAllVariants ? 1 : product.variants.length
              return acc
            }, 0)

            // Either productIds or variantIds needs to have at least one element
            return data.products && totalProductsAndVariants <= productLimit
          },
          {
            // Custom error message
            message: t('validationProductsMaxLimit', {
              count: productLimit,
            }),
            path: ['products'], // Specify that this error is for the productIds and variantIds fields
          }
        )
        .refine(
          (data) => {
            // Either sellingPlan.full or sellingPlan.partial needs to be active
            return data.sellingPlans.full.isActive || data.sellingPlans.partial.isActive
          },
          {
            // Custom error message
            message: t('validationPurchaseOptionActive'),
            path: ['sellingPlans'], // Specify that this error is for the sellingPlans field
          }
        )
        .refine(
          (data) => {
            // If sellingPlan.full is active, it needs a name
            if (data.sellingPlans.full.isActive) {
              if (!data.sellingPlans.full.name) {
                return false
              }
            }

            return true
          },
          {
            // Custom error message
            message: t('validationPurchaseOptionNameRequired'),
            path: ['sellingPlans', 'full', 'name'], // Specify that this error is for the sellingPlans field
          }
        )
        .refine(
          (data) => {
            // If not on the free plan and sellingPlan.partial is active, it needs a name
            if (data.sellingPlans.partial.isActive) {
              if (!data.sellingPlans.partial.name) {
                return false
              }
            }

            return true
          },
          {
            // Custom error message
            message: t('validationPurchaseOptionNameRequired'),
            path: ['sellingPlans', 'partial', 'name'], // Specify that this error is for the sellingPlans field
          }
        )
        .refine(
          (data) => {
            // If fulfillmentTrigger is 'EXACT_TIME', fulfillmentDate must be set
            if (data.fulfillmentTrigger === 'EXACT_TIME') {
              return data.fulfillmentDate != null
            }
            // If fulfillmentTrigger is not 'exact time', validation passes
            return true
          },
          {
            // Custom error message
            message: t('validationFulfillmentDateRequired'),
            path: ['fulfillmentDate'], // Specify that this error is for the fulfillmentDate field
          }
        )
        .refine(
          (data) => {
            // If fulfillmentTrigger is 'EXACT_TIME', fulfillmentDate is set, and endDate is set
            if (data.fulfillmentTrigger === 'EXACT_TIME' && data.fulfillmentDate && data.endDate) {
              // fulfillmentDate must be after endDate
              return new Date(data.fulfillmentDate) > new Date(data.endDate)
            }
            // If fulfillmentTrigger is not 'EXACT_TIME', fulfillmentDate is not set, or endDate is not set, validation passes
            return true
          },
          {
            // Custom error message
            message: t('validationFulfillmentDateAfterEndDate'),
            path: ['fulfillmentDate'], // Specify that this error is for the fulfillmentDate field
          }
        )
        .refine(
          (data) => {
            // If fulfillmentTrigger is 'EXACT_TIME', fulfillmentDate is set, and endDate is not set
            if (data.fulfillmentTrigger === 'EXACT_TIME' && data.fulfillmentDate && !data.endDate) {
              // fulfillmentDate must be after startDate
              return new Date(data.fulfillmentDate) > new Date(data.startDate)
            }
            // If fulfillmentTrigger is not 'EXACT_TIME', fulfillmentDate is not set, or endDate is set, validation passes
            return true
          },
          {
            // Custom error message
            message: t('validationFulfillmentDateAfterStartDate'),
            path: ['fulfillmentDate'], // Specify that this error is for the fulfillmentDate field
          }
        )
        .refine(
          (data) => {
            if (data.sellingPlans.partial.isActive) {
              // Partial amount between 0% and 100%
              if (data.sellingPlans.partial.partialType === 'PERCENTAGE') {
                console.log(data.sellingPlans.partial.partialAmount)
                return (
                  typeof data.sellingPlans.partial.partialAmount === 'number' &&
                  data.sellingPlans.partial.partialAmount >= 0 &&
                  data.sellingPlans.partial.partialAmount < 100
                )
              }
            }
            return true
          },
          {
            message: t('validationPartialPlanPartialTypePercentageRange'),
            path: ['sellingPlans', 'partial', 'partialAmount'],
          }
        )
        .refine(
          (data) => {
            if (data.sellingPlans.partial.isActive) {
              // Partial amount between > 0 for fixed
              if (data.sellingPlans.partial.partialType === 'FIXED') {
                return typeof data.sellingPlans.partial.partialAmount === 'number' && data.sellingPlans.partial.partialAmount >= 0
              }
            }
            return true
          },
          {
            message: t('validationPartialPlanPartialTypeFixedRange'),
            path: ['sellingPlans', 'partial', 'partialAmount'],
          }
        )
        .refine(
          (data) => {
            if (data.sellingPlans.full.isActive && data.sellingPlans.full.hasDiscount) {
              // Partial amount between 0% and 100%
              if (data.sellingPlans.full.discountType === 'PERCENTAGE') {
                return data.sellingPlans.full?.discountAmount && data.sellingPlans.full?.discountAmount > 0 && data.sellingPlans.full?.discountAmount < 100
              }
            }
            return true
          },
          {
            message: t('validationSellingPlanDiscountPercentage'),
            path: ['sellingPlans', 'full', 'discountAmount'],
          }
        )
        .refine(
          (data) => {
            if (data.sellingPlans.full.isActive && data.sellingPlans.full.hasDiscount) {
              // Partial amount between 0% and 100%
              if (data.sellingPlans.full.discountType === 'FIXED') {
                return data.sellingPlans.full?.discountAmount && data.sellingPlans.full?.discountAmount > 0
              }
            }
            return true
          },
          {
            message: t('validationSellingPlanDiscountFixed'),
            path: ['sellingPlans', 'full', 'discountAmount'],
          }
        )
        .refine(
          (data) => {
            if (data.sellingPlans.partial.isActive && data.sellingPlans.partial.hasDiscount) {
              // Partial amount between 0% and 100%
              if (data.sellingPlans.partial.discountType === 'PERCENTAGE') {
                return (
                  data.sellingPlans.partial?.discountAmount && data.sellingPlans.partial?.discountAmount > 0 && data.sellingPlans.partial?.discountAmount < 100
                )
              }
            }
            return true
          },
          {
            message: t('validationSellingPlanDiscountPercentage'),
            path: ['sellingPlans', 'partial', 'discountAmount'],
          }
        )
        .refine(
          (data) => {
            if (data.sellingPlans.partial.isActive && data.sellingPlans.partial.hasDiscount) {
              // Partial amount between 0% and 100%
              if (data.sellingPlans.partial.discountType === 'FIXED') {
                return data.sellingPlans.partial?.discountAmount && data.sellingPlans.partial?.discountAmount > 0
              }
            }
            return true
          },
          {
            message: t('validationSellingPlanDiscountFixed'),
            path: ['sellingPlans', 'partial', 'discountAmount'],
          }
        )
        .refine(
          (data) => {
            // If partial selling plan is active and finalPaymentTrigger is 'EXACT_TIME', paymentDueDate is required
            if (data.sellingPlans.partial.isActive) {
              if (data.sellingPlans.partial.finalPaymentTrigger === 'EXACT_TIME') {
                return data.sellingPlans.partial.paymentDueDate != null
              }
            }
            return true
          },
          {
            message: t('validationPartialPlanPaymentDueDateRequired'),
            path: ['sellingPlans', 'partial', 'paymentDueDate'],
          }
        )
        .refine(
          (data) => {
            // If partial selling plan is active and finalPaymentTrigger is 'TIME_AFTER_CHECKOUT', numberOfDays is required
            if (data.sellingPlans.partial.isActive) {
              if (data.sellingPlans.partial.finalPaymentTrigger === 'TIME_AFTER_CHECKOUT') {
                return typeof data.sellingPlans.partial.numberOfDays === 'number' && data.sellingPlans.partial.numberOfDays > 0
              }
            }
            return true
          },
          {
            message: t('validationPartialPlanNumberOfDaysRequired'),
            path: ['sellingPlans', 'partial', 'numberOfDays'],
          }
        )
        .refine(
          (data) => {
            // If hasStockLimit is true, stockLimit is required and must be greater than 0
            if (data.hasStockLimit) {
              return typeof data.stockLimit === 'number' && data.stockLimit > 0
            }
            return true
          },
          {
            message: t('validationStockLimitRange'),
            path: ['stockLimit'],
          }
        )
    ),
    mode: 'onChange',
    shouldFocusError: true,
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = form

  useEffect(() => {
    if (isDisabled) return

    if (isDirty) {
      shopify.saveBar.show('save-bar')
    } else {
      shopify.saveBar.hide('save-bar')
    }
  }, [isDirty, isDisabled])

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const watchPreorderTrigger = watch('preorderTrigger')

  // Create our actions
  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    if (isDisabled) {
      return false
    }

    // Grab the product IDs from the form
    const { products, ...formValues } = data

    // Build up the mutation payload
    let payload = {
      ...formValues,
      products: [] as FormSchema['products'],
      deleted: {
        products: [] as FormSchema['products'],
      },
    }

    if (isEditing) {
      const newItems = determineNewOrUpdated(campaignProducts, products ?? [])
      const deletedItems = determineDeleted(campaignProducts, products ?? [])

      //const { newProducts = [], deletedProducts = [] } = compareProducts(campaignProducts, products ?? [])
      payload.products = newItems
      payload.deleted = {
        products: deletedItems,
      }

      payload.sellingPlans.full.uuid = data.sellingPlans.full.uuid
      payload.sellingPlans.partial.uuid = data.sellingPlans.partial.uuid
    } else {
      payload.products = products
    }

    try {
      await mutateCampaign.mutateAsync(payload, {
        onSuccess: (data: FetchCampaignResponse) => {
          shopify.saveBar.hide('save-bar')

          if (!isEditing) {
            shopify.toast.show(t('campaignsCreatedToast'), {
              duration: 2000,
            })

            queryClient.invalidateQueries({ queryKey: campaignQueryOptions().queryKey, exact: false, type: 'all' })
          } else {
            shopify.toast.show(t('campaignsUpdatedToast'), {
              duration: 2000,
            })
          }

          queryClient.invalidateQueries({ queryKey: campaignQueryOptions({ uuid: campaignId }).queryKey, exact: true, type: 'all' })
          navigate({ to: `/preorders/$uuid`, params: { uuid: data.uuid } })

          return data
        },
      })
    } catch (error) {
      console.log(error)
      shopify.toast.show(t('genericErrorToast'), {
        duration: 2000,
        isError: true,
      })
    }

    return true
  }

  const onError: SubmitErrorHandler<MutateCampaignPayload> = () => {
    setCanFocus(true)

    shopify.toast.show(t('genericErrorToast'), {
      duration: 3000,
      isError: true,
    })

    return false
  }

  return (
    <>
      <FormProvider {...form}>
        <SaveBar id="save-bar">
          <button
            variant="primary"
            onClick={() => handleSubmit(onSubmit, onError)()}
            disabled={mutateCampaign.isPending}
            loading={mutateCampaign.isPending ? '' : undefined}
          ></button>
          <button
            onClick={() => {
              reset()
            }}
            disabled={mutateCampaign.isPending}
          ></button>
        </SaveBar>
        <Layout.AnnotatedSection title={t('campaignsDetailsTitle')} description={t('campaignsDetailsDescription')}>
          <Disabled isDisabled={isDisabled}>
            <CampaignName />
          </Disabled>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignsAvailabilityTitle')} description={t('campaignsAvailabilityDescription')}>
          <Disabled isDisabled={isDisabled}>
            <BlockStack gap="400">
              <CampaignTrigger />
              {watchPreorderTrigger === 'IN_STOCK' && <CampaignStockLimit publishedAt={campaign?.publishedAt ?? null} />}
              <CampaignInventoryPolicy />
            </BlockStack>
          </Disabled>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignDurationTitle')} description={t('campaignDurationDescription')}>
          <Disabled isDisabled={isDisabled}>
            <CampaignDuration status={campaign?.status} />
          </Disabled>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignProductsTitle')} description={t('campaignProductsDescription')}>
          <Disabled isDisabled={isDisabled}>
            <ProductSelection status={campaign?.status} />
          </Disabled>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignPurchaseOptionsTitle')} description={t('campaignPurchaseOptionsDescription')}>
          <Disabled isDisabled={isDisabled}>
            <CampaignPayment />
          </Disabled>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignFulfillmentTitle')} description={t('campaignFulfillmentDescription')}>
          <Disabled isDisabled={isDisabled}>
            <CampaignFulfillment />
          </Disabled>
        </Layout.AnnotatedSection>
      </FormProvider>
    </>
  )
}

const getProductAndVariantIds = (products: FetchCampaignResponse['products']) => {
  if (!products) {
    return [[], []]
  }

  return products.reduce(
    (acc, product) => {
      if (!product.variants || product.variants.length === 0) {
        acc[0].push(product.id)
      } else if (product) {
        acc[1].push(...product.variants.map((variant) => variant.id))
      }

      return acc
    },
    [[], []] as [string[], string[]]
  )
}

function determineNewOrUpdated(originalData: NonNullable<FormSchema['products']>, updatedData: NonNullable<FormSchema['products']>) {
  const newOrUpdated = []

  for (let updatedItem of updatedData) {
    const originalItem = originalData.find((item) => item.id === updatedItem.id)

    if (!originalItem) {
      newOrUpdated.push(updatedItem)
    } else {
      // Product originally had variants but now doesn't
      if (originalItem.variants && originalItem.variants.length > 0 && (!updatedItem.variants || updatedItem.variants.length === 0)) {
        // Add the product back with no variants
        newOrUpdated.push({
          ...updatedItem,
          variants: [],
        })
      } else {
        const originalVariants = new Set(originalItem.variants.map((v) => v.id))

        const newVariants = updatedItem.variants.filter((v) => !originalVariants.has(v.id))

        if (newVariants.length > 0) {
          newOrUpdated.push({
            ...updatedItem,
            variants: newVariants,
          })
        }
      }
    }
  }

  return newOrUpdated
}

function determineDeleted(originalData: NonNullable<FormSchema['products']>, updatedData: NonNullable<FormSchema['products']>) {
  const deleted = []

  for (let originalItem of originalData) {
    const updatedItem = updatedData.find((item) => item.id === originalItem.id)

    if (!updatedItem) {
      // This product has been completely removed
      deleted.push({
        ...originalItem,
        variants: originalItem.variants || [],
      })
    } else {
      // Product originally had no variants but now has variants
      if ((!originalItem.variants || originalItem.variants.length === 0) && updatedItem.variants && updatedItem.variants.length > 0) {
        // This product needs to be deleted
        deleted.push({
          ...originalItem,
          variants: [],
        })
      } else if (originalItem.variants && originalItem.variants.length > 0 && (!updatedItem.variants || updatedItem.variants.length === 0)) {
        // Product originally had variants but now doesn't, delete all variants
        deleted.push({
          ...originalItem,
          variants: originalItem.variants,
        })
      } else {
        // Check for deleted variants
        const updatedVariants = new Set(updatedItem.variants.map((v) => v.id))
        const deletedVariants = originalItem.variants.filter((v) => !updatedVariants.has(v.id))

        if (deletedVariants.length > 0) {
          deleted.push({
            ...originalItem,
            variants: deletedVariants,
          })
        }
      }
    }
  }

  return deleted
}

export { CampaignForm }
