import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation, Trans } from 'react-i18next'

import {
  bisListQueryOptions,
  bisRestocksQueryOptions,
  emailTemplatesQueryOptions,
  reportsBisAggregateMetricsQueryOptions,
  settingsQueryOptions,
  shopifyProductQueryOptions,
} from '@/common/queryOptions'
import { Banner, BlockStack, Box, Button, Card, Layout, SkeletonPage, Text } from '@shopify/polaris'
import { BackInStockContactsTable } from '@/components/backInStock/BackInStockContactsTable'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { BackInStockRestocksTable } from '@/components/backInStock/BackInStockRestocksTable'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { useSuspenseQueries } from '@tanstack/react-query'
import { useMutateBisRestock } from '@/hooks/useMutateBisRestock'
import { BackInStockProductChart } from '@/components/backInStock/BackInStockProductChart'
import { SkeletonChart } from '@/components/shared/SkeletonChart'
import { useState } from 'react'
import { BackInStockPreview } from '@/components/emailTemplates/BackInStock'

export const Route = createFileRoute('/back-in-stock/products/$productId/')({
  loader: async ({ params: { productId }, context: { queryClient }, route: { path } }) => {
    try {
      Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(emailTemplatesQueryOptions()),
        queryClient.ensureQueryData(shopifyProductQueryOptions({ productId: `gid://shopify/Product/${productId}` })),
        queryClient.ensureQueryData(bisRestocksQueryOptions({ id: productId, page: 1, limit: 5 })),
        queryClient.ensureQueryData(reportsBisAggregateMetricsQueryOptions({ productId })),
        queryClient.ensureQueryData(bisListQueryOptions({ id: productId, page: 1, sendStatus: undefined })),
      ])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productId } = useParams({ strict: false }) as { productId: string }
  const [isAwaitingRestock, setIsAwaitingRestock] = useState(false)

  const [
    { data: settings },
    { data: emails },
    {
      data: { product: shopifyProduct },
    },
    {
      data: { data: restocks },
    },
  ] = useSuspenseQueries({
    queries: [
      settingsQueryOptions(),
      emailTemplatesQueryOptions(),
      shopifyProductQueryOptions({ productId: `gid://shopify/Product/${productId}` }),
      bisRestocksQueryOptions({ id: productId, page: 1, limit: 5 }),
    ],
  })

  const restockMutation = useMutateBisRestock({ id: productId })

  const hasCustomEmailTemplatesFeature = settings.featureCustomEmailTemplates
  const isRestockInProgress = restocks?.some((restock) => restock.status !== 'COMPLETE' && restock.status !== 'ERROR')

  const sendNotification = async () => {
    try {
      await restockMutation.mutateAsync({
        id: productId,
      })

      setIsAwaitingRestock(true)
    } catch (error) {
    } finally {
      const sendNotificationModal = document.getElementById('send-notification-modal') as UIModalElement
      sendNotificationModal.hide()
    }
  }

  return (
    <PageFrame
      title={shopifyProduct.title}
      backAction={{
        content: t('backInStockDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/back-in-stock` })
        },
      }}
      primaryAction={
        <Button
          variant="primary"
          disabled={restockMutation.isPending || isRestockInProgress}
          loading={restockMutation.isPending}
          onClick={async () => {
            const sendNotificationModal = document.getElementById('send-notification-modal') as UIModalElement
            sendNotificationModal.show()
          }}
        >
          {t('backInStockProductSendEmailCta')}
        </Button>
      }
      actionGroups={[
        {
          title: t('genericMoreActions'),
          actions: [
            {
              content: t('genericViewProduct'),
              url: `shopify://admin/products/${productId}`,
              target: '_blank',
            },
            {
              content: t('backInStockProductViewBreakdownCta'),
              onAction: () => {
                navigate({ to: `/back-in-stock/products/$productId/variants`, params: { productId } })
              },
            },
          ],
        },
      ]}
    >
      <Layout.Section>
        <BackInStockProductChart productId={productId} />
      </Layout.Section>
      <Layout.Section>
        <SectionTitle title={t('backInStockContactTableTitle')} description={t('backInStockContactTableSubtitle')} />
        <BackInStockContactsTable productId={productId} />
      </Layout.Section>
      <Layout.Section>
        <SectionTitle title={t('backInStockRestockTableTitle')} description={t('backInStockRestockTableSubtitle')} />
        <BackInStockRestocksTable productId={productId} isAwaitingRestock={isAwaitingRestock} setIsAwaitingRestock={setIsAwaitingRestock} />
      </Layout.Section>

      <ui-modal id="send-notification-modal" variant="base">
        <Box padding="400">
          <div className="modalBannerCard">
            <Card padding="0">
              <BlockStack gap="400">
                <Text as="p">
                  <Trans t={t} i18nKey="backInStockRestockModalDescription">
                    . <strong> . </strong> .
                  </Trans>
                </Text>
                <Banner tone="info">
                  <p>{t('backInStockRestockModalWarningBanner')}</p>
                </Banner>
              </BlockStack>
            </Card>
            <BackInStockPreview
              shopName={settings.shopName}
              templateHtml={
                hasCustomEmailTemplatesFeature
                  ? emails.backInStock.hasCustomTemplate
                    ? emails.backInStock.templateHtml
                    : emails.backInStock.defaultTemplateHtml
                  : emails.backInStock.defaultTemplateHtml
              }
              body={emails.backInStock.body}
              title={emails.backInStock.title}
              primaryButtonText={emails.backInStock.primaryButtonText}
              productName={shopifyProduct.title}
              productImage={shopifyProduct.featuredImage?.url}
              productImageAlt={shopifyProduct.featuredImage?.altText}
            />
          </div>
        </Box>
        <ui-title-bar title={t('backInStockRestockModalTitle')}>
          <button variant="primary" onClick={sendNotification} loading={restockMutation.isPending ? '' : undefined}>
            {t('backInStockRestockModalCta')}
          </button>
          <button
            onClick={() => {
              const sendNotificationModal = document.getElementById('send-notification-modal') as UIModalElement
              sendNotificationModal.hide()
            }}
          >
            {t('backInStockRestockModalCancelCta')}
          </button>
        </ui-title-bar>
      </ui-modal>
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage primaryAction backAction>
      <Layout>
        <Layout.Section>
          <SkeletonChart />
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('backInStockContactTableTitle')} description={t('backInStockContactTableSubtitle')} />
          <SkeletonTable rows={5} />
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('backInStockRestockTableTitle')} description={t('backInStockRestockTableSubtitle')} />
          <SkeletonTable rows={3} />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
