import { BlockStack, Box, Card, SkeletonBodyText, SkeletonDisplayText, Text } from '@shopify/polaris'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const BisButtonWidgetPreview = () => {
  const { t } = useTranslation()
  const { watch } = useFormContext()

  const bisButtonText = watch('bisButtonText')
  const bisButtonTextColor = watch('bisButtonTextColor')
  const bisButtonBackgroundColor = watch('bisButtonBackgroundColor')

  return (
    <BlockStack gap="400">
      <Card>
        <div
          style={{
            fontSize: '14px',
          }}
        >
          <Box>
            <SkeletonDisplayText size="small" />
            <Box minHeight="10px" />
            <Box width="80px">
              <SkeletonBodyText lines={1} />
            </Box>
            <Box minHeight="20px" />
            <SkeletonBodyText lines={3} />
            <Box minHeight="20px" />
            <div
              style={{
                margin: '10px 0',
              }}
            >
              <SkeletonDisplayText size="extraLarge" maxWidth="100%" />
            </div>
            <div
              style={{
                cursor: 'pointer',
                padding: '0.8rem',
                border: bisButtonBackgroundColor ? undefined : '1px solid #000',
                backgroundColor: bisButtonBackgroundColor ? bisButtonBackgroundColor : '#000000',
                color: bisButtonTextColor ? bisButtonTextColor : '#ffffff',
              }}
            >
              <Text as="span" variant="bodyLg" alignment="center">
                {bisButtonText ? bisButtonText : t('displayBisButtonPlaceholder')}
              </Text>
            </div>
          </Box>
        </div>
      </Card>
    </BlockStack>
  )
}

export { BisButtonWidgetPreview }
