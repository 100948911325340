import { buildAPIUrl } from '@/common/functions'
import { localisationQueryOptions } from '@/common/queryOptions'
import { FetchLocalizationResponse, MutateLocalizationPayload } from '@/common/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useMutateLocalization = () => {
  const queryClient = useQueryClient()
  const requestURL = buildAPIUrl('localisation')

  return useMutation<FetchLocalizationResponse, unknown, Partial<MutateLocalizationPayload>>({
    mutationFn: (data: Partial<MutateLocalizationPayload> = {}) =>
      fetch(requestURL, {
        body: JSON.stringify(data),
        method: 'PUT',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error updating localisation'
          }
        })
        .then((res) => res.message),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: localisationQueryOptions().queryKey,
        exact: true,
      })
    },
  })
}
