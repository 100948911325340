import { RouterProvider, createRouter } from '@tanstack/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import i18n from './i18n/config'
import { I18nextProvider } from 'react-i18next'
import { AppProvider as PolarisProvider } from '@shopify/polaris'
import { createTheme, PolarisVizLightTheme, PolarisVizProvider } from '@shopify/polaris-viz'
import enTranslations from '@shopify/polaris/locales/en.json'
import { I18nContext, I18nManager } from '@shopify/react-i18n'

// Import the generated route tree
import { routeTree } from './routeTree.gen'

// Third party
import '@shopify/polaris/build/esm/styles.css'
import '@shopify/polaris-viz/build/esm/styles.css'

import './App.scss'

// Components
import { AuthGuard } from '@/components/shared/AuthGuard'
import { NotFound } from '@/components/shared/NotFound'
import { Error } from '@/components/shared/Error'

// Create a new query client instance
const queryClient = new QueryClient()

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPendingMs: 0,
  defaultPendingMinMs: 500,
  defaultErrorComponent: () => <Error />,
  defaultNotFoundComponent: () => <NotFound />,
  //defaultViewTransition: true,
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

// Create a new i18n manager instance
const i18nManager = new I18nManager({
  locale: shopify.config.locale,
})

// Create a new line theme
const lineTheme = createTheme(
  {
    chartContainer: {
      padding: '10px 0 0 0',
    },
    grid: {
      horizontalOverflow: false,
    },
  },
  PolarisVizLightTheme
)

const polarisVizThemes = {
  lineTheme: lineTheme,
}

// Define the App component
const App = () => {
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <QueryClientProvider client={queryClient}>
        <PolarisVizProvider themes={polarisVizThemes}>
          <I18nContext.Provider value={i18nManager}>
            <PolarisProvider i18n={enTranslations}>
              <AuthGuard>
                <RouterProvider router={router} />
              </AuthGuard>
            </PolarisProvider>
          </I18nContext.Provider>
        </PolarisVizProvider>
      </QueryClientProvider>
    </I18nextProvider>
  )
}

export { App }
