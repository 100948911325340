import { Badge, Tooltip } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { BIS_AVG_CONVERSION_RATE } from '@/common/constants.ts'
import { formatNumber } from '@/common/functions.ts'

const ConversionRatePercentage = ({ conversionRate = 0 }: { conversionRate: number }) => {
  const { t } = useTranslation()

  let tooltip: string = ''
  let tone: 'info' | 'success' | 'magic' | 'warning' | 'attention' | 'critical' | undefined

  if (!conversionRate) {
    return (
      <Badge>
        {t('genericPercentage', {
          percentage: 0,
        })}
      </Badge>
    )
  }

  const conversionRateDelta: number = conversionRate - BIS_AVG_CONVERSION_RATE

  if (conversionRateDelta < -8) {
    tone = 'warning'
    tooltip = t('backInStockConversionRateTooltipBad')
  } else if (conversionRateDelta >= -8 && conversionRateDelta < -5) {
    tone = 'attention'
    tooltip = t('backInStockConversionRateTooltipLow')
  } else if (conversionRateDelta >= -5 && conversionRateDelta < -2) {
    tone = 'info'
    tooltip = t('backInStockConversionRateTooltipAverage')
  } else if (conversionRateDelta >= -2 && conversionRateDelta < 5) {
    tone = 'success'
    tooltip = t('backInStockConversionRateTooltipGood')
  } else if (conversionRateDelta >= 5) {
    tone = 'magic'
    tooltip = t('backInStockConversionRateTooltipHigh')
  } else {
    tone = undefined
  }

  return (
    <>
      {tooltip ? (
        <div className="inlineTableIcon">
          <Tooltip content={tooltip} hasUnderline={false}>
            <Badge tone={tone}>
              {t('genericPercentage', {
                percentage: formatNumber(conversionRate),
              })}
            </Badge>
          </Tooltip>
        </div>
      ) : null}
    </>
  )
}

export { ConversionRatePercentage }
