import { Box } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

interface HTMLPreviewProps {
  subject?: string
  htmlContent: string
  from?: string
  to?: string
  replyTo?: string
}

const EmailPreview = ({ subject, htmlContent, from, to, replyTo }: HTMLPreviewProps) => {
  const { t } = useTranslation()

  return (
    <Box borderColor="border-secondary" borderWidth="025">
      {from || to || replyTo ? (
        <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
          {from ? (
            <>
              <strong>{t('emailTemplatesFieldFrom')}</strong>: {from} <br />
            </>
          ) : (
            ''
          )}
          {replyTo ? (
            <>
              <strong>{t('emailTemplatesFieldReplyTo')}</strong>: {replyTo}
              <br />
            </>
          ) : (
            ''
          )}
          {to ? (
            <>
              <strong>{t('emailTemplatesFieldTo')}</strong>: {to}
            </>
          ) : (
            ''
          )}
        </Box>
      ) : (
        ''
      )}
      {subject && (
        <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
          <strong>{t('emailTemplatesFieldSubject')}</strong>: {subject}
        </Box>
      )}
      <Box>
        <iframe
          title="Preview"
          srcDoc={htmlContent}
          style={{
            width: '100%',
            minHeight: '640px',
            boxSizing: 'content-box',
            border: '0',
          }}
          sandbox="allow-scripts"
        />
      </Box>
    </Box>
  )
}

export { EmailPreview }
