import { MetricBar } from '@/components/shared/MetricBar'
import { useTranslation } from 'react-i18next'
import { chartTitleFormatter, chartTooltipTitleFormatter, formatNumber } from '@/common/functions.ts'
import { DataSeries } from '@shopify/polaris-viz-core'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQueries } from '@tanstack/react-query'
import { reportsBisListQueryOptions, reportsBisQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Card } from '@shopify/polaris'
import { useState } from 'react'
import { BisReportQueryResponse } from '@/common/queryFunctions'
import { ConversionRatePercentage } from '@/components/shared/ConversionRatePercentage'

const BackInStockChart = () => {
  const [i18n] = useI18n()
  const { t } = useTranslation()
  const [filterStartDate, _] = useState(undefined)
  const [filterEndDate, __] = useState(undefined)

  const [{ data: settings }, { data: bisReportEmails }, { data: bis }] = useSuspenseQueries({
    queries: [
      settingsQueryOptions(),
      reportsBisListQueryOptions({ from: filterStartDate, to: filterEndDate }),
      reportsBisQueryOptions({
        from: undefined,
        to: undefined,
      }),
    ],
  })

  const chartData: DataSeries[] = [
    {
      name: t('backInStockMetricsSignups'),
      data: bisReportEmails.map((d) => ({
        key: d.key,
        value: d.signups,
      })),
    },
  ]

  const totalSignups = bisReportEmails.reduce((total, current) => total + current.signups, 0)
  const totalSends = bisReportEmails.reduce((total, current) => total + current.sends, 0)
  const totalConversions = bisReportEmails.reduce((total, current) => total + current.conversions, 0)
  const conversionRate = (totalConversions / totalSends) * 100

  const calculateRevenue = (group: BisReportQueryResponse) => group.data.reduce((total, current) => total + (current.amount ?? 0), 0)

  const renderRevenueBlocks = (): JSX.Element[] => {
    if (bis.length === 0) {
      return [
        <MetricBar.ValueBlock
          key="revenue-0"
          title={t('backInStockMetricsRevenue', { currency: settings.currency })}
          value={i18n.formatCurrency(0, { currency: settings.currency })}
        />,
      ]
    }

    return bis.map((group) => (
      <MetricBar.ValueBlock
        key={`revenue-${group.currency}`}
        title={t('backInStockMetricsRevenue', { currency: group.currency })}
        value={i18n.formatCurrency(calculateRevenue(group), { currency: group.currency })}
      />
    ))
  }

  return (
    <Card padding="400">
      <MetricBar
        blocks={[
          <MetricBar.ValueBlock title={t('backInStockMetricsSignups')} value={formatNumber(totalSignups)} />,
          <MetricBar.ValueBlock title={t('backInStockMetricsNotifications')} value={formatNumber(totalSends)} />,
          ...renderRevenueBlocks(),
          <MetricBar.ValueBlock title={t('backInStockMetricsConversionRate')} value={<ConversionRatePercentage conversionRate={conversionRate} />} />,
        ]}
        chart={
          <MetricBar.Chart
            data={chartData}
            xAxisOptions={{
              labelFormatter: chartTitleFormatter,
            }}
            tooltipOptions={{
              titleFormatter: chartTooltipTitleFormatter,
            }}
          />
        }
      ></MetricBar>
    </Card>
  )
}

export { BackInStockChart }
