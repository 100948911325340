import { FinancialStatus, PaymentStatus } from '@/common/types'
import { Tooltip, Icon } from '@shopify/polaris'
import { CheckCircleIcon, ClockIcon, AlertTriangleIcon, CreditCardIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { formatDateForShopify } from '@/common/datetime.ts'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'

const AutoPaymentIcon = ({
  paymentStatus,
  financialStatus,
  paymentDueOn,
  jobId,
}: {
  paymentStatus: PaymentStatus
  financialStatus: FinancialStatus
  paymentDueOn: string
  jobId?: string | null
}) => {
  const { t } = useTranslation()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const paymentDueDate = formatDateForShopify(paymentDueOn, settings.timezone)

  let tooltip: string = ''
  let icon: typeof ClockIcon | undefined
  let tone: 'critical' | 'success' | undefined

  switch (paymentStatus) {
    case 'AWAITING_DEFERRED':
      tooltip = t('ordersTableAutoPaymentStatusAwaitingDeferred', {
        date: paymentDueDate,
      })
      tone = undefined
      icon = CreditCardIcon
      break
    case 'QUEUED':
      tooltip = t('ordersTableAutoPaymentStatusQueued')
      tone = undefined
      icon = ClockIcon
      break
    case 'PROCESSING':
      if (financialStatus === 'PAID') {
        tooltip = t('ordersTableAutoPaymentStatusPaid', {
          date: paymentDueDate,
        })
        tone = 'success'
        icon = CheckCircleIcon
      } else {
        tooltip = t('ordersTableAutoPaymentStatusProcessing')
        tone = undefined
        icon = ClockIcon
      }
      break
    case 'PAID':
      if (jobId) {
        tooltip = t('ordersTableAutoPaymentStatusPaid', {
          date: paymentDueDate,
        })
        tone = 'success'
        icon = CheckCircleIcon
      }
      break
    case 'ERROR':
      tooltip = t('ordersTableAutoPaymentStatusError')
      tone = 'critical'
      icon = AlertTriangleIcon
      break
  }

  return tooltip && icon ? (
    <div className="inlineTableIcon">
      <Tooltip content={tooltip}>
        <Icon source={icon} tone={tone} accessibilityLabel={tooltip} />
      </Tooltip>
    </div>
  ) : null
}

export { AutoPaymentIcon }
