import { ChecklistItemProps } from '@/components/shared/Checklist'
import { produce } from 'immer'
import { useState } from 'react'
/**
 * Keeps track of checklist items
 */
export const useChecklist = () => {
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()

  const [items, setItems] = useState<ChecklistItemProps[]>([])

  const addItems = (newItems: ChecklistItemProps[]) => {
    setItems((prev) => [...prev, ...newItems])
  }

  const removeItem = (id: string) => {
    setItems((prev) => prev.filter((item) => item.id !== id))
  }

  const clearItems = () => {
    setItems([])
  }

  const isAllItemsComplete = items.every((item) => item.status === 'COMPLETE')

  const updateItem = (id: string, item: Partial<ChecklistItemProps>) => {
    setItems((prev) => {
      return produce(prev, (draft) => {
        const index = draft.findIndex((i) => i.id === id)
        if (index !== -1) {
          draft[index] = { ...draft[index], ...item }
        }
      })
    })
  }

  return {
    title,
    setTitle,
    description,
    setDescription,
    items,
    addItems,
    removeItem,
    clearItems,
    updateItem,
    isAllItemsComplete,
  }
}
