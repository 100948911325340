import { useState } from 'react'
import { BlockStack, Box, Button, InlineStack, Popover, Scrollable, SkeletonBodyText, SkeletonDisplayText, Text, Thumbnail } from '@shopify/polaris'
import { useFetchOrderFulfillments } from '@/hooks/useFetchOrderFulfillments'
import { FulfillmentBadge } from './FulfillmentBadge'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

type Props = {
  orderId: string
  itemCount: number
}

const ProductPopoverSkeleton = () => {
  return (
    <Box paddingBlockStart="200" paddingBlockEnd="150" paddingInline="300">
      <Box minWidth="300px" paddingBlockStart="100" paddingBlockEnd="150">
        <BlockStack gap="400">
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={1} />
        </BlockStack>
      </Box>
    </Box>
  )
}

const ProductPopover: React.FC<Props> = ({ orderId, itemCount }) => {
  const { t } = useTranslation()
  const [popoverActive, setPopoverActive] = useState(false)

  const displayedText = t('genericItemsCount', {
    count: itemCount,
  })

  const { queryOptions } = useFetchOrderFulfillments({ orderId: orderId })
  const { data, isLoading } = useQuery({ ...queryOptions, enabled: popoverActive && itemCount > 0 })

  const content = () => {
    if (isLoading) {
      return <ProductPopoverSkeleton />
    }

    if (!data) {
      return (
        <Text as="p" variant="bodyMd">
          {t('ordersTableEmptyProductsPopoverError')}
        </Text>
      )
    }

    return (
      <Scrollable shadow style={{ maxHeight: '400px' }}>
        {data.order.fulfillmentOrders.nodes.map((fulfillment) => {
          const lineItems = fulfillment.lineItems.nodes

          // If there are no line items, just skip
          if (lineItems.length === 0) {
            return null
          }

          return (
            <Popover.Section key={fulfillment.id}>
              <Box minWidth="300px" paddingBlockStart="100" paddingBlockEnd="150">
                <BlockStack gap="400">
                  <BlockStack>
                    <Box paddingBlockEnd="200">
                      <Text as="h1" variant="bodySm" fontWeight="semibold" tone="subdued">
                        {fulfillment.assignedLocation?.name ?? t('ordersTablePopoverStoreLocation')}
                      </Text>
                    </Box>
                    {fulfillment.deliveryMethod.methodType !== 'NONE' && (
                      <Box paddingBlockEnd="100">
                        <Text as="p" variant="bodyMd" fontWeight="medium">
                          {t(`ordersTablePopoverDeliveryMethod_${fulfillment.deliveryMethod.methodType}`)}
                        </Text>
                      </Box>
                    )}
                    <Box>
                      <FulfillmentBadge fulfillmentStatus={fulfillment.status} />
                    </Box>
                  </BlockStack>

                  <BlockStack gap="200">
                    {lineItems.map((lineItem) => {
                      return (
                        <InlineStack key={lineItem.id} gap="500" align="space-between" wrap={false}>
                          <InlineStack gap="300" align="center" wrap={false}>
                            <Thumbnail alt={lineItem.image.altText ?? ''} source={lineItem.image.url} />
                            <Box>
                              <BlockStack>
                                <Text as="h3" variant="bodyMd" fontWeight="medium">
                                  <Button
                                    variant="plain"
                                    target="_blank"
                                    onClick={() => {
                                      window.open(`shopify://admin/orders/${data.order.legacyResourceId}`, '_blank', 'noopener,noreferrer')
                                      setPopoverActive(false)
                                    }}
                                  >
                                    {lineItem.variantTitle ?? lineItem.productTitle}
                                  </Button>
                                </Text>
                                <Text as="span" variant="bodyMd" fontWeight="medium" tone="subdued">
                                  {lineItem.sku}
                                </Text>
                              </BlockStack>
                            </Box>
                          </InlineStack>
                          <Box>
                            <Text as="span" variant="bodyMd" fontWeight="medium" tone="subdued">
                              x {lineItem.totalQuantity}
                            </Text>
                          </Box>
                        </InlineStack>
                      )
                    })}
                  </BlockStack>
                </BlockStack>
              </Box>
            </Popover.Section>
          )
        })}
      </Scrollable>
    )
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Popover
        active={popoverActive}
        preferredAlignment="left"
        preferredPosition="below"
        preventCloseOnChildOverlayClick={false}
        activator={
          <Button
            variant="monochromePlain"
            removeUnderline /* deprecated but no alternative? */
            disclosure={itemCount > 0}
            onClick={() => {
              if (itemCount === 0) return
              setPopoverActive(!popoverActive)
              document.body.click() // fixes stacking popovers
            }}
          >
            {displayedText}
          </Button>
        }
        onClose={() => setPopoverActive(false)}
        fluidContent={true}
      >
        {content()}
      </Popover>
    </div>
  )
}

export { ProductPopover }
