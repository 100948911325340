import { BlockStack, Card, Text, InlineStack, Box, Button, TextField, InlineGrid } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '@/common/functions.ts'
import { useEffect, useState } from 'react'
import { AppPlan } from '@/common/types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { appPlanQueryOptions } from '@/common/queryOptions'
import { useMutatePlan } from '@/hooks/useMutatePlan'

export const CustomPlanCard = ({ customPlan, isCurrent }: { customPlan: AppPlan | null; isCurrent: boolean }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [code, setCode] = useState<string>('')
  const [codeError, setCodeError] = useState<string | undefined>(undefined)
  const [appPlanCode, setAppPlanCode] = useState<AppPlan | undefined>(undefined)

  const title = t(`appPlansCustomTitle`)
  const description = t(`appPlansCustomDescription`)

  const { data, refetch, isFetching } = useQuery({
    ...appPlanQueryOptions({ code }),
    enabled: false,
    structuralSharing: false,
    staleTime: 0,
  })

  useEffect(() => {
    if (!data) return // If data is undefined, return early

    const {
      message: [appPlan],
      status,
    } = data

    if (status) {
      setAppPlanCode(appPlan as AppPlan)
      setCodeError(undefined)
    } else {
      console.log('HERE')
      setCodeError(t('appPlansCustomCodeError'))
      setAppPlanCode(undefined)
    }
  }, [data])

  const renderPlan = appPlanCode ?? customPlan ?? null

  const mutation = useMutatePlan()

  const upgradePlan = async () => {
    if (!code) return

    const res = await mutation.mutateAsync({
      appCode: code,
    })

    if (res) {
      open(res, '_top')
    }
  }

  return (
    <div
      style={{
        borderRadius: '.75rem',
        position: 'relative',
        zIndex: '0',
      }}
    >
      <Box
        borderColor={isCurrent && !appPlanCode ? 'border-emphasis' : undefined}
        borderWidth={isCurrent && !appPlanCode ? '050' : undefined}
        borderRadius={isCurrent && !appPlanCode ? '300' : undefined}
      >
        <Card>
          <BlockStack gap="400">
            <BlockStack gap="200" align="start">
              <Text as="h3" variant="headingLg">
                {title}
              </Text>
              {description ? (
                <Text as="p" variant="bodySm" tone="subdued">
                  {description}
                </Text>
              ) : null}
            </BlockStack>

            {((isCurrent && renderPlan) || appPlanCode) && (
              <BlockStack gap="400">
                <PlanPrice
                  price={renderPlan?.price}
                  discountDuration={renderPlan?.app_plan_code?.discountDuration}
                  discountPercentage={renderPlan?.app_plan_code?.discountPercentage}
                  trialDays={renderPlan?.app_plan_code?.trialDays}
                  interval={renderPlan?.interval}
                />

                {renderPlan && <FeatureList plan={renderPlan} />}

                {appPlanCode && (
                  <BlockStack gap="100">
                    <Text tone="subdued" as="p" variant="bodyMd">
                      {t('appPlansCode')}: <strong>{code}</strong>
                    </Text>
                  </BlockStack>
                )}
              </BlockStack>
            )}

            <Box paddingBlockStart="200">
              <BlockStack gap="400">
                {!appPlanCode && (
                  <TextField
                    value={code}
                    autoComplete="off"
                    label={t('appPlansCustomCodeFieldLabel')}
                    onChange={setCode}
                    size="slim"
                    disabled={isFetching}
                    error={codeError}
                    connectedRight={
                      <Button onClick={refetch} disabled={isFetching || !code} loading={isFetching}>
                        {t('appPlansCustomCodeButtonLabel')}
                      </Button>
                    }
                  />
                )}

                {appPlanCode && (
                  <InlineGrid columns={2} gap="200">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setAppPlanCode(undefined)
                        setCode('')
                        queryClient.removeQueries({
                          queryKey: [
                            'appPlan',
                            {
                              code,
                            },
                          ],
                        })
                      }}
                    >
                      {t('genericCancel')}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={upgradePlan}
                      disabled={mutation.isPending || appPlanCode.uuid === customPlan?.app_plan_code?.uuid}
                      loading={mutation.isPending}
                    >
                      {t('appPlansChangeCta')}
                    </Button>
                  </InlineGrid>
                )}
              </BlockStack>
            </Box>
          </BlockStack>
        </Card>
      </Box>
    </div>
  )
}

const PlanPrice = ({
  price,
  discountDuration,
  discountPercentage,
  trialDays,
  interval,
}: {
  price?: number
  discountDuration?: number | null
  discountPercentage?: number | null
  trialDays?: number | null
  interval?: 'EVERY_30_DAYS' | 'ANNUAL'
}) => {
  const { t } = useTranslation()
  const fullPriceFormatted = formatPrice(price ?? 0, 'USD')
  const discountPriceFormatted = price ? formatPrice(price * (1 - (discountPercentage ?? 0) / 100), 'USD') : fullPriceFormatted

  let billingTerms: string[] = []

  if (trialDays) {
    billingTerms.push(
      t('appPlansCustomTrialLine', {
        count: trialDays,
      })
    )
  }

  if (discountPercentage) {
    if (discountDuration) {
      billingTerms.push(
        t(interval === 'EVERY_30_DAYS' ? 'appPlansCustomDiscountDurationMonthlyLine' : 'appPlansCustomDiscountDurationAnnualLine', {
          count: discountDuration,
          price: discountPriceFormatted,
          fullPrice: fullPriceFormatted,
          discount: discountPercentage,
        })
      )
    } else {
      billingTerms.push(
        t('appPlansCustomDiscountIndefiniteLine', {
          price: discountPriceFormatted,
          discount: discountPercentage,
        })
      )
    }
  }

  return (
    <BlockStack gap="100">
      <InlineStack blockAlign="end" gap="100" align="start">
        <Text as="h2" variant="headingXl">
          {discountPercentage && discountDuration === null ? discountPriceFormatted : fullPriceFormatted}
        </Text>
        <Box paddingBlockEnd="200">
          <Text as="p" variant="bodySm">
            / {interval === 'EVERY_30_DAYS' ? t('appPlansFrequencyMonth') : t('appPlansFrequencyYear')}
          </Text>
        </Box>
      </InlineStack>
      {price !== null && (
        <Text tone="subdued" as="p" variant="bodyMd">
          {billingTerms.join(' ')}
        </Text>
      )}
    </BlockStack>
  )
}

const FeatureList = ({ plan }: { plan: AppPlan }) => {
  const { t } = useTranslation()

  const features = [
    plan.monthlyOrderLimit &&
      t('appPlansPreordersCount', {
        limit: plan.monthlyOrderLimit,
      }),
    plan.monthlyEmailLimit &&
      t('appPlansEmailsCount', {
        limit: plan.monthlyEmailLimit,
      }),
    plan.featurePartialPayments && t('appPlansCustomPartialPayments'),
    plan.featureCustomEmailDomain && t('appPlansCustomEmailDomainTemplate'),
  ]

  return (
    <BlockStack gap="100">
      {features.map(
        (feature, index) =>
          feature && (
            <Text key={index} tone="subdued" as="p" variant="bodyMd">
              {feature}
            </Text>
          )
      )}
    </BlockStack>
  )
}
