import { createFileRoute } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { emailTemplatesQueryOptions } from '@/common/queryOptions'
import { EmailForm } from '@/components/emailTemplates/EmailForm'
import { BlockStack, Box, Card, Grid, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { SectionTitle } from '@/components/shared/SectionTitle'

export const Route = createFileRoute('/emails')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(emailTemplatesQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()

  return (
    <PageFrame title={t('emailTemplatesTitle')} subtitle={t('emailTemplatesSubtitle')}>
      <EmailForm />
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('emailTemplatesTitle')}>
      <Layout>
        <Layout.Section>
          <SectionTitle title={t('emailTemplatesPreorderDelayedTitle')} description={t('emailTemplatesPreorderDelayedDescription')} />
          <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={10} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <Box borderColor="border-secondary" borderWidth="025">
                  <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                    <SkeletonBodyText lines={3} />
                  </Box>
                  <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                    <SkeletonBodyText lines={1} />
                  </Box>
                  <Box padding="400">
                    <Box minHeight="400px" background="bg-fill-tertiary" />
                  </Box>
                </Box>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('emailTemplatesBackInStockTitle')} description={t('emailTemplatesBackInStockDescription')} />
          <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={10} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <Box borderColor="border-secondary" borderWidth="025">
                  <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                    <SkeletonBodyText lines={3} />
                  </Box>
                  <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                    <SkeletonBodyText lines={1} />
                  </Box>
                  <Box padding="400">
                    <Box minHeight="400px" background="bg-fill-tertiary" />
                  </Box>
                </Box>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
