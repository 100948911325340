import { Box, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import { useFormContext } from 'react-hook-form'
import { useSuspenseQuery } from '@tanstack/react-query'
import { localisationQueryOptions } from '@/common/queryOptions'

const CollectionWidgetPreview = () => {
  const { watch } = useFormContext()

  const { data: localisation } = useSuspenseQuery(localisationQueryOptions())

  const showPreorderCollectionBadge = watch('showPreorderCollectionBadge')
  const showBackorderCollectionBadge = watch('showBackorderCollectionBadge')
  const purchaseOptionBadgeTextColor = watch('purchaseOptionBadgeTextColor')
  const purchaseOptionBadgeBackgroundColor = watch('purchaseOptionBadgeBackgroundColor')

  return (
    <div
      style={{
        fontSize: '14px',
      }}
    >
      <Box>
        <div className="collectionImageSkeleton">
          {showPreorderCollectionBadge || showBackorderCollectionBadge ? (
            <div
              className="badgePreview"
              style={{
                color: purchaseOptionBadgeTextColor || '#ffffff',
                backgroundColor: purchaseOptionBadgeBackgroundColor || '#000000',
              }}
            >
              {showPreorderCollectionBadge
                ? localisation?.preorderLabel || 'Pre-order'
                : showBackorderCollectionBadge
                  ? localisation?.backorderLabel || 'Back-order'
                  : null}
            </div>
          ) : null}
        </div>
        <Box minHeight="15px" />
        <Box maxWidth="250px">
          <SkeletonBodyText lines={1} />
        </Box>
        <Box minHeight="10px" />
        <Box>
          <SkeletonDisplayText size="small" />
        </Box>
        <Box minHeight="15px" />
        <Box>
          <SkeletonDisplayText size="extraLarge" maxWidth="100%" />
        </Box>
      </Box>
    </div>
  )
}

export { CollectionWidgetPreview }
