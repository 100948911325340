import { useMutateSettings } from '@/hooks/useMutateSettings'
import { BlockStack, Box, Divider, Icon, InlineStack, Layout, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQueries } from '@tanstack/react-query'
import { DismissableCard } from '@/components/shared/DismissableCard'
import { SectionTitle } from '@/components/shared/SectionTitle'

/* https://undraw.co/search */
import DesignerIllustration from '@/illustrations/designer.svg?react'
import InvestingIllustration from '@/illustrations/finance.svg?react'
import TermsIllustration from '@/illustrations/terms.svg?react'
import ReviewIllustration from '@/illustrations/balloon.svg?react'
import GlobeIllustration from '@/illustrations/globe.svg?react'
import UpgradeIllustration from '@/illustrations/upgrade.svg?react'
import CancelIllustration from '@/illustrations/cancel.svg?react'
import PreorderIllustration from '@/illustrations/preorder.svg?react'
import BackInStockIllustration from '@/illustrations/back-in-stock.svg?react'
import {
  reportsBisQueryOptions,
  reportsBackordersQueryOptions,
  campaignReportsOrdersQueryOptions,
  campaignQueryOptions,
  checkoutProfilesQueryOptions,
  settingsQueryOptions,
} from '@/common/queryOptions'
import { useNavigate } from '@tanstack/react-router'
import { CheckCircleIcon } from '@shopify/polaris-icons'

const ThingsToDo = () => {
  const [i18n] = useI18n()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [
    { data: campaignReport },
    { data: backorderReport },
    { data: backInStockReport },
    {
      data: { data: campaigns },
    },
    { data: settings },
    { data: checkoutProfileId, isError: isCheckoutProfileError },
  ] = useSuspenseQueries({
    queries: [
      campaignReportsOrdersQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBackordersQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBisQueryOptions({
        from: undefined,
        to: undefined,
      }),
      campaignQueryOptions(),
      settingsQueryOptions(),
      checkoutProfilesQueryOptions(),
    ],
  })

  const settingsMutation = useMutateSettings()

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutate({ dismissFlags })
  }

  let mainCurrencyOrders = 0
  let mainCurrencyCampaignRevenue = 0
  let mainCurrencyBackorderRevenue = 0
  let mainCurrencyBackInStockRevenue = 0

  if (campaignReport && campaignReport.length) {
    campaignReport.forEach((group) => {
      if (group.currency === settings.currency) {
        mainCurrencyOrders += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
        mainCurrencyCampaignRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })
  }

  if (backorderReport && backorderReport.length) {
    backorderReport.forEach((group) => {
      if (group.currency === settings.currency) {
        mainCurrencyOrders += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
        mainCurrencyBackorderRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })
  }

  if (backInStockReport && backInStockReport.length) {
    backInStockReport.forEach((group) => {
      if (group.currency === settings.currency) {
        mainCurrencyOrders += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
        mainCurrencyBackInStockRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })
  }

  const totalRevenue = mainCurrencyBackorderRevenue + mainCurrencyCampaignRevenue + mainCurrencyBackInStockRevenue
  const totalRevenueFormatted = i18n.formatCurrency(totalRevenue, {
    currency: settings?.currency ?? 'USD',
  })

  const dismissFlags = settings?.dismissFlags

  return (
    <Layout.Section>
      <SectionTitle title={t('thingsToDoTitle')} />
      <BlockStack gap="400">
        {!dismissFlags?.leaveReview && settings?.currency && totalRevenue > 100 && mainCurrencyOrders >= 3 && (
          <DismissableCard
            className="holographicCard"
            title={t('thingsToDoLeaveReviewTitle')}
            description={t('thingsToDoLeaveReviewDescriptionWithRevenue', {
              revenue: totalRevenueFormatted,
            })}
            illustration={<ReviewIllustration />}
            primaryCta={{
              label: t('thingsToDoLeaveReviewPrimaryCta'),
              props: {
                url: 'https://apps.shopify.com/early-bird-pre-order-manager#modal-show=WriteReviewModal',
                external: true,
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, leaveReview: true })}
          />
        )}
        {!dismissFlags?.createCampaign && !campaigns.length && (
          <DismissableCard
            title={t('thingsToDoCreateCampaignTitle')}
            description={t('thingsToDoCreateCampaignDescription')}
            illustration={<PreorderIllustration />}
            primaryCta={{
              label: t('thingsToDoCreateCampaignPrimaryCta'),
              props: {
                onClick: () => {
                  navigate({ to: '/preorders/create' })
                },
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, createCampaign: true })}
          />
        )}
        {!dismissFlags?.activateBackorders && !settings?.backorderEnabled && (
          <DismissableCard
            title={t('thingsToDoActivateBackordersTitle')}
            description={t('thingsToDoActivateBackordersDescription')}
            illustration={<InvestingIllustration />}
            primaryCta={{
              label: t('thingsToDoActivateBackordersPrimaryCta'),
              props: {
                onClick: () => {
                  navigate({ to: '/backorders/settings' })
                },
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, activateBackorders: true })}
          />
        )}
        {!dismissFlags?.activateBis && !settings?.bisEnabled && (
          <DismissableCard
            title={t('thingsToDoActivateBackInStockTitle')}
            description={t('thingsToDoActivateBackInStockDescription')}
            illustration={<BackInStockIllustration />}
            primaryCta={{
              label: t('thingsToDoActivateBackInStockPrimaryCta'),
              props: {
                onClick: () => {
                  navigate({ to: '/back-in-stock/settings' })
                },
              },
            }}
            /*secondaryCta={{
              label: t('genericLearnMore'),
              props: {
                url: `https://docs.shopside.com.au`,
                external: true,
              },
            }}*/
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, activateBackorders: true })}
          />
        )}
        {!dismissFlags?.customizeDisplay && (
          <DismissableCard
            title={t('thingsToDoCustomizeDisplayTitle')}
            description={t('thingsToDoCustomizeDisplayDescription')}
            illustration={<DesignerIllustration />}
            primaryCta={{
              label: t('thingsToDoCustomizeDisplayPrimaryCta'),
              props: {
                onClick: () => {
                  navigate({ to: '/display' })
                },
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, customizeDisplay: true })}
          />
        )}
        {!dismissFlags?.localisation && (
          <DismissableCard
            title={t('thingsToDoLocalisationTitle')}
            description={t('thingsToDoLocalisationDescription')}
            illustration={<GlobeIllustration />}
            primaryCta={{
              label: t('thingsToDoLocalisationPrimaryCta'),
              props: {
                onClick: () => {
                  navigate({ to: '/localization' })
                },
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, localisation: true })}
          />
        )}
        {!isCheckoutProfileError && !dismissFlags?.cancelExtension && (
          <DismissableCard
            title={t('thingsToDoCancelExtensionTitle')}
            description={t('thingsToDoCancelExtensionDescription')}
            illustration={<CancelIllustration />}
            primaryCta={{
              label: t('thingsToDoCancelExtensionPrimaryCta'),
              props: {
                url: `https://admin.shopify.com/store/${shopify.config.shop.split('.')[0]}/settings/checkout/editor/profiles/${checkoutProfileId}?page=customer-account-order-status&extensionConfiguration=${import.meta.env.VITE_APP_CANCEL_EXTENSION_ID}&extensionPicker=true`,
                external: true,
              },
            }}
            secondaryCta={{
              label: t('thingsToDoCancelExtensionSecondaryCta'),
              props: {
                url: `https://www.shopside.com.au/earlybird-article/customer-accounts-manage-order-extension`,
                external: true,
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, cancelExtension: true })}
          />
        )}
        {!dismissFlags?.reviewRules && (
          <DismissableCard
            title={t('thingsToDoReviewRulesTitle')}
            description={t('thingsToDoReviewRulesDescription')}
            illustration={<TermsIllustration />}
            primaryCta={{
              label: t('thingsToDoReviewRulesPrimaryCta'),
              props: {
                url: 'https://help.shopify.com/en/manual/products/purchase-options/pre-orders/setup',
                external: true,
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, reviewRules: true })}
          />
        )}
        {!dismissFlags?.upgradePlan && totalRevenue > 0 && (
          <DismissableCard
            title={t('thingsToDoUpgradePlanTitle')}
            description={t('thingsToDoUpgradePlanDescription')}
            illustration={<UpgradeIllustration />}
            primaryCta={{
              label: t('thingsToDoUpgradePlanPrimaryCta'),
              props: {
                onClick: () => {
                  navigate({ to: '/plans' })
                },
              },
            }}
            isDismissDisabled={settingsMutation.isPending}
            onDismiss={() => onDismiss({ ...dismissFlags, upgradePlan: true })}
          />
        )}
        <InlineStack align="center" blockAlign="center">
          <Box width="100%" paddingBlock="300">
            <Divider borderColor="border-tertiary" borderWidth="025" />
          </Box>
          <Box background="bg" paddingInlineStart="400" paddingInlineEnd="400" position="absolute">
            <InlineStack wrap={false} gap="200">
              <Box>
                <Icon source={CheckCircleIcon} tone="subdued" />
              </Box>
              <Text variant="headingSm" as="h2" tone="subdued" breakWord={false}>
                {t('thingsToDoFooter')}
              </Text>
            </InlineStack>
          </Box>
        </InlineStack>
      </BlockStack>
    </Layout.Section>
  )
}

export { ThingsToDo }
