import { Banner, Box, Button, InlineStack, Layout, Text } from '@shopify/polaris'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { settingsCheckAppEmbedQueryOptions } from '@/common/queryOptions'

// Deep link to add the embed block in the theme customizer
const appEmbedLink = `https://admin.shopify.com/store/${shopify.config.shop.split('.')[0]}/themes/current/editor?context=apps&activateAppId=${
  import.meta.env.VITE_APP_EMBED_ID
}/app-embed`

const AppEmbedBlock = () => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [isPolling, setIsPolling] = useState(false)

  const { data: hasEmbedBlock } = useSuspenseQuery({
    ...settingsCheckAppEmbedQueryOptions(),
    refetchInterval: isPolling ? 3000 : false,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    if (hasEmbedBlock) {
      setIsPolling(false)
    } else {
      setIsVisible(true)
    }
  }, [hasEmbedBlock])

  if (!isVisible) return null

  let content: React.ReactNode | null = null

  if (hasEmbedBlock) {
    content = (
      <Banner
        title={t('embedBlockBannerTitle')}
        tone="success"
        action={{
          content: t('genericDismiss'),
          onAction: () => {
            setIsVisible(false)
          },
        }}
      >
        <p>{t('embedBlockBannerSuccessDescription')}</p>
      </Banner>
    )
  } else {
    content = (
      <Banner title={t('embedBlockBannerTitle')} tone="warning">
        <p>{t('embedBlockBannerDescription')}</p>
        <Box paddingBlockStart={'300'}>
          <InlineStack gap="300" blockAlign="center">
            <Button
              onClick={() => {
                setIsPolling(true)
                open(appEmbedLink, '_blank')
              }}
            >
              {t('embedBlockBannerAction')}
            </Button>
            {isPolling && (
              <Text as="span" variant="bodyMd">
                {t('embedBlockBannerSecondaryActionLoading')}
              </Text>
            )}
          </InlineStack>
        </Box>
      </Banner>
    )
  }

  return <Layout.Section>{content}</Layout.Section>
}

export { AppEmbedBlock }
