import { useState } from 'react'

import { Icon, Listbox, Popover, TextField } from '@shopify/polaris'
import { ClockIcon } from '@shopify/polaris-icons'
import dayjs from '@/common/datetime'
import { useTranslation } from 'react-i18next'

type TimeOption = {
  label: string
  value: string
}

type TimeSelectFieldProps = {
  disabled?: boolean
  label: string
  date: string | null
  displayTimezone?: string
  onChange: (value: string) => void
}

// Function to generate time options
function generateTimeOptions(): TimeOption[] {
  const options: TimeOption[] = []
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const formattedHours24 = hours.toString().padStart(2, '0')
      const formattedMinutes = minutes.toString().padStart(2, '0')
      const period = hours < 12 ? 'am' : 'pm'

      const formattedHours12 = (hours % 12 || 12).toString()

      const timeLabel = `${formattedHours12}:${formattedMinutes} ${period}`
      const timeValue = `${formattedHours24}:${formattedMinutes}`

      options.push({ label: timeLabel, value: timeValue })
    }
  }
  return options
}

const TimeSelectField = ({ disabled, label, date: initialDate, displayTimezone = 'UTC', onChange }: TimeSelectFieldProps) => {
  const { t } = useTranslation()
  const [isPopoverVisible, setPopoverVisible] = useState(false)

  const date = initialDate ?? dayjs().tz(displayTimezone).startOf('day').toISOString()
  const dateAtStore = dayjs(date).tz(displayTimezone)

  // Take the server time and convert it to the local time
  const localTimeValue = dateAtStore.format('HH:mm')

  // Generate time options in 30-minute increments
  const timeOptions: TimeOption[] = generateTimeOptions()

  // Handler for select field change
  const handleTimeChange = (value: string) => {
    if (!dateAtStore) return

    const [hour, minute] = value.split(':').map(Number)
    const updatedDate = dayjs(dateAtStore).set('hour', hour).set('minute', minute).set('second', 0)

    onChange(updatedDate.tz('UTC').toISOString())
    setPopoverVisible(false)
  }

  return (
    <Popover
      active={isPopoverVisible}
      activator={
        <TextField
          disabled={disabled}
          role="combobox"
          label={label}
          prefix={<Icon source={ClockIcon} />}
          value={initialDate !== '' ? timeOptions.find((option) => option.value === localTimeValue)?.label : t('genericSelectTime')}
          onFocus={() => setPopoverVisible(true)}
          autoComplete="off"
        />
      }
      autofocusTarget="none"
      preferredAlignment="left"
      fullWidth
      preferInputActivator={false}
      preferredPosition="below"
      preventCloseOnChildOverlayClick
      onClose={() => setPopoverVisible(false)}
    >
      <Listbox accessibilityLabel={t('genericSelectTime')} onSelect={handleTimeChange}>
        {timeOptions.map((option) => (
          <Listbox.Option key={option.value} value={option.value} selected={localTimeValue === option.value}>
            {option.label}
          </Listbox.Option>
        ))}
      </Listbox>
    </Popover>
  )
}

export { TimeSelectField }
