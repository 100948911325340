import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const LocalizationFallback = () => {
  const { t } = useTranslation()
  return (
    <SkeletonPage title={t('localisationTitle')}>
      <Layout>
        <Layout.AnnotatedSection title={t('localisationDateTitle')} description={t('localisationDateDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('localisationLabelsTitle')} description={t('localisationLabelsDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}

export { LocalizationFallback }
