import { Box, TextField, Text, BlockStack, InlineStack, Button, Collapsible, Spinner } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader'
import { useMutateVerifyDns } from '@/hooks/useMutateVerifyDns'
import { EmailStatusBadge } from '@/components/shared/EmailStatusBadge'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { dnsQueryOptions } from '@/common/queryOptions'

const DNSSettings = ({ emailDomainStatus }: { emailDomainStatus: string | null }) => {
  const { t } = useTranslation()
  const { data: dns, isFetching, refetch } = useQuery(dnsQueryOptions())

  const mutation = useMutateVerifyDns()
  const [open, setOpen] = useState(emailDomainStatus !== 'verified')

  const { records } = dns ?? {}

  const isVerifyButtonHidden = emailDomainStatus === 'verified'
  const isShowingVerifyButton = emailDomainStatus === 'not_started' || emailDomainStatus === 'temporary_failure' || emailDomainStatus === 'failed'

  return (
    <Box background="bg-surface-secondary" padding="300" borderRadius="200">
      <CardHeader
        title={t('settingsEmailSenderDnsRecordsTitle')}
        description={t('settingsEmailSenderDnsRecordsSubtitle')}
        actions={
          <Button
            variant="plain"
            onClick={() => {
              setOpen(!open)
            }}
            accessibilityLabel={open ? t('genericHide') : t('genericShow')}
          >
            {open ? t('genericHide') : t('genericShow')}
          </Button>
        }
      />

      <Collapsible open={open} id="dns-collapsible" transition={{ duration: '500ms', timingFunction: 'ease-in-out' }} expandOnPrint>
        <Box paddingBlockStart="300">
          <BlockStack gap="300">
            <InlineStack gap="400" wrap={false} align="space-between">
              <Box width="10%">
                <Text as="span" variant="bodyMd" fontWeight="semibold">
                  {t('settingsEmailSenderDnsType')}
                </Text>
              </Box>
              <Box width="25%">
                <Text as="span" variant="bodyMd" fontWeight="semibold">
                  {t('settingsEmailSenderDnsName')}
                </Text>
              </Box>
              <Box width="45%">
                <Text as="span" variant="bodyMd" fontWeight="semibold">
                  {t('settingsEmailSenderDnsValue')}
                </Text>
              </Box>
              <Box width="20%">
                <Text as="span" variant="bodyMd" fontWeight="semibold">
                  {t('settingsEmailSenderDnsStatus')}
                </Text>
              </Box>
            </InlineStack>
            {!records?.length && isFetching && (
              <InlineStack gap="200">
                <Spinner size="small" />
                <Text as="span" variant="bodyMd">
                  {t('genericLoading')}
                </Text>
              </InlineStack>
            )}
            {records?.map((record) => {
              return (
                <InlineStack key={record.name} gap="400" wrap={false} align="space-between" blockAlign="center">
                  <Box width="10%">
                    <Text as="span" variant="bodyMd">
                      {record.type}
                    </Text>
                  </Box>
                  <Box width="25%">
                    <TextField
                      label={t('settingsEmailSenderDnsName')}
                      labelHidden
                      autoComplete="off"
                      value={record.name ?? ''}
                      size="slim"
                      readOnly
                      selectTextOnFocus
                    />
                  </Box>
                  <Box width="45%">
                    <TextField
                      label={t('settingsEmailSenderDnsName')}
                      labelHidden
                      autoComplete="off"
                      value={record.value ?? ''}
                      size="slim"
                      readOnly
                      selectTextOnFocus
                    />
                  </Box>
                  <Box width="20%">
                    <EmailStatusBadge status={record.status} size="small" />
                  </Box>
                </InlineStack>
              )
            })}
            {!isVerifyButtonHidden && (
              <InlineStack align="end">
                {isShowingVerifyButton ? (
                  <Button tone="success" onClick={() => mutation.mutate()} loading={mutation.isPending}>
                    {t('settingsEmailSenderDnsVerifyCta')}
                  </Button>
                ) : (
                  <Button tone="success" onClick={() => refetch()} loading={isFetching}>
                    {t('settingsEmailSenderDnsRefreshCta')}
                  </Button>
                )}
              </InlineStack>
            )}
          </BlockStack>
        </Box>
      </Collapsible>
    </Box>
  )
}

export { DNSSettings }
