import { bisRestocksQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Card, IndexTable, Text, EmptySearchResult } from '@shopify/polaris'
import { useSuspenseQueries } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState, useTransition, useRef } from 'react'
import { formatNumber } from '@/common/functions'
import { formatDateForShopify } from '@/common/datetime'
import { RestockStatusBadge } from '../shared/RestockStatusBadge'

const FAST_REFRESH_INTERVAL = 3000
const SLOW_REFRESH_INTERVAL = 30000

function BackInStockRestocksTable({
  productId,
  isAwaitingRestock,
  setIsAwaitingRestock,
}: {
  productId: string
  isAwaitingRestock: boolean
  setIsAwaitingRestock: (isAwaitingRestock: boolean) => void
}) {
  const { t } = useTranslation()
  const [isPending, startTransition] = useTransition()

  const [filterPage, setFilterPage] = useState(1)
  const [refetchInterval, setRefetchInterval] = useState(SLOW_REFRESH_INTERVAL)
  const firstRestockUuidRef = useRef<string | null>(null)

  const [
    { data: settings },
    {
      data: { data: restocks, total, hasNext },
      isRefetching,
      isError,
    },
  ] = useSuspenseQueries({
    queries: [
      settingsQueryOptions(),
      {
        ...bisRestocksQueryOptions({
          id: productId,
          page: filterPage,
          limit: 5,
        }),
        refetchInterval: refetchInterval,
        refetchIntervalInBackground: true,
      },
    ],
  })

  const rows: TableRowProps[] = restocks.map((restock, index) => ({
    id: restock.uuid,
    createdAt: formatDateForShopify(restock.createdAt, settings.timezone),
    completedAt: formatDateForShopify(restock.completedAt, settings.timezone),
    nextBatchScheduledAt: formatDateForShopify(restock.nextBatchScheduledAt, settings.timezone),
    status: restock.status,
    errorCode: restock.errorCode,
    isAutomatic: restock.isAutomaticSend,
    currentSendCount: restock.currentSendCount,
    index: index,
  }))

  const changePage = useCallback((page: number) => {
    startTransition(() => {
      setFilterPage(page)
    })
  }, [])

  useEffect(() => {
    if (isAwaitingRestock) {
      // Use fast refetch interval when awaiting restock, even if restocks is empty
      setRefetchInterval(FAST_REFRESH_INTERVAL)

      if (restocks.length > 0) {
        const currentFirstRestockUuid = restocks[0].uuid

        if (firstRestockUuidRef.current === null || currentFirstRestockUuid !== firstRestockUuidRef.current) {
          // A new restock has arrived
          setIsAwaitingRestock(false)
          setRefetchInterval(SLOW_REFRESH_INTERVAL)
          firstRestockUuidRef.current = currentFirstRestockUuid
        }
      } else {
        // If restocks is empty, keep firstRestockUuidRef as null
        firstRestockUuidRef.current = null
      }
    } else {
      // Not awaiting restock, use slow refetch interval
      setRefetchInterval(SLOW_REFRESH_INTERVAL)

      if (restocks.length > 0) {
        // Update the stored UUID even when not awaiting restock
        firstRestockUuidRef.current = restocks[0].uuid
      } else {
        firstRestockUuidRef.current = null
      }
    }
  }, [restocks, isAwaitingRestock, setIsAwaitingRestock])

  useEffect(() => {
    if (isError) {
      setRefetchInterval(SLOW_REFRESH_INTERVAL)
    }
  }, [isError])

  return (
    <Card padding="0">
      <IndexTable
        selectable={false}
        loading={!isError && (isPending || isRefetching || isAwaitingRestock)}
        resourceName={{ singular: t('backInStockRestockTableResourceName'), plural: t('backInStockRestockTableResourceNamePlural') }}
        itemCount={total}
        headings={[
          { title: t('backInStockRestockTableFieldIsAutomatic') },
          { title: t('backInStockRestockTableFieldStatus') },
          { title: t('backInStockRestockTableFieldCreatedAt') },
          { title: t('backInStockRestockTableFieldCompletedAt') },
          { title: t('backInStockRestockTableFieldSendCount') },
        ]}
        hasMoreItems={hasNext}
        emptyState={
          <EmptySearchResult title={t('backInStockRestockTableEmptyTitle')} description={t('backInStockRestockTableEmptyDescription')} withIllustration />
        }
        pagination={{
          hasNext,
          onNext: () => changePage(filterPage + 1),
          hasPrevious: filterPage > 1,
          onPrevious: () => changePage(filterPage - 1),
        }}
      >
        {rows.map((row) => (
          <TableRow key={row.id} {...row} />
        ))}
      </IndexTable>
    </Card>
  )
}

interface TableRowProps {
  id: string
  createdAt: string
  completedAt: string
  nextBatchScheduledAt: string | null
  status: 'ERROR' | 'COMPLETE' | 'SENDING' | 'SCANNING' | 'WAITING_NEXT_BATCH' | null
  errorCode: string | null
  isAutomatic: boolean
  currentSendCount: number
  index: number
}

const TableRow = ({ id, createdAt, completedAt, nextBatchScheduledAt, status, errorCode, isAutomatic, currentSendCount, index }: TableRowProps) => {
  const { t } = useTranslation()

  return (
    <IndexTable.Row id={id} key={id} position={index}>
      <IndexTable.Cell>
        <Text as="span">{isAutomatic ? t('backInStockRestockTableFieldTriggerAutomatic') : t('backInStockRestockTableFieldTriggerManual')}</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span">
          <RestockStatusBadge status={status} errorCode={errorCode} isAutomaticSend={isAutomatic} nextBatchScheduledAt={nextBatchScheduledAt} />
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span">{createdAt}</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span">{completedAt}</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" numeric>
          {formatNumber(currentSendCount)}
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>
  )
}

export { BackInStockRestocksTable }
