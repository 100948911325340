import { Box, BlockStack, Card, Grid, Layout } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { CollectionWidgetSettings } from './CollectionWidgetSettings'
import { CollectionWidgetPreview } from './CollectionWidgetPreview'
import { SectionTitle } from '@/components/shared/SectionTitle'

const CollectionWidget = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const { t } = useTranslation()

  return (
    <Layout.Section>
      <Box paddingBlockStart="200" paddingBlockEnd="400" paddingInline="0">
        <SectionTitle title={t('displayCollectionPageTitle')} description={t('displayCollectionPageDescription')} />
        <BlockStack gap="400">
          <Grid columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <Disabled isDisabled={isDisabled}>
                  <CollectionWidgetSettings />
                </Disabled>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <CollectionWidgetPreview />
              </Card>
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </Box>
    </Layout.Section>
  )
}

export { CollectionWidget }
