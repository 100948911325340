import { Layout, Page, PageProps } from '@shopify/polaris'

// Extend PageProps to include the loading prop
interface PageFrameProps extends PageProps {
  // isLoading?: boolean
  // skeleton?: React.ReactNode
}

const PageFrame = ({ title, children, ...props }: PageFrameProps) => {
  // if (isLoading) {
  //   return (
  //     <SkeletonPage primaryAction={!!props.primaryAction}>
  //       <Layout>{skeleton}</Layout>
  //     </SkeletonPage>
  //   )
  // }

  return (
    <Page title={title} {...props}>
      <Layout>{children}</Layout>
    </Page>
  )
}

export { PageFrame }
