import { Box, Divider, InlineStack, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { formatPrice, replaceInTemplate } from '@/common/functions'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { localisationQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import dayjs from '@/common/datetime'
import { useState } from 'react'

const PreorderWidgetPreview = () => {
  const { t } = useTranslation()
  const [previewSellingPlan, setPreviewSellingPlan] = useState<'full' | 'partial'>('partial')

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { data: localisation } = useSuspenseQuery(localisationQueryOptions())

  const { watch } = useFormContext()

  const addToCartButtonText = watch('addToCartButtonText')
  const addToCartButtonTextColor = watch('addToCartButtonTextColor')
  const addToCartButtonBackgroundColor = watch('addToCartButtonBackgroundColor')
  const sellingPlanBadgeBackgroundColor = watch('sellingPlanBadgeBackgroundColor')
  const sellingPlanBadgeTextColor = watch('sellingPlanBadgeTextColor')
  const partialPaymentNote = watch('partialPaymentNote')
  const fulfilmentDateNote = watch('fulfilmentDateNote')

  const parsedPaymentDate = replaceInTemplate(partialPaymentNote ?? '', [
    {
      find: '{{FINAL_PAYMENT_DATE}}',
      replace: dayjs()
        .tz(settings.timezone)
        .locale(localisation.dateLocale)
        .add(7, 'day')
        .format(localisation?.dateFormat ?? 'YYYY-MM-DD'),
    },
  ])

  const parsedFulfillmentDate = replaceInTemplate(fulfilmentDateNote ?? '', [
    {
      find: '{{FULFILLMENT_DATE}}',
      replace: dayjs()
        .tz(settings.timezone)
        .locale(localisation.dateLocale)
        .add(14, 'day')
        .format(localisation?.dateFormat ?? 'YYYY-MM-DD'),
    },
  ])

  return (
    <>
      <Box borderWidth="025" borderColor="border-secondary" padding="0">
        <Box padding="400" paddingInlineStart="300" paddingInlineEnd="300">
          <InlineStack gap="200" blockAlign="center">
            <input
              type="radio"
              id="fullPlan"
              name="selling_plan"
              value={'full'}
              checked={previewSellingPlan === 'full'}
              onChange={() => setPreviewSellingPlan('full')}
            />
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <InlineStack align="space-between">
                <label htmlFor="fullPlan">{t('displayPreviewSellingPlanFullExample')}</label>
                <InlineStack gap="200">
                  <div
                    style={{
                      background: sellingPlanBadgeBackgroundColor ?? '#000000',
                      color: sellingPlanBadgeTextColor ?? '#ffffff',
                      borderRadius: '999px',
                      padding: '0.1rem 0.5rem',
                    }}
                  >
                    <Text as="span" variant="bodySm">
                      {localisation.discountPrefix || 'Save'} {formatPrice(15, settings.currency)}
                    </Text>
                  </div>
                  <Text as="span">{formatPrice(84, settings.currency)}</Text>
                </InlineStack>
              </InlineStack>
            </div>
          </InlineStack>
        </Box>
        <Divider />
        <Box padding="400" paddingInlineStart="300" paddingInlineEnd="300">
          <InlineStack gap="200" blockAlign="center">
            <input
              type="radio"
              checked={previewSellingPlan === 'partial'}
              id="partialPlan"
              name="selling_plan"
              value={'partial'}
              onChange={() => setPreviewSellingPlan('partial')}
            />
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <InlineStack align="space-between">
                <label htmlFor="partialPlan">{t('displayPreviewSellingPlanPartialExample')}</label>
                <InlineStack gap="200">
                  <Text as="span">{formatPrice(99, settings.currency)}</Text>
                </InlineStack>
              </InlineStack>
            </div>
          </InlineStack>
        </Box>
      </Box>
      {partialPaymentNote && previewSellingPlan === 'partial' && (
        <>
          <Box minHeight="20px" />
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <Text as="p">{parsedPaymentDate}</Text>
          </div>
        </>
      )}
      {fulfilmentDateNote && (
        <>
          <Box minHeight="10px" />
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <Text as="p">{parsedFulfillmentDate}</Text>
          </div>
        </>
      )}
      <Box minHeight="20px" />
      <div
        style={{
          cursor: 'pointer',
          padding: '0.8rem',
          border: addToCartButtonBackgroundColor ? undefined : '1px solid #000',
          backgroundColor: addToCartButtonBackgroundColor ?? '#ffffff',
          color: addToCartButtonTextColor ?? '#000000',
        }}
      >
        <Text as="span" variant="bodyLg" alignment="center">
          {addToCartButtonText ? addToCartButtonText : 'Add pre-order to cart'}
        </Text>
      </div>
    </>
  )
}

export { PreorderWidgetPreview }
