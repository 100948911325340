import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Button, Layout, SkeletonPage } from '@shopify/polaris'

import {
  reportsBisProductsQueryOptions,
  reportsBisListQueryOptions,
  reportsBisQueryOptions,
  settingsQueryOptions,
  shopifyGetProductsQueryOptions,
} from '@/common/queryOptions'

import { PageFrame } from '@/components/shared/PageFrame'
import { SkeletonChart } from '@/components/shared/SkeletonChart'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { BackInStockStatusBadge } from '@/components/shared/BackInStockStatusBadge'
import { BackInStockChart } from '@/components/backInStock/BackInStockChart'
import { BackInStockProductsTable } from '@/components/backInStock/BackInStockProductsTable'

import { useMutateSettings } from '@/hooks/useMutateSettings'
import { AppEmbedBlock } from '@/components/dashboard/AppEmbedBlock'

export const Route = createFileRoute('/back-in-stock/')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      // Start all queries immediately
      const reportsBisProductsPromise = queryClient.ensureQueryData(
        reportsBisProductsQueryOptions({
          page: 1,
        })
      )

      Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(
          reportsBisListQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
        queryClient.ensureQueryData(
          reportsBisQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
      ])

      // Wait only for the settings to resolve
      const bisProducts = await reportsBisProductsPromise
      const bisProductIds = bisProducts.data.map((product) => product.productId)

      // Start the dependent query
      queryClient.ensureQueryData(shopifyGetProductsQueryOptions({ productIds: bisProductIds }))
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const mutateSettings = useMutateSettings()

  return (
    <PageFrame
      title={t('backInStockDashTitle')}
      subtitle={t('backInStockDashTitleSubtitle')}
      titleMetadata={<BackInStockStatusBadge enabled={settings.bisEnabled} />}
      primaryAction={
        <Button
          variant="primary"
          disabled={mutateSettings.isPending}
          loading={mutateSettings.isPending}
          onClick={async () => {
            mutateSettings.mutateAsync({
              bisEnabled: !settings.bisEnabled,
            })
          }}
        >
          {settings.bisEnabled ? t('backInStockCtaUnpublish') : t('backInStockCtaPublish')}
        </Button>
      }
      secondaryActions={[
        {
          content: t('genericConfigure'),
          onAction: () => {
            navigate({ to: `/back-in-stock/settings` })
          },
        },
      ]}
    >
      {settings.bisEnabled && <AppEmbedBlock />}
      <Layout.Section>
        <BackInStockChart />
      </Layout.Section>
      <Layout.Section>
        <BackInStockProductsTable />
      </Layout.Section>
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('backInStockDashTitle')} primaryAction>
      <Layout>
        <Layout.Section>
          <SkeletonChart />
        </Layout.Section>
        <Layout.Section>
          <SkeletonTable />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
