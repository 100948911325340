import { FormLayout, TextField } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

const BisModalWidgetSettingsSuccess = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <FormLayout>
      <Controller
        name="bisFormSuccessMessage"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisFormSuccessMessageLabel')}
            placeholder={t('displayBisFormSuccessMessagePlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error?.message}
          />
        )}
      />
    </FormLayout>
  )
}

export { BisModalWidgetSettingsSuccess }
