import { MetricBar } from './MetricBar'
import { Trans, useTranslation } from 'react-i18next'
import { useRouterState, useNavigate } from '@tanstack/react-router'
import { useSuspenseQueries } from '@tanstack/react-query'
import { settingsQueryOptions, reportsUsageQueryOptions } from '@/common/queryOptions'
import { Banner, BlockStack, Card, Layout } from '@shopify/polaris'
import { buildAdminUrl } from '@/common/functions.ts'
import { SectionTitle } from './SectionTitle'
import dayjs from '@/common/datetime'

const UsageStats = () => {
  const { t } = useTranslation()

  const [{ data: usageData }, { data: settingsData }] = useSuspenseQueries({
    queries: [reportsUsageQueryOptions(), settingsQueryOptions()],
  })

  const router = useRouterState()
  const navigate = useNavigate()

  const isShowingApproaching = settingsData.usagePreorderLimitStatus === 'APPROACHING' || settingsData.usageEmailLimitStatus === 'APPROACHING'
  const isShowingGrace = settingsData.usagePreorderLimitStatus === 'GRACE' || settingsData.usageEmailLimitStatus === 'GRACE'
  const isShowingExceeded = settingsData.usagePreorderLimitStatus === 'EXCEEDED' || settingsData.usageEmailLimitStatus === 'EXCEEDED'

  const upgradeLink = (
    <a
      href={buildAdminUrl('plans')}
      onClick={async (e) => {
        e.preventDefault()
        /* @ts-ignore */
        await shopify.saveBar.leaveConfirmation()
        navigate({ to: '/plans' })
      }}
    >
      .
    </a>
  )

  return (
    <Layout.Section>
      <SectionTitle
        title={t('usageStatsTitle')}
        description={t('usageStatsSubtitle', {
          from: dayjs.utc().startOf('month').format('MMMM D'),
          to: dayjs.utc().endOf('month').format('MMMM D'),
        })}
      />
      <Card padding="400">
        <BlockStack gap="400">
          {isShowingApproaching || isShowingExceeded || isShowingGrace ? (
            isShowingExceeded ? (
              <Banner tone="critical" title={t('usageStatsBannerExceededTitle')}>
                <Trans t={t} i18nKey="usageStatsBannerExceededDescription">
                  .{upgradeLink}.
                </Trans>
              </Banner>
            ) : (
              <>
                {isShowingGrace ? (
                  <Banner tone="critical" title={t('usageStatsBannerGraceTitle')}>
                    <Trans t={t} i18nKey="usageStatsBannerGraceDescription">
                      .{upgradeLink}.
                    </Trans>
                  </Banner>
                ) : (
                  <Banner tone="warning" title={t('usageStatsBannerApproachingTitle')}>
                    <Trans t={t} i18nKey="usageStatsBannerApproachingDescription">
                      .{upgradeLink}.
                    </Trans>
                  </Banner>
                )}
              </>
            )
          ) : null}

          <MetricBar
            blocks={[
              <MetricBar.PlanBlock title={t('usageStatsPlanTitle')} currentPlan={usageData.plan.name} showPlanLink={router.location.pathname !== '/plans'} />,
              <MetricBar.ProgressBlock title={t('usageStatsPreordersTitle')} used={usageData?.orders.current} total={usageData?.orders.limit} />,
              <MetricBar.ProgressBlock title={t('usageStatsEmailsTitle')} used={usageData?.emails?.current} total={usageData?.emails?.limit} />,
              /* <MetricBar.ProgressBlock title={t("usageStatsSmsTitle")} used={data?.sms?.current} total={data?.sms?.limit} />, */
            ]}
          />
        </BlockStack>
      </Card>
    </Layout.Section>
  )
}

export { UsageStats }
