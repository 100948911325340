import { useEffect, useState, useCallback } from 'react'
import { ColorPicker as PolarisColorPicker, TextField, Popover, hsbToHex, hexToRgb, rgbToHsb, Box, BlockStack, Button } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

type ColorPickerProps = {
  label: string
  placeholder: string
  error?: string
  defaultColor?: string
  color?: string
  allowInherit?: boolean
  onChange: (val: string) => void
}

const ColorPicker = ({ color, defaultColor = '#ffffff', error, label, placeholder, allowInherit, onChange }: ColorPickerProps) => {
  const { t } = useTranslation()

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [isInheriting, setIsInheriting] = useState(allowInherit && !color)
  const [initialColor] = useState(color || '')
  const [currentColor, setCurrentColor] = useState(color || '')
  const [colorError, setColorError] = useState<string | undefined>(undefined)
  const [isFocused, setIsFocused] = useState(false)

  const validateAndUpdateColor = useCallback(
    (value: string) => {
      const isValidHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)
      if (isValidHex || value === '') {
        onChange(value)
        setColorError(undefined)
        return true
      } else {
        setColorError('Invalid color format')
        return false
      }
    },
    [onChange]
  )

  const handleColorChange = useCallback(
    (value: string) => {
      setCurrentColor(value)
      validateAndUpdateColor(value)
    },
    [validateAndUpdateColor]
  )

  const toggleColorPicker = useCallback(() => {
    if (!isInheriting) {
      setIsColorPickerOpen((prev) => !prev)
      setIsFocused(true)
    }
  }, [isInheriting])

  const toggleInheritMode = useCallback(() => {
    setIsInheriting((prev) => !prev)
    if (isInheriting) {
      // Switching to Edit mode
      handleColorChange(initialColor)
    } else {
      // Switching to Inherit mode
      handleColorChange('')
      setIsColorPickerOpen(false)
    }
  }, [isInheriting, initialColor, handleColorChange])

  useEffect(() => {
    setIsFocused(isColorPickerOpen)
  }, [isColorPickerOpen])

  useEffect(() => {
    if (!isInheriting) {
      setCurrentColor(color || '')
    }
  }, [color, isInheriting])

  const getInheritButton = () => {
    if (!allowInherit) return null
    return (
      <Button variant="plain" onClick={toggleInheritMode}>
        {isInheriting ? t('displayColorPickerButtonEdit') : t('displayColorPickerButtonReset')}
      </Button>
    )
  }

  const getDisplayColor = () => (isInheriting ? defaultColor : currentColor || defaultColor)

  const getColorPreview = () => (
    <div
      onClick={toggleColorPicker}
      className="color-preview"
      style={{
        backgroundColor: getDisplayColor(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: isInheriting ? 0.5 : 1,
        cursor: isInheriting ? 'initial' : 'pointer',
      }}
    />
  )

  return (
    <Popover
      fluidContent
      fullWidth={false}
      preferredAlignment="left"
      preferredPosition="below"
      active={isColorPickerOpen}
      onClose={toggleColorPicker}
      activator={
        <TextField
          autoComplete="off"
          label={label}
          error={error || colorError}
          onChange={handleColorChange}
          onFocus={() => !isInheriting && setIsColorPickerOpen(true)}
          value={isInheriting ? '' : currentColor}
          placeholder={isInheriting ? t('displayColorInheritPlaceholder') : placeholder}
          disabled={isInheriting}
          focused={isFocused}
          selectTextOnFocus
          connectedLeft={getColorPreview()}
          suffix={getInheritButton()}
        />
      }
    >
      <Box padding="200">
        <BlockStack gap="200">
          <PolarisColorPicker
            allowAlpha={false}
            fullWidth={false}
            color={rgbToHsb(hexToRgb(getDisplayColor()))}
            onChange={(newColor) => handleColorChange(hsbToHex(newColor))}
          />
        </BlockStack>
      </Box>
    </Popover>
  )
}

export { ColorPicker }
