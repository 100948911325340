// React, React Query
import { buildAPIUrl } from '@/common/functions'
import { FetchProductResponse } from '@/common/types'
import { keepPreviousData, queryOptions } from '@tanstack/react-query'

const useFetchProduct = ({ productId }: { productId: string }) => {
  // The keys for storing our data in the cache
  const queryKey = `${productId}-info`

  // The URLs for our API endpoints
  const requestURL = buildAPIUrl('graphql')

  const queryFunction = async (): Promise<FetchProductResponse> => {
    return fetch(requestURL, {
      body: JSON.stringify({
        query: `query ($id: ID!) {
            product(id: $id) {
              id
              title
              legacyResourceId
              featuredImage {
                altText
                url
              }
            }
        }`,
        variables: {
          id: productId,
        },
      }),
      method: 'POST',
    })
      .then((res) => res.json())
      .then((res) => res.message.data)
  }

  const queryOpt = queryOptions({
    queryKey: [queryKey],
    queryFn: queryFunction,
    staleTime: 1000 * 60,
    placeholderData: keepPreviousData,
    select: (data) => data,
  })

  return {
    queryFunction,
    queryOptions: queryOpt,
  }
}

export { useFetchProduct }
