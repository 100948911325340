import { Controller, useFormContext } from 'react-hook-form'
import { Box, TextField, Card, InlineGrid, Checkbox, BlockStack, Banner } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader.tsx'
import { DNSSettings } from './DNSSettings.tsx'
import { EmailStatusBadge } from '@/components/shared/EmailStatusBadge.tsx'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled.tsx'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link, useNavigate } from '@tanstack/react-router'
import { settingsQueryOptions } from '@/common/queryOptions.tsx'
import { buildAdminUrl } from '@/common/functions.ts'

const defaultEmail = import.meta.env.DEV ? 'noreply@ebd.shopside.com.au' : 'noreply@ebp.shopside.com.au'

const EmailSettings = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { control, watch } = useFormContext()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const hasCustomEmailDomainFeature = settings.featureCustomEmailDomain === true

  const emailDomainStatus = watch('emailDomainStatus')
  const emailFromName = watch('emailFromName')
  const emailFromEmail = watch('emailFromEmail')
  const hasCustomDomain = !hasCustomEmailDomainFeature ? false : watch('hasCustomDomain')
  const showDnsSettings = hasCustomDomain === true && watch('emailDomainId') && hasCustomEmailDomainFeature
  const verifiedFromEmail = emailDomainStatus === 'verified' && hasCustomEmailDomainFeature && hasCustomDomain && emailFromEmail ? emailFromEmail : defaultEmail
  const [isEmailLocked, setIsEmailLocked] = useState(emailDomainStatus === 'verified' || emailDomainStatus === 'pending')

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader title={t('settingsEmailSenderTitle')} description={t('settingsEmailSenderSubtitle')} />

        <Banner tone="info">
          <p>
            {t('settingsEmailSenderVerifiedBanner', {
              name: emailFromName ?? 'Shopside',
              email: verifiedFromEmail,
            })}
          </p>
        </Banner>
        <InlineGrid columns="2" gap="400">
          <Controller
            name="emailFromName"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('settingsFieldFromName')}
                placeholder="Shopside"
                onChange={onChange}
                onBlur={onBlur}
                maxLength={100}
                value={value ?? ''}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="emailReplyToEmail"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('settingsFieldReplyToAddress')}
                placeholder={defaultEmail}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                error={error?.message}
              />
            )}
          />
        </InlineGrid>
        <Disabled
          isDisabled={!hasCustomEmailDomainFeature}
          message={
            <p>
              <Trans t={t} i18nKey="genericUpgradePlanBanner">
                .
                <Link
                  to={buildAdminUrl('plans')}
                  onClick={async (e) => {
                    e.preventDefault()

                    /* @ts-ignore */
                    await shopify.saveBar.leaveConfirmation()

                    navigate({ to: '/plans' })
                  }}
                >
                  .
                </Link>
                .
              </Trans>
            </p>
          }
        >
          <BlockStack gap="200">
            <Controller
              name="hasCustomDomain"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('settingsFieldCustomDomain')}
                  helpText={t('settingsFieldCustomDomainHelp')}
                  checked={hasCustomEmailDomainFeature && value ? value : false}
                  onChange={onChange}
                />
              )}
            />
            {hasCustomDomain === true && (
              <BlockStack gap="300">
                <Box background="bg-surface-secondary" padding="300" borderRadius="200">
                  <BlockStack gap="300">
                    {!hasCustomEmailDomainFeature ? (
                      <TextField
                        autoComplete="off"
                        label={t('settingsFieldFromAddress')}
                        placeholder={defaultEmail}
                        value={defaultEmail}
                        readOnly={isEmailLocked}
                        labelAction={isEmailLocked ? { content: t('genericChange'), onAction: () => setIsEmailLocked(!isEmailLocked) } : undefined}
                      />
                    ) : (
                      <Controller
                        name="emailFromEmail"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <TextField
                            autoComplete="off"
                            label={t('settingsFieldFromAddress')}
                            placeholder={defaultEmail}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value ?? ''}
                            error={error?.message}
                            readOnly={isEmailLocked}
                            labelAction={isEmailLocked ? { content: t('genericChange'), onAction: () => setIsEmailLocked(!isEmailLocked) } : undefined}
                            suffix={<EmailStatusBadge status={emailDomainStatus} />}
                          />
                        )}
                      />
                    )}
                  </BlockStack>
                </Box>
              </BlockStack>
            )}
            {showDnsSettings && <DNSSettings emailDomainStatus={emailDomainStatus} />}
          </BlockStack>
        </Disabled>
      </BlockStack>
    </Card>
  )
}

export { EmailSettings }
