import { Controller, useFormContext } from 'react-hook-form'
import { FormLayout, Card, ChoiceList } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import { CardSection } from '../CardSection'

const ProductSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Card>
      <CardSection>
        <CardHeader title={t('backInStockSelectionTypeCardTitle')} description={t('backInStockSelectionTypeCardDescription')} />
        <FormLayout>
          <Controller
            name="bisSelectionType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ChoiceList
                title=""
                choices={[
                  { label: t('backInStockFieldSelectionTypeExclude'), value: 'EXCLUDE' },
                  { label: t('backInStockFieldSelectionTypeInclude'), value: 'INCLUDE' },
                ]}
                onChange={(values) => onChange(values[0])}
                selected={[value]}
              />
            )}
          />
        </FormLayout>
      </CardSection>
    </Card>
  )
}

export { ProductSettings }
