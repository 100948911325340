import { createFileRoute } from '@tanstack/react-router'
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Grid, Box } from '@shopify/polaris'
import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { localisationQueryOptions, settingsQueryOptions, widgetQueryOptions } from '@/common/queryOptions'
import { useSuspenseQuery } from '@tanstack/react-query'
import { DisplayForm } from '@/components/display/DisplayForm'
import { SectionTitle } from '@/components/shared/SectionTitle'

export const Route = createFileRoute('/display')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(localisationQueryOptions())

      const settings = await queryClient.ensureQueryData(settingsQueryOptions())

      queryClient.ensureQueryData(
        widgetQueryOptions({
          widgetId: settings.widgetId,
        })
      )
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const { data: widgetSettings } = useSuspenseQuery(
    widgetQueryOptions({
      widgetId: settings.widgetId,
    })
  )

  return (
    <PageFrame title={t('displayTitle')} subtitle={t('displaySubtitle')}>
      <DisplayForm data={widgetSettings} isDisabled={false} sections={['preorder', 'backorder', 'collection', 'bis']} />
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('displayTitle')}>
      <Layout>
        <Layout.Section>
          <SectionTitle title={t('displayBackorderTitle')} description={t('displayBackorderDescription')} />
          <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('displayPreorderTitle')} description={t('displayPreorderDescription')} />
          <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('displayCollectionPageTitle')} description={t('displayCollectionPageDescription')} />
          <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <BlockStack gap="400">
                  <Box>
                    <div className="collectionImageSkeleton"></div>
                    <Box minHeight="15px" />
                    <Box maxWidth="250px">
                      <SkeletonBodyText lines={1} />
                    </Box>
                    <Box minHeight="10px" />
                    <Box>
                      <SkeletonDisplayText size="small" />
                    </Box>
                    <Box minHeight="15px" />
                    <Box>
                      <SkeletonDisplayText size="extraLarge" maxWidth="100%" />
                    </Box>
                  </Box>
                </BlockStack>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('displayBisTitle')} description={t('displayBisDescription')} />
          <BlockStack gap="400">
            <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
                <Card>
                  <BlockStack gap="400">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={3} />
                  </BlockStack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
                <Card>
                  <BlockStack gap="400">
                    <Box>
                      <SkeletonDisplayText size="small" />
                      <Box minHeight="10px" />
                      <Box width="80px">
                        <SkeletonBodyText lines={1} />
                      </Box>
                      <Box minHeight="20px" />
                      <SkeletonBodyText lines={3} />
                      <Box minHeight="20px" />
                      <div
                        style={{
                          margin: '10px 0',
                        }}
                      >
                        <SkeletonDisplayText size="extraLarge" maxWidth="100%" />
                      </div>
                    </Box>
                  </BlockStack>
                </Card>
              </Grid.Cell>
            </Grid>
            <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
                <Card>
                  <BlockStack gap="400">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={3} />
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={3} />
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={3} />
                  </BlockStack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
                <Card>
                  <BlockStack gap="400">
                    <Box background="bg-fill-tertiary" minHeight="300px" />
                  </BlockStack>
                </Card>
              </Grid.Cell>
            </Grid>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
