import { SectionTitle } from '@/components/shared/SectionTitle'
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Grid } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const CampaignDisplayFallback = () => {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('displayTitle')}>
      <Layout>
        <Layout.Section>
          <SectionTitle title={t('displayPreorderTitle')} description={t('displayPreorderDescription')} />
          <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

export { CampaignDisplayFallback }
