import { Controller, useFormContext } from 'react-hook-form'

import { BlockStack, Card, ChoiceList } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'

const CampaignTrigger = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const options = [
    {
      label: t('campaignsFieldPreorderTriggerAlwaysLabel'),
      value: 'ALWAYS',
      helpText: t('campaignsFieldPreorderTriggerAlwaysDescription'),
    },
    {
      label: t('campaignsFieldPreorderTriggerInStockLabel'),
      value: 'IN_STOCK',
      helpText: t('campaignsFieldPreorderTriggerInStockDescription'),
    },
  ]

  return (
    <div id="campaignTrigger">
      <Card>
        <BlockStack gap="400">
          <CardHeader title={t('campaignsAvailabilityCardTitle')} />
          <Controller
            name="preorderTrigger"
            control={control}
            render={({ field: { onChange, value } }) => <ChoiceList title="" choices={options} onChange={(values) => onChange(values[0])} selected={[value]} />}
          />
        </BlockStack>
      </Card>
    </div>
  )
}

export { CampaignTrigger }
