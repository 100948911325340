import { Controller, useFormContext } from 'react-hook-form'

import { Card, BlockStack, Checkbox, InlineGrid } from '@shopify/polaris'

import { DatePickerField } from '@/components/shared/DatePickerField'
import { TimeSelectField } from '@/components/shared/TimeSelectField'
import { CardHeader } from '@/components/shared/CardHeader'
import { Disabled } from '@/components/shared/Disabled'
import { useTranslation } from 'react-i18next'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'
import { useEffect } from 'react'
import { FetchCampaignResponse } from '@/common/types.ts'

const CampaignDuration = ({ status }: { status: FetchCampaignResponse['status'] | undefined }) => {
  const { t } = useTranslation()
  const { control, watch, trigger } = useFormContext()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const watchStartDate = watch('startDate')
  const watchHasEndDate = watch('hasEndDate')
  const isPublished = watch('isPublished')

  useEffect(() => {
    trigger('endDate')
  }, [watchStartDate])

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader
          title={t('campaignDurationCardTitle')}
          description={t('campaignFieldTimezoneNotice', {
            timezone: settings.timezone ?? t('campaignFieldTimezoneUnknown'),
          })}
        />
        <Disabled isDisabled={isPublished || status === 'SCHEDULED'} message={t('campaignFieldStartDateDisabledBanner')}>
          <BlockStack gap="200">
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState: { error }, formState: {} }) => {
                return (
                  <>
                    <InlineGrid columns={2} gap="400">
                      <DatePickerField
                        label={t('campaignFieldStartDateLabel')}
                        date={field.value}
                        displayTimezone={settings.timezone}
                        onChange={(val) => field.onChange(val)}
                        error={error}
                      />
                      <TimeSelectField
                        label={t('campaignFieldStartTimeLabel')}
                        date={field.value}
                        displayTimezone={settings.timezone}
                        onChange={(val) => field.onChange(val)}
                        disabled={!field.value}
                      />
                    </InlineGrid>
                  </>
                )
              }}
            />
          </BlockStack>
        </Disabled>

        <BlockStack gap="200">
          <Controller
            name="hasEndDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Checkbox label={t('campaignFieldHasEndDateLabel')} checked={value} onChange={onChange} onBlur={onBlur} />
            )}
          />
          {watchHasEndDate === true && (
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <InlineGrid columns={2} gap="400">
                      <DatePickerField
                        label={t('campaignFieldEndDateLabel')}
                        date={field.value}
                        displayTimezone={settings.timezone}
                        onChange={(val) => field.onChange(val)}
                        error={error}
                      />
                      <TimeSelectField
                        label={t('campaignFieldEndTimeLabel')}
                        date={field.value}
                        displayTimezone={settings.timezone}
                        onChange={(val) => field.onChange(val)}
                        disabled={!field.value}
                      />
                    </InlineGrid>
                  </>
                )
              }}
            />
          )}
        </BlockStack>
      </BlockStack>
    </Card>
  )
}

export { CampaignDuration }
