import { Controller, useFormContext } from 'react-hook-form'
import { FormLayout, TextField, BlockStack } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const SoldOutWidgetSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <BlockStack gap="400">
      <FormLayout>
        <BlockStack gap="200">
          <Controller
            name="soldOutAddToCartButtonText"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('displaySoldOutButtonLabel')}
                placeholder={t('displaySoldOutButtonPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="soldOutNote"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField autoComplete="off" label={t('displaySoldOutNoteLabel')} value={value ?? ''} onChange={onChange} multiline={2} error={error?.message} />
            )}
          />
        </BlockStack>
      </FormLayout>
    </BlockStack>
  )
}

export { SoldOutWidgetSettings }
