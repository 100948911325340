import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormLayout, Card, ChoiceList, TextField } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import { Disabled } from '@/components/shared/Disabled'

const CampaignStockLimit = ({ publishedAt }: { publishedAt: string | null }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const options = [
    {
      label: t('campaignsFieldPreorderStockLimitManagedNo'),
      value: 'false',
    },
    {
      label: t('campaignsFieldPreorderStockLimitManagedYes'),
      value: 'true',
      renderChildren: (isSelected: boolean) =>
        isSelected && (
          <Controller
            name="stockLimit"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <TextField
                  autoComplete="off"
                  label={t('campaignsFieldStockLimit')}
                  type="number"
                  min={0}
                  max={2147483647}
                  helpText={t('campaignsFieldStockLimitDescription')}
                  value={field.value?.toString() ?? undefined}
                  onChange={(val) => {
                    const parsed = parseInt(val, 10)
                    field.onChange(isNaN(parsed) ? null : parsed)
                  }}
                  error={error?.message}
                />
              )
            }}
          />
        ),
    },
  ]

  return (
    <Card>
      <CardHeader title={t('campaignStockLimitTitle')} />
      <Box paddingBlockStart="300">
        <FormLayout>
          <Disabled isDisabled={publishedAt !== null} message={t('campaignsFieldPreorderStockLimitDisabledBanner')}>
            <Controller
              name="hasStockLimit"
              control={control}
              render={({ field: { onChange, value } }) => {
                const stringValue = value.toString()
                return <ChoiceList title="" choices={options} onChange={(values) => onChange(values[0] === 'true')} selected={[stringValue]} />
              }}
            />
          </Disabled>
        </FormLayout>
      </Box>
    </Card>
  )
}

export { CampaignStockLimit }
