import { Badge } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const ContactBisStatusBadge = ({
  status,
}: {
  status: 'QUEUED' | 'REQUESTED' | 'SENT' | 'DELIVERED' | 'DELIVERY_DELAYED' | 'COMPLAINED' | 'BOUNCED' | 'OPENED' | 'CLICKED' | null
}) => {
  const { t } = useTranslation()

  let label: string
  let tone: 'info' | 'success' | 'warning' | 'attention' | 'critical' | undefined

  switch (status) {
    case 'QUEUED':
    case 'REQUESTED':
    case 'DELIVERY_DELAYED':
      label = t('backInStockContactTableStatusSending')
      tone = 'info'
      break
    case 'SENT':
      label = t('backInStockContactTableStatusSent')
      tone = 'info'
      break
    case 'DELIVERED':
    case 'COMPLAINED':
      label = t('backInStockContactTableStatusDelivered')
      tone = 'info'
      break
    case 'OPENED':
      label = t('backInStockContactTableStatusOpened')
      tone = 'success'
      break
    case 'CLICKED':
      label = t('backInStockContactTableStatusClicked')
      tone = 'success'
      break
    case 'BOUNCED':
      label = t('backInStockContactTableStatusBounced')
      tone = 'critical'
      break
    case null:
    default:
      label = t('backInStockContactTableStatusNotSent')
      tone = undefined
      break
  }

  return label ? <Badge tone={tone}>{label}</Badge> : null
}

export { ContactBisStatusBadge }
