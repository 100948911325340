import { useState } from 'react'

import { IndexTable, Text, Card, Link, IndexFilters, TabProps, useSetIndexFiltersMode, EmptySearchResult } from '@shopify/polaris'

import dayjs from '@/common/datetime'
import { Customer, FulfillmentStatus, FinancialStatus, PaymentStatus } from '@/common/types'
import { CustomerPopover } from '@/components/shared/CustomerPopover'
import { FulfillmentBadge } from '@/components/shared/FulfillmentBadge'
import { useTranslation } from 'react-i18next'
import { PaymentBadge } from './PaymentBadge'
import { ProductPopover } from './ProductPopover'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'
import { AutoPaymentIcon } from '@/components/shared/AutoPaymentIcon'

const noop = () => {}

export type OrderTableRowProps = {
  uuid: string
  orderId: string
  name: string
  url: string
  customer: Customer
  orderDate: string
  orderCurrency: string | null
  totalAmount: number
  fulfilmentStatus: FulfillmentStatus
  financialStatus: FinancialStatus
  paymentStatus?: PaymentStatus
  paymentMandateId?: string | null
  paymentDueOn?: string | null
  jobId?: string | null
  itemCount: number
}

type OrderTableProps = {
  tableType: 'PRE_ORDER' | 'BACKORDER'
  currentPage: number
  tabs: TabProps[]
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  rows: OrderTableRowProps[]
  isLoading?: boolean
  hasNext: boolean
}

const OrderTable = ({ tableType, rows = [], isLoading, currentPage, setCurrentPage, hasNext, tabs }: OrderTableProps) => {
  const { t } = useTranslation()
  const [i18n] = useI18n()
  const [tab, setTab] = useState(0)

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const { mode, setMode } = useSetIndexFiltersMode()

  const resourceName = {
    singular: t('ordersTableResourceName'),
    plural: t('ordersTableResourceNamePlural'),
  }

  const rowMarkup = rows.map(
    (
      {
        uuid,
        orderId,
        name,
        url,
        customer,
        itemCount,
        orderDate,
        orderCurrency,
        financialStatus,
        paymentStatus,
        paymentMandateId,
        jobId,
        paymentDueOn,
        fulfilmentStatus,
        totalAmount,
      },
      index
    ) => {
      // NB: Can't use formatPrice function here? Hook error - have to directly use formatCurrency
      // Note: Would need to make a new component for the row.
      const formattedPreorderAmount = i18n.formatCurrency(totalAmount ?? 0, {
        currency: orderCurrency ?? settings.currency ?? 'USD',
      })

      // If in past week, show as "Wednesday at 7:00pm"
      // Otherwise, show as "1 Jan 2024 at 7:00pm" (omit year if same as current year)
      const orderDateFormatted =
        dayjs().diff(dayjs(orderDate), 'days') < 7
          ? dayjs(orderDate)
              .tz(settings.timezone)
              .format(`dddd [${t('datetimeAt')}] h:mma`)
          : dayjs(orderDate)
              .tz(settings.timezone)
              .format(`D MMM ${dayjs(orderDate).year() !== dayjs().year() ? `YYYY ` : ''}[${t('datetimeAt')}] h:mma`)

      const isSubdued = financialStatus === 'REFUNDED' || (financialStatus === 'PAID' && fulfilmentStatus === 'FULFILLED')

      return (
        <IndexTable.Row id={uuid} key={uuid} position={index} tone={isSubdued ? 'subdued' : undefined}>
          <IndexTable.Cell className="title-cell">
            <div className="title-wrapper">
              <Link monochrome dataPrimaryLink url={url} removeUnderline>
                <Text as="span" fontWeight="semibold">
                  {name}
                </Text>
              </Link>
            </div>
          </IndexTable.Cell>

          <IndexTable.Cell>{orderDateFormatted}</IndexTable.Cell>

          <IndexTable.Cell>
            <CustomerPopover customer={customer} />
          </IndexTable.Cell>

          <IndexTable.Cell>
            <ProductPopover orderId={orderId} itemCount={itemCount ?? 0} />
          </IndexTable.Cell>

          <IndexTable.Cell>
            <Text as="span" alignment="end" numeric>
              {formattedPreorderAmount} {orderCurrency !== settings.currency ? orderCurrency ?? settings.currency ?? 'USD' : ''}
            </Text>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <PaymentBadge financialStatus={financialStatus} />
            {!(financialStatus === 'PAID' && paymentStatus === 'AWAITING_DEFERRED') && paymentStatus && paymentMandateId && paymentDueOn && (
              <AutoPaymentIcon paymentStatus={paymentStatus} financialStatus={financialStatus} jobId={jobId} paymentDueOn={paymentDueOn} />
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <FulfillmentBadge fulfillmentStatus={fulfilmentStatus} />
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    }
  )

  const EmptySearchResultMarkup = () => {
    return <EmptySearchResult title={t('ordersTableEmptyTitle')} description={t('ordersTableEmptyDescription')} withIllustration />
  }

  return (
    <Card padding="0">
      <IndexFilters
        selected={tab}
        onSelect={setTab}
        onQueryChange={noop}
        onQueryClear={noop}
        canCreateNewView={false}
        cancelAction={{
          onAction: noop,
          disabled: true,
          loading: false,
        }}
        filters={[]}
        onClearAll={noop}
        mode={mode}
        setMode={setMode}
        hideFilters
        hideQueryField
        tabs={tabs}
        disableStickyMode={true}
        loading={isLoading}
      />
      <IndexTable
        //sortable={[false, false, true, true, true, true]}
        selectable={false}
        resourceName={resourceName}
        itemCount={rows.length}
        headings={[
          { title: t('ordersTableFieldName') },
          { title: t('ordersTableFieldDate') },
          { title: t('ordersTableFieldCustomer') },
          { title: t('ordersTableFieldItems') },
          { title: tableType === 'PRE_ORDER' ? t('ordersTableFieldPreorderTotal') : t('ordersTableFieldBackorderTotal'), alignment: 'end' },
          { title: t('ordersTableFieldPaymentStatus') },
          { title: t('ordersTableFieldFulfillmentStatus') },
        ]}
        emptyState={<EmptySearchResultMarkup />}
        pagination={{
          hasNext,
          onNext: () => setCurrentPage((currentPage: number) => currentPage + 1),
          hasPrevious: currentPage > 1,
          onPrevious: () => setCurrentPage((currentPage: number) => currentPage - 1),
        }}
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  )
}

export { OrderTable }
