import { useEffect, useState, useTransition } from 'react'
import { OrderTableRowProps, OrderTable } from '@/components/shared/OrderTable'
import { FinancialStatus, FulfillmentStatus, PreorderTableTab } from '@/common/types'
import { useSuspenseQuery } from '@tanstack/react-query'
import { campaignOrdersQueryOptions } from '@/common/queryOptions'
import { useTranslation } from 'react-i18next'
import { TabProps } from '@shopify/polaris'

type LoaderProps = {
  uuid?: string
  type?: PreorderTableTab['slug']
  page?: number
}

const CampaignOrderTable = ({ uuid, type, page = 1 }: LoaderProps) => {
  const { t } = useTranslation()
  const [isPending, startTransition] = useTransition()
  const [filterFinancialStatus, setFilterFinancialStatus] = useState<FinancialStatus | undefined>(undefined)
  const [filterFulfillmentStatus, setFilterFulfillmentStatus] = useState<FulfillmentStatus | undefined>(undefined)

  const [activeTab, setActiveTab] = useState<PreorderTableTab['slug']>(type ?? 'all')
  const [currentPage, setCurrentPage] = useState<number>(page)

  const { data, isFetching } = useSuspenseQuery({
    ...campaignOrdersQueryOptions({
      uuid,
      financialStatus: filterFinancialStatus,
      fulfillmentStatus: filterFulfillmentStatus,
      page: currentPage,
    }),
    refetchInterval: 60000,
  })

  /**
   * Set the statuses based on the active tab
   */
  useEffect(() => {
    startTransition(() => {
      if (activeTab === 'all') {
        setFilterFinancialStatus(undefined)
        setFilterFulfillmentStatus(undefined)
        setCurrentPage(1)
      }
      if (activeTab === 'unfulfilled') {
        setFilterFulfillmentStatus('UNFULFILLED')
        setFilterFinancialStatus(undefined)
        setCurrentPage(1)
      }
      if (activeTab === 'scheduled') {
        setFilterFulfillmentStatus('SCHEDULED')
        setFilterFinancialStatus(undefined)
        setCurrentPage(1)
      }
      if (activeTab === 'fulfilled') {
        setFilterFulfillmentStatus('FULFILLED')
        setFilterFinancialStatus(undefined)
        setCurrentPage(1)
      }
      if (activeTab === 'refunded') {
        setFilterFulfillmentStatus(undefined)
        setFilterFinancialStatus('REFUNDED')
        setCurrentPage(1)
      }
      if (activeTab === 'partially-paid') {
        setFilterFulfillmentStatus(undefined)
        setFilterFinancialStatus('PARTIALLY_PAID')
        setCurrentPage(1)
      }
      if (activeTab === 'on-hold') {
        setFilterFulfillmentStatus('ON_HOLD')
        setFilterFinancialStatus(undefined)
        setCurrentPage(1)
      }
    })
  }, [activeTab])

  const orders = data?.data ?? []
  const hasNext = data?.hasNext ?? false

  const tabs: PreorderTableTab[] = [
    { slug: 'all', label: t('ordersTableTabAll') },
    { slug: 'unfulfilled', label: t('ordersTableTabUnfulfilled') },
    { slug: 'scheduled', label: t('ordersTableTabScheduled') },
    { slug: 'fulfilled', label: t('ordersTableTabFulfilled') },
    { slug: 'on-hold', label: t('ordersTableTabOnHold') },
    { slug: 'refunded', label: t('ordersTableTabRefunded') },
    { slug: 'partially-paid', label: t('ordersTableTabPartiallyPaid') },
  ]

  const tableTabs: TabProps[] = tabs.map((item, index) => ({
    content: item.label,
    index,
    onAction: () => {
      setActiveTab(item.slug)
    },
    id: `${item}-${index}`,
    isLocked: true,
  }))

  const rows = orders.map((item) => {
    return {
      uuid: item.order.uuid,
      orderId: item.apiData.id,
      name: item.order.name,
      url: `shopify://admin/orders/${item.apiData.legacyResourceId}`,
      customer: item.apiData.customer,
      orderDate: item.order.createdAt,
      orderCurrency: item.order.currency,
      totalAmount: item.order.totalAmount,
      fulfilmentStatus: item.order.fulfillmentStatus,
      financialStatus: item.order.financialStatus,
      paymentStatus: item.order.paymentStatus,
      paymentMandateId: item.order.paymentMandateId,
      paymentDueOn: item.order.paymentDueOn,
      jobId: item.order.jobId,
      itemCount: item.order.itemCount,
    }
  }) satisfies OrderTableRowProps[]

  return (
    <OrderTable
      tableType="PRE_ORDER"
      currentPage={currentPage}
      rows={rows}
      hasNext={hasNext}
      isLoading={isPending || isFetching}
      tabs={tableTabs}
      setCurrentPage={(val) =>
        startTransition(() => {
          setCurrentPage(val)
        })
      }
    />
  )
}

export { CampaignOrderTable }
