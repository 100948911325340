import { Controller, useFormContext } from 'react-hook-form'
import { Card, BlockStack, InlineGrid, Select, FormLayout, Banner } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import i18n from 'i18next'
import { CardSection } from '../CardSection'

const BATCH_SIZES = [
  {
    label: i18n.t('generic1x'),
    value: '1',
  },
  {
    label: i18n.t('generic2x'),
    value: '2',
  },
  {
    label: `${i18n.t('generic5x')} ${i18n.t('genericRecommendedSuffix')}`,
    value: '5',
  },
  {
    label: i18n.t('generic10x'),
    value: '10',
  },
  {
    label: i18n.t('generic20x'),
    value: '20',
  },
]

const BATCH_DELAYS = [
  {
    label: i18n.t('genericMinute', {
      count: 5,
    }),
    value: '5',
  },
  {
    label: i18n.t('genericMinute', {
      count: 15,
    }),
    value: '15',
  },
  {
    label: i18n.t('genericMinute', {
      count: 30,
    }),
    value: '30',
  },
  {
    label: i18n.t('genericHour', {
      count: 1,
    }),
    value: '60',
  },
  {
    label: i18n.t('genericHour', {
      count: 2,
    }),
    value: '120',
  },
  {
    label: i18n.t('genericHour', {
      count: 4,
    }),
    value: '240',
  },
  {
    label: i18n.t('genericHour', {
      count: 8,
    }),
    value: '480',
  },
  {
    label: i18n.t('genericHour', {
      count: 12,
    }),
    value: '720',
  },
  {
    label: i18n.t('genericDay', {
      count: 1,
    }),
    value: '1440',
  },
]

const SORTING_METHODS = [
  {
    label: i18n.t('backInStockFieldBatchSortingOldest'),
    value: 'OLDEST',
  },
  {
    label: i18n.t('backInStockFieldBatchSortingNewest'),
    value: 'NEWEST',
  },
]

const BatchSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Card>
      <BlockStack gap="400">
        <CardSection>
          <CardHeader title={t('backInStockBatchCardTitle')} description={t('backInStockBatchCardDescription')} />
          <Banner tone="info">
            <p>{t('backInStockBatchCardInfoBanner')}</p>
          </Banner>
          <BlockStack gap="400">
            <BlockStack gap="400">
              <InlineGrid columns="2" gap="400">
                <Controller
                  name="batchMultiplier"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      label={t('backInStockFieldBatchMultiplierLabel')}
                      options={BATCH_SIZES}
                      value={value.toString()}
                      onChange={(val) => onChange(parseInt(val, 10))}
                      onBlur={onBlur}
                      helpText={t('backInStockFieldBatchMultiplierDescription', {
                        multiplier: t(`generic${BATCH_SIZES.find((v) => v.value == value)?.value}x`),
                      })}
                    />
                  )}
                />
                <Controller
                  name="batchDelay"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      label={t('backInStockFieldBatchDelayLabel')}
                      options={BATCH_DELAYS}
                      value={value.toString()}
                      onChange={(val) => onChange(parseInt(val, 10))}
                      onBlur={onBlur}
                      helpText={t('backInStockFieldBatchDelayDescription', {
                        delay: BATCH_DELAYS.find((v) => v.value == value)?.label,
                      })}
                    />
                  )}
                />
              </InlineGrid>
            </BlockStack>
          </BlockStack>
        </CardSection>
        <CardSection>
          <CardHeader title={t('backInStockFieldBatchSortingLabel')} description={t('backInStockFieldBatchSortingDescription')} />
          <FormLayout>
            <Controller
              name="sortingMethod"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  label={t('backInStockFieldBatchSortingLabel')}
                  labelHidden
                  options={SORTING_METHODS}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormLayout>
        </CardSection>
      </BlockStack>
    </Card>
  )
}

export { BatchSettings }
