import { MetricCard } from '@/components/shared/MetricCard'
import { InlineGrid, Bleed, Layout } from '@shopify/polaris'
import { SparkLineChart } from '@shopify/polaris-viz'
import { useTranslation } from 'react-i18next'
import { DataSeries } from '@shopify/polaris-viz-core'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQueries } from '@tanstack/react-query'
import {
  reportsBackordersQueryOptions,
  reportsBisListQueryOptions,
  campaignReportsOrdersQueryOptions,
  settingsQueryOptions,
  reportsBisQueryOptions,
} from '@/common/queryOptions'
import { SectionTitle } from '../shared/SectionTitle'

const CampaignMetrics = () => {
  const [i18n] = useI18n()
  const { t } = useTranslation()

  const [{ data: campaignReport }, { data: reportsBackorder }, { data: reportsBackInStock }, { data: reportsBis }, { data: settings }] = useSuspenseQueries({
    queries: [
      campaignReportsOrdersQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBackordersQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBisQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBisListQueryOptions({
        from: undefined,
        to: undefined,
      }),
      settingsQueryOptions(),
    ],
  })

  const placeholderChartData: DataSeries[] = [
    {
      data: [
        {
          key: '',
          value: 0,
        },
        {
          key: '',
          value: 0,
        },
      ],
    },
  ]
  const campaignChartData: DataSeries[] = []
  const backorderChartData: DataSeries[] = []
  const backInStockChartData: DataSeries[] = []
  const bisChartData: DataSeries[] = []

  let mainCurrencyCampaignRevenue = 0
  let mainCurrencyBackorderRevenue = 0
  let mainCurrencyBackInStockRevenue = 0
  let totalSignups = 0

  campaignReport.length &&
    campaignReport.map((group) => {
      campaignChartData.push({
        /*isComparison: group.currency !== settingsQuery.data?.currency,*/
        name: t('campaignsMetricsRevenue', {
          currency: group.currency,
        }),
        data: group.data.map((d) => {
          return {
            key: d.key,
            value: d.amount,
          }
        }),
      })

      if (group.currency === settings.currency) {
        mainCurrencyCampaignRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })

  reportsBackorder.length &&
    reportsBackorder.map((group) => {
      backorderChartData.push({
        /*isComparison: group.currency !== settingsQuery.data?.currency,*/
        name: t('campaignsMetricsRevenue', {
          currency: group.currency,
        }),
        data: group.data.map((d) => {
          return {
            key: d.key,
            value: d.amount,
          }
        }),
      })

      if (group.currency === settings.currency) {
        mainCurrencyBackorderRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })

  reportsBackInStock.length &&
    reportsBackInStock.map((group) => {
      backInStockChartData.push({
        /*isComparison: group.currency !== settingsQuery.data?.currency,*/
        name: t('campaignsMetricsRevenue', {
          currency: group.currency,
        }),
        data: group.data.map((d) => {
          return {
            key: d.key,
            value: d.amount,
          }
        }),
      })

      if (group.currency === settings.currency) {
        mainCurrencyBackInStockRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })

  totalSignups = reportsBis.reduce((total, current) => total + current.signups, 0) ?? 0
  bisChartData.push({
    name: t('backInStockMetricsSignups'),
    data:
      reportsBis?.map((d) => {
        return {
          key: d.key,
          value: d.signups,
        }
      }) ?? [],
  })

  return (
    <Layout.Section>
      <SectionTitle title={t('keyMetricsTitle')} description={t('keyMetricsSubtitle')} />
      <InlineGrid
        gap="400"
        columns={{
          xs: 1,
          sm: 2,
          md: 2,
          lg: 4,
        }}
      >
        <MetricCard
          heading={t('keyMetricsBackorderRevenueTitle')}
          value={i18n.formatCurrency(mainCurrencyBackorderRevenue, {
            currency: reportsBackorder[0]?.currency ?? settings.currency ?? 'USD',
          })}
          tooltip={t('keyMetricsBackorderRevenueTooltip')}
          url="/backorders"
          isLoading={false} // TODO: Make this optional
        >
          <Bleed marginInline="400" marginBlockEnd="400">
            <SparkLineChart theme="Light" data={backorderChartData.length === 0 ? placeholderChartData : backorderChartData} />
          </Bleed>
        </MetricCard>

        <MetricCard
          heading={t('keyMetricsPreorderRevenueTitle')}
          value={i18n.formatCurrency(mainCurrencyCampaignRevenue, {
            currency: reportsBackorder[0]?.currency ?? settings.currency ?? 'USD',
          })}
          tooltip={t('keyMetricsPreorderRevenueTooltip')}
          url="/preorders"
          isLoading={false}
        >
          <Bleed marginInline="400" marginBlockEnd="400">
            <SparkLineChart theme="Light" data={campaignChartData.length === 0 ? placeholderChartData : campaignChartData} />
          </Bleed>
        </MetricCard>

        <MetricCard
          heading={t('keyMetricsBackInStockRevenueTitle')}
          value={i18n.formatCurrency(mainCurrencyBackInStockRevenue, {
            currency: reportsBackInStock[0]?.currency ?? settings.currency ?? 'USD',
          })}
          tooltip={t('keyMetricsBackInStockRevenueTooltip')}
          url="/back-in-stock"
          isLoading={false}
        >
          <Bleed marginInline="400" marginBlockEnd="400">
            <SparkLineChart theme="Light" data={backInStockChartData.length === 0 ? placeholderChartData : backInStockChartData} />
          </Bleed>
        </MetricCard>

        <MetricCard
          heading={t('keyMetricsBackInStockTitle')}
          value={totalSignups.toString()}
          tooltip={t('keyMetricsBackInStockTooltip')}
          url="/back-in-stock"
          isLoading={false}
        >
          <Bleed marginInline="400" marginBlockEnd="400">
            <SparkLineChart theme="Light" data={bisChartData.length === 0 ? placeholderChartData : bisChartData} />
          </Bleed>
        </MetricCard>
      </InlineGrid>
    </Layout.Section>
  )
}

export { CampaignMetrics }
