import { useEffect, useState } from 'react'
import { FieldErrors } from 'react-hook-form'

const useScrollToError = (errors: FieldErrors) => {
  const [canFocus, setCanFocus] = useState(true)

  useEffect(() => {
    if (errors && canFocus) {
      // Scroll to the first error on the page (any element that has an ID that ends with ":Error")
      const errorElement = document.querySelector('[id$=":Error"]')
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        setCanFocus(false) // so the form doesn't suddenly jump to the next input that has error.
      }
    }
  }, [errors, canFocus])

  return [setCanFocus]
}

export default useScrollToError
