import React, { useEffect, useState, useTransition, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, EmptySearchResult, IndexFilters, IndexTable, InlineStack, Link, TabProps, Text, useSetIndexFiltersMode } from '@shopify/polaris'
import { useNavigate } from '@tanstack/react-router'
import { useSuspenseQueries } from '@tanstack/react-query'
import { bisListQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { formatDateForShopify } from '@/common/datetime'
import { ContactBisStatusBadge } from '../shared/ContactBisStatusBadge'
import { EmailConvertedIcon } from '@/components/shared/EmailConvertedIcon'

type TableRowProps = {
  id: string
  url: string
  customerId: string
  orderId: string | null
  fullName: string
  email: string
  title: string
  emailSendId: string | null
  status: 'QUEUED' | 'REQUESTED' | 'SENT' | 'DELIVERED' | 'DELIVERY_DELAYED' | 'COMPLAINED' | 'BOUNCED' | 'OPENED' | 'CLICKED' | null
  notifiedAt: string
  createdAt: string
}

type TabSlug = 'ALL' | 'NOT_SENT' | 'DELIVERED' | 'OPENED' | 'CLICKED' | 'BOUNCED'

type TableTab = {
  index: number
  slug: TabSlug
  label: string
}

const noop = () => {}

const BackInStockContactsTable = ({ productId }: { productId: string }) => {
  const { t } = useTranslation()
  const [isPending, startTransition] = useTransition()
  const { mode, setMode } = useSetIndexFiltersMode()

  const [filterStatus, setFilterStatus] = useState<TabSlug>('ALL')
  const [filterPage, setFilterPage] = useState<number>(1)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

  const tabs: TableTab[] = useMemo(
    () => [
      { index: 0, slug: 'ALL', label: t('backInStockContactTableFilterAll') },
      { index: 1, slug: 'NOT_SENT', label: t('backInStockContactTableStatusNotSent') },
      { index: 2, slug: 'DELIVERED', label: t('backInStockContactTableStatusDelivered') },
      { index: 3, slug: 'OPENED', label: t('backInStockContactTableStatusOpened') },
      { index: 4, slug: 'CLICKED', label: t('backInStockContactTableStatusClicked') },
      { index: 5, slug: 'BOUNCED', label: t('backInStockContactTableStatusBounced') },
    ],
    [t]
  )

  const [
    { data: settings },
    {
      data: { data: contacts, total, hasNext },
      isRefetching,
    },
  ] = useSuspenseQueries({
    queries: [
      settingsQueryOptions(),
      {
        ...bisListQueryOptions({
          id: productId,
          page: filterPage,
          sendStatus: filterStatus === 'ALL' ? undefined : filterStatus,
        }),
        refetchInterval: 30000,
      },
    ],
  })

  useEffect(() => {
    startTransition(() => {
      const activeTab = tabs[activeTabIndex]
      setFilterStatus(activeTab.slug)
      setFilterPage(1)
    })
  }, [activeTabIndex, tabs])

  const rows: TableRowProps[] = useMemo(
    () =>
      contacts.map(({ apiData, contact }) => ({
        id: contact.uuid,
        url: `shopify://admin/customers/${apiData.customer.legacyResourceId}`,
        customerId: contact.uuid,
        orderId: contact.orderId,
        fullName: apiData?.customer?.firstName ? `${apiData.customer.firstName} ${apiData.customer?.lastName}` : apiData.customer.email,
        email: apiData.customer.email,
        title: apiData.variant?.title ?? t('backInStockContactTableVariantTitleFallback'),
        status: contact.email_send_status,
        emailSendId: contact.email_send_id,
        createdAt: formatDateForShopify(contact.createdAt, settings.timezone),
        notifiedAt: formatDateForShopify(contact.sentAt, settings.timezone),
      })),
    [contacts, settings.timezone, t]
  )

  const resourceName = useMemo(
    () => ({
      singular: t('backInStockContactTableResourceName'),
      plural: t('backInStockContactTableResourceNamePlural'),
    }),
    [t]
  )

  const tableTabs: TabProps[] = useMemo(
    () =>
      tabs.map((item) => ({
        content: item.label,
        index: item.index,
        onAction: () => setActiveTabIndex(item.index),
        id: `${item.slug}-${item.index}`,
        isLocked: true,
      })),
    [tabs]
  )

  const handleTabSelect = useCallback((selectedTabIndex: number) => {
    setActiveTabIndex(selectedTabIndex)
  }, [])

  const changePage = useCallback((page: number) => {
    startTransition(() => {
      setFilterPage(page)
    })
  }, [])

  return (
    <Card padding="0">
      <IndexFilters
        selected={activeTabIndex}
        onSelect={handleTabSelect}
        onQueryChange={noop}
        onQueryClear={noop}
        canCreateNewView={false}
        filters={[]}
        onClearAll={noop}
        mode={mode}
        setMode={setMode}
        tabs={tableTabs}
        loading={isPending || isRefetching}
        hideFilters
        hideQueryField
        disableStickyMode
      />
      <IndexTable
        selectable={false}
        resourceName={resourceName}
        itemCount={total}
        headings={[
          { title: t('backInStockContactTableFieldContactEmail') },
          { title: t('backInStockContactTableFieldStatus') },
          { title: t('backInStockContactTableFieldVariant') },
          { title: t('backInStockContactTableFieldCreatedAt') },
          { title: t('backInStockContactTableFieldNotified') },
        ]}
        emptyState={
          <EmptySearchResult title={t('backInStockContactTableEmptyTitle')} description={t('backInStockContactTableEmptyDescription')} withIllustration />
        }
        pagination={{
          hasNext,
          onNext: () => changePage(filterPage + 1),
          hasPrevious: filterPage > 1,
          onPrevious: () => changePage(filterPage - 1),
        }}
      >
        {rows.map((row, index) => (
          <ContactTableRow key={row.id} {...row} productId={productId} index={index} />
        ))}
      </IndexTable>
    </Card>
  )
}

const ContactTableRow: React.FC<TableRowProps & { productId: string; index: number }> = ({
  productId,
  customerId,
  orderId,
  fullName,
  title,
  status,
  createdAt,
  notifiedAt,
  index,
}) => {
  const navigate = useNavigate()

  return (
    <IndexTable.Row id={customerId} key={`id`} position={index}>
      <IndexTable.Cell className="title-cell">
        <div className="title-wrapper">
          <Link
            monochrome
            dataPrimaryLink
            removeUnderline
            onClick={() =>
              navigate({
                to: '/back-in-stock/products/$productId/contacts/$contactId',
                params: {
                  productId: productId,
                  contactId: customerId,
                },
              })
            }
          >
            <InlineStack gap="200" blockAlign="center" align="center" wrap={false}>
              <Box>
                <Avatar customer name={fullName} />
              </Box>
              <Text as="span" fontWeight="medium">
                {fullName}
              </Text>
            </InlineStack>
          </Link>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" alignment="start">
          <ContactBisStatusBadge status={status} />
          <EmailConvertedIcon orderId={orderId} />
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" alignment="start">
          {title}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" alignment="start">
          {createdAt}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" alignment="start">
          {notifiedAt}
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>
  )
}

export { BackInStockContactsTable }
