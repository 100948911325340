import { useCallback, useState, useTransition } from 'react'
import {
  IndexTable,
  Text,
  Card,
  Link,
  IndexFilters,
  useSetIndexFiltersMode,
  EmptySearchResult,
  IndexFiltersMode,
  Thumbnail,
  InlineStack,
  Box,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useSuspenseQuery } from '@tanstack/react-query'
import { formatNumber, formatPrice } from '@/common/functions.ts'
import { useNavigate } from '@tanstack/react-router'
import { reportsBisProductsQueryOptions, settingsQueryOptions, shopifyGetProductsQueryOptions } from '@/common/queryOptions'
import { ProductIcon } from '@shopify/polaris-icons'
import { ConversionRatePercentage } from '@/components/shared/ConversionRatePercentage.tsx'

interface TableRowProps {
  productId: string
  title: string
  signups: number
  waiting: number
  sent: number
  totalAmount: number
  conversionRate: number
  totalInventory: number
  index: number
  image: string
  rowType: 'data' | 'child'
}

const BackInStockProductsTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isPending, startTransition] = useTransition()
  const [filterPage, setFilterPage] = useState(1)
  const [activeTab, setActiveTab] = useState(0)

  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering)

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { data: bisProducts } = useSuspenseQuery(
    reportsBisProductsQueryOptions({
      page: filterPage,
    })
  )
  const productIds = bisProducts.data.map((product) => product.productId)

  const {
    data: { nodes: bisProductsShopify },
  } = useSuspenseQuery(shopifyGetProductsQueryOptions({ productIds }))

  const { data: products = [], total = 0, hasNext = false } = bisProducts ?? {}

  const rows: TableRowProps[] =
    bisProductsShopify
      ?.map((product, index: number) => {
        const productRow = products.find((p) => p.productId === product.id)
        if (productRow) {
          return {
            ...productRow,
            title: product.title,
            totalInventory: product.totalInventory,
            index,
            rowType: 'data',
            image: product.featuredImage?.url,
          }
        }
        return null
      })
      .filter((row): row is TableRowProps => row !== null) ?? []

  const changePage = useCallback((page: number) => {
    startTransition(() => {
      setFilterPage(page)
    })
  }, [])

  const TableRow = ({ productId, title, signups, waiting, sent, totalAmount, conversionRate, totalInventory, index, image, rowType }: TableRowProps) => {
    const id = productId.split('/').pop() ?? ''

    return (
      <IndexTable.Row id={id} key={id} position={index} rowType={rowType}>
        <IndexTable.Cell className="title-cell">
          <div className="title-wrapper">
            <Link
              monochrome
              dataPrimaryLink
              onClick={() =>
                navigate({
                  to: '/back-in-stock/products/$productId',
                  params: {
                    productId: id,
                  },
                })
              }
              removeUnderline
            >
              <InlineStack blockAlign="center" gap="200" align="center" wrap={false}>
                <Box>
                  <Thumbnail source={image ?? ProductIcon} size="small" alt="Small document" />
                </Box>
                <Text as="span" fontWeight="medium">
                  {title}
                </Text>
              </InlineStack>
            </Link>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="p">
            {t('backInStockProductTableContactsCount', {
              count: signups,
              signups: formatNumber(signups),
            })}
          </Text>
          <Text as="span" variant="bodyXs" tone="subdued">
            {t('backInStockProductTableWaitingCount', {
              waiting: formatNumber(waiting),
            })}
          </Text>{' '}
          <Text as="span" variant="bodyXs" tone="subdued">
            /
          </Text>{' '}
          <Text as="span" variant="bodyXs" tone="subdued">
            {t('backInStockProductTableSentCount', {
              sent: formatNumber(sent),
            })}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" tone={totalInventory <= 0 ? 'critical' : undefined}>
            {t('backInStockProductTableInventoryCount', {
              inventory: formatNumber(totalInventory),
            })}
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text as="span" alignment="end" numeric>
            {formatPrice(totalAmount, settings.currency)}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" alignment="end">
            <ConversionRatePercentage conversionRate={conversionRate} />
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  }

  return (
    <Card padding="0">
      <IndexFilters
        selected={activeTab}
        onSelect={setActiveTab}
        onQueryChange={() => {}}
        onQueryClear={() => {}}
        canCreateNewView={false}
        filters={[]}
        onClearAll={() => {}}
        mode={mode}
        setMode={setMode}
        tabs={[]}
        loading={isPending}
        hideFilters
        hideQueryField
        disableStickyMode
      />
      <IndexTable
        selectable={false}
        loading={isPending}
        resourceName={{ singular: t('productsTableResourceName'), plural: t('productsTableResourceNamePlural') }}
        itemCount={total}
        headings={[
          { title: t('backInStockProductTableFieldProduct') },
          { title: t('backInStockProductTableFieldSignups') },
          { title: t('backInStockProductTableFieldStock') },
          {
            title: t('backInStockProductTableFieldRevenue', {
              currency: settings.currency,
            }),
            tooltipWidth: 'wide',
            tooltipContent: t('backInStockProductTableRevenueTooltip'),
            alignment: 'end',
          },
          {
            title: t('backInStockProductTableFieldConversionRate'),
            tooltipWidth: 'wide',
            tooltipContent: t('backInStockConversionRateTooltip'),
            alignment: 'end',
          },
        ]}
        hasMoreItems={hasNext}
        emptyState={<EmptySearchResult title={t('productsTableEmptyTitle')} description={t('productsTableEmptyDescription')} withIllustration />}
        pagination={{
          hasNext,
          onNext: () => changePage(filterPage + 1),
          hasPrevious: filterPage > 1,
          onPrevious: () => changePage(filterPage - 1),
        }}
      >
        {rows.map((row) => (
          <TableRow key={row.productId} {...row} />
        ))}
      </IndexTable>
    </Card>
  )
}

export { BackInStockProductsTable }
