import { formatSecondsAsTime } from '@/common/datetime'
import { settingsQueryOptions } from '@/common/queryOptions'
import { Checklist } from '@/components/shared/Checklist'
import { useChecklist } from '@/hooks/useChecklist'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from '@shopify/polaris'
import { useSuspenseQuery } from '@tanstack/react-query'

const stages = [
  {
    id: 'ANALYZING',
    statuses: ['ANALYZING', 'ANALYZING_MIXED'],
  },
  {
    id: 'DISCOVERING',
    statuses: ['DISCOVERING'],
  },
  {
    id: 'UPLOADING',
    statuses: ['UPLOADING'],
  },
  {
    id: 'UPDATING_PRODUCTS',
    statuses: ['UPDATING_PRODUCTS_ON', 'UPDATING_PRODUCTS_OFF', 'UPDATING_PRODUCTS_MIXED'],
  },
  {
    id: 'COMPLETE',
    statuses: ['UPDATE_COMPLETE'],
  },
]

const BackorderPublishingQueue = () => {
  const { t } = useTranslation()

  const [inFlow, setInFlow] = useState<boolean>(false)
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { items, addItems, updateItem, clearItems, title, setTitle, description, setDescription, isAllItemsComplete } = useChecklist()
  const estProductTime = formatSecondsAsTime(settings.backorderIsContinueSellingManaged ? (settings.backorderCurrentProductCount || 0) * 0.4 + 5 : 5)

  useEffect(() => {
    // If the backorderStatus is in any of the status arrays from the stages variable, set inFlow true
    const isInFlow = stages.some((stage) => stage.statuses.includes(settings.backorderStatus))
    if (isInFlow) setInFlow(true)
  }, [settings.backorderStatus])

  const handleDismiss = () => {
    clearItems()
    setInFlow(false)
  }

  const updateListItemStatus = () => {
    const statusIndex = stages.findIndex((stage) => stage.statuses.includes(settings.backorderStatus))

    items.forEach(({ id }) => {
      const itemIndex = stages.findIndex((stage) => stage.id === id)

      switch (true) {
        case itemIndex < statusIndex:
        case statusIndex === -1:
          updateItem(id, { status: 'COMPLETE' })
          break
        case itemIndex === statusIndex:
          updateItem(id, { status: 'IN_PROGRESS' })
          break
        default:
          updateItem(id, { status: 'INCOMPLETE' })
          break
      }
    })
  }

  useEffect(() => {
    clearItems()
    setTitle(t('backorderChecklistUpdatingTitle'))
    setDescription(t('backorderChecklistProcessingDescription'))
    addItems([
      {
        id: 'ANALYZING',
        title: t('backorderChecklistItemUpdating'),
        status: 'INCOMPLETE',
      },
      {
        id: 'DISCOVERING',
        title: t('backorderChecklistItemDiscoveringProducts', {
          count: settings.backorderCurrentProductCount || 0,
        }),
        status: 'INCOMPLETE',
      },
      {
        id: 'UPLOADING',
        title: t('backorderChecklistItemUploading'),
        status: 'INCOMPLETE',
      },
      {
        id: 'UPDATING_PRODUCTS',
        title: t('backorderChecklistItemUpdatingProducts', {
          count: settings.backorderCurrentProductCount || 0,
          eta: estProductTime,
        }),
        status: 'INCOMPLETE',
      },
      {
        id: 'COMPLETE',
        title: t('backorderChecklistItemUpdated'),
        status: 'INCOMPLETE',
      },
    ])
  }, [])

  useEffect(() => {
    updateItem('DISCOVERING', {
      title: t('backorderChecklistItemDiscoveringProducts', {
        count: settings.backorderCurrentProductCount || 0,
      }),
    })

    updateItem('UPDATING_PRODUCTS', {
      title: t('backorderChecklistItemUpdatingProducts', {
        count: settings.backorderCurrentProductCount || 0,
        eta: estProductTime,
      }),
    })
  }, [settings.backorderCurrentProductCount])

  useEffect(() => {
    // We don't have a flow or items
    if (!items.length) return

    if (isAllItemsComplete) {
      setTitle(t('backorderChecklistUpdatedTitle'))
      setDescription('')
    } else {
      setTitle(t('backorderChecklistUpdatingTitle'))
      setDescription(t('backorderChecklistProcessingDescription'))
    }

    updateListItemStatus()
  }, [settings.backorderStatus, items.length, isAllItemsComplete])

  if (!inFlow) return null

  return (
    <Layout.Section>
      <Checklist title={title} description={description} items={items} onDismiss={handleDismiss} />
    </Layout.Section>
  )
}

export { BackorderPublishingQueue }
