import { FormLayout, TextField } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { ColorPicker } from '../shared/ColorPicker'

const BisModalWidgetSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <FormLayout>
      <FormLayout.Group>
        <Controller
          name="bisModalBackgroundColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayBisModalBackgroundColorLabel')}
              placeholder="#ffffff"
              defaultColor="#ffffff"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
            />
          )}
        />
        <Controller
          name="bisModalTextColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayBisModalTextColorLabel')}
              placeholder="#000000"
              defaultColor="#000000"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
            />
          )}
        />
      </FormLayout.Group>
      <Controller
        name="bisModalTitle"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisModalTitleLabel')}
            placeholder={t('displayBisModalTitlePlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error?.message}
          />
        )}
      />

      <Controller
        name="bisModalDescription"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisModalDescriptionLabel')}
            placeholder={t('displayBisModalDescriptionPlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error?.message}
            multiline={3}
          />
        )}
      />

      <Controller
        name="bisModalEmailPlaceholder"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisFormEmailPlaceholderLabel')}
            placeholder={t('displayBisFormEmailPlaceholderPlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error?.message}
          />
        )}
      />

      <Controller
        name="bisModalMarketingConsentLabel"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisFormMarketingConsentLabel')}
            placeholder={t('displayBisFormMarketingConsentPlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error?.message}
          />
        )}
      />
    </FormLayout>
  )
}

export { BisModalWidgetSettings }
