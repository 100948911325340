import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { Layout } from '@shopify/polaris'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProductSettings } from './ProductSettings'
import { z } from 'zod'
import { useMutateSettings } from '@/hooks/useMutateSettings'
import merge from 'ts-deepmerge'
import { useTranslation } from 'react-i18next'
import { ProductSelection } from './ProductSelection'
import { InventoryPolicy } from './InventoryPolicy'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { SaveBar } from '@shopify/app-bridge-react'
import { settingsQueryOptions, shopifyResourcePickerQueryOptions } from '@/common/queryOptions'
import { Disabled } from '../shared/Disabled'
import { useEffect } from 'react'
import { useNavigate } from '@tanstack/react-router'
import useScrollToError from '@/hooks/useScrollToError'

// Define the schema for form validation
const schema = z.object({
  backorderEnabled: z.boolean(),
  backorderIsContinueSellingManaged: z.boolean(),
  backorderSelectionType: z.enum(['INCLUDE', 'EXCLUDE']),
  backorderProducts: z
    .array(
      z.object({
        id: z.string(),
        title: z.string(),
        featuredImage: z
          .object({
            url: z.string(),
          })
          .optional(),
        variants: z
          .array(
            z.object({
              id: z.string(),
            })
          )
          .optional(),
        totalVariants: z.number().optional(),
      })
    )
    .nullable(),
})

// Define the type for the form data
export type FormSchema = z.infer<typeof schema>
export type SettingsFormFieldsWithDeleted = FormSchema & {
  deleted?: {
    backorderProductIds: string[]
  }
  added?: {
    backorderProductIds: string[]
  }
}

// Set default values for the form
const defaultValues: FormSchema = {
  backorderEnabled: false,
  backorderIsContinueSellingManaged: false,
  backorderSelectionType: 'EXCLUDE',
  backorderProducts: [],
}

const BackorderForm = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { data: shopifyProducts } = useSuspenseQuery(shopifyResourcePickerQueryOptions({ productIds: settings.backorderProductIds }))

  const defaultFormValues = settings
    ? merge.withOptions({ allowUndefinedOverrides: false }, defaultValues, {
        backorderEnabled: settings.backorderEnabled,
        backorderIsContinueSellingManaged: settings.backorderIsContinueSellingManaged,
        backorderSelectionType: settings.backorderSelectionType,
        backorderProducts: shopifyProducts,
      })
    : defaultValues

  const form = useForm<FormSchema>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const {
    watch,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const selectionType = watch('backorderSelectionType')
  const mutateSettings = useMutateSettings()

  const isDisabled = (settings.backorderStatus !== 'COMPLETE' && settings.backorderStatus !== 'ERROR') || mutateSettings.isPending

  useEffect(() => {
    if (isDisabled) return

    if (isDirty) {
      shopify.saveBar.show('backorder-save-bar')
    } else {
      shopify.saveBar.hide('backorder-save-bar')
    }
  }, [isDirty, isDisabled])

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onError: SubmitErrorHandler<FormSchema> = (_) => {
    setCanFocus(true)

    shopify.toast.show(t('genericErrorToast'), {
      duration: 2000,
      isError: true,
    })
  }

  // Create our actions
  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    if (isDisabled) {
      return false
    }

    // Grab the product IDs from the form
    const { backorderProducts, ...formValues } = data

    // Build up the mutation payload
    let payload = {
      ...formValues,
      backorderProductIds: backorderProducts?.map((product) => product.id) ?? [],
      added: {
        backorderProductIds: [] as string[],
      },
      deleted: {
        backorderProductIds: [] as string[],
      },
    }

    // If enabled, determine the new and deleted products
    if (settings.backorderEnabled) {
      const existingProducts = settings?.backorderProductIds ?? []
      const formProducts = payload.backorderProductIds ?? []

      const newProducts = formProducts.filter((product) => !existingProducts.includes(product))
      const deletedProducts = existingProducts.filter((product) => !formProducts.includes(product))

      payload = {
        ...payload,
        added: {
          backorderProductIds: newProducts,
        },
        deleted: {
          backorderProductIds: deletedProducts,
        },
      }
    }

    try {
      await mutateSettings.mutateAsync(payload, {
        onSuccess: (data) => {
          shopify.saveBar.hide('backorder-save-bar')

          queryClient.invalidateQueries({
            queryKey: settingsQueryOptions().queryKey,
          })

          queryClient.invalidateQueries({
            queryKey: ['shopify-resource-picker'],
          })

          shopify.toast.show(t('settingsUpdatedToast'), {
            duration: 2000,
          })

          navigate({ to: '/backorders' })

          return data
        },
      })
    } catch (error) {
      console.log(error)
      shopify.toast.show(t('genericErrorToast'), {
        duration: 2000,
        isError: true,
      })
    }
  }

  return (
    <FormProvider {...form}>
      <SaveBar id="backorder-save-bar">
        <button
          variant="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={mutateSettings.isPending}
          loading={mutateSettings.isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={mutateSettings.isPending}></button>
      </SaveBar>

      <Layout.AnnotatedSection title={t('backorderSelectionTypeTitle')} description={t('backorderSelectionTypeDescription')}>
        <Disabled isDisabled={isDisabled}>
          <ProductSettings />
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title={t('backorderProductsTitle')}
        description={selectionType === 'EXCLUDE' ? t('backorderProductsExcludedDescription') : t('backorderProductsIncludedDescription')}
      >
        <Disabled isDisabled={isDisabled}>
          <ProductSelection selectionType={selectionType} />
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection title={t('backorderInventoryPolicyTitle')} description={t('backorderInventoryPolicyDescription')}>
        <Disabled isDisabled={isDisabled}>
          <InventoryPolicy />
        </Disabled>
      </Layout.AnnotatedSection>
    </FormProvider>
  )
}

export { BackorderForm }
