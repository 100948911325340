import { BlockStack, InlineGrid, Text } from '@shopify/polaris'

const CardHeader = ({ title, description, actions }: { title: string; description?: string; actions?: JSX.Element }) => {
  return (
    <BlockStack gap="050">
      <InlineGrid columns="1fr auto">
        <Text as="h2" variant="headingSm">
          {title}
        </Text>
        {actions}
      </InlineGrid>

      {description && (
        <Text as="p" variant="bodyMd" tone="subdued">
          {description}
        </Text>
      )}
    </BlockStack>
  )
}

export { CardHeader }
