import dayjs from 'dayjs'

import 'dayjs/locale/af'
import 'dayjs/locale/am'
import 'dayjs/locale/ar-dz'
import 'dayjs/locale/ar-iq'
import 'dayjs/locale/ar-kw'
import 'dayjs/locale/ar-ly'
import 'dayjs/locale/ar-ma'
import 'dayjs/locale/ar-sa'
import 'dayjs/locale/ar-tn'
import 'dayjs/locale/ar'
import 'dayjs/locale/az'
import 'dayjs/locale/be'
import 'dayjs/locale/bg'
import 'dayjs/locale/bi'
import 'dayjs/locale/bm'
import 'dayjs/locale/bn-bd'
import 'dayjs/locale/bn'
import 'dayjs/locale/bo'
import 'dayjs/locale/br'
import 'dayjs/locale/bs'
import 'dayjs/locale/ca'
import 'dayjs/locale/cs'
import 'dayjs/locale/cv'
import 'dayjs/locale/cy'
import 'dayjs/locale/da'
import 'dayjs/locale/de-at'
import 'dayjs/locale/de-ch'
import 'dayjs/locale/de'
import 'dayjs/locale/dv'
import 'dayjs/locale/el'
import 'dayjs/locale/en-au'
import 'dayjs/locale/en-ca'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/en-ie'
import 'dayjs/locale/en-il'
import 'dayjs/locale/en-in'
import 'dayjs/locale/en-nz'
import 'dayjs/locale/en-sg'
import 'dayjs/locale/en-tt'
import 'dayjs/locale/en'
import 'dayjs/locale/eo'
import 'dayjs/locale/es-do'
import 'dayjs/locale/es-mx'
import 'dayjs/locale/es-pr'
import 'dayjs/locale/es-us'
import 'dayjs/locale/es'
import 'dayjs/locale/et'
import 'dayjs/locale/eu'
import 'dayjs/locale/fa'
import 'dayjs/locale/fi'
import 'dayjs/locale/fo'
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/fr-ch'
import 'dayjs/locale/fr'
import 'dayjs/locale/fy'
import 'dayjs/locale/ga'
import 'dayjs/locale/gd'
import 'dayjs/locale/gl'
import 'dayjs/locale/gom-latn'
import 'dayjs/locale/gu'
import 'dayjs/locale/hi'
import 'dayjs/locale/he'
import 'dayjs/locale/hr'
import 'dayjs/locale/ht'
import 'dayjs/locale/hu'
import 'dayjs/locale/hy-am'
import 'dayjs/locale/id'
import 'dayjs/locale/is'
import 'dayjs/locale/it-ch'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/jv'
import 'dayjs/locale/ka'
import 'dayjs/locale/kk'
import 'dayjs/locale/km'
import 'dayjs/locale/kn'
import 'dayjs/locale/ko'
import 'dayjs/locale/ku'
import 'dayjs/locale/ky'
import 'dayjs/locale/lb'
import 'dayjs/locale/lo'
import 'dayjs/locale/lt'
import 'dayjs/locale/lv'
import 'dayjs/locale/me'
import 'dayjs/locale/mi'
import 'dayjs/locale/mk'
import 'dayjs/locale/ml'
import 'dayjs/locale/mn'
import 'dayjs/locale/mr'
import 'dayjs/locale/ms-my'
import 'dayjs/locale/ms'
import 'dayjs/locale/mt'
import 'dayjs/locale/my'
import 'dayjs/locale/nb'
import 'dayjs/locale/ne'
import 'dayjs/locale/nl-be'
import 'dayjs/locale/nl'
import 'dayjs/locale/nn'
import 'dayjs/locale/oc-lnc'
import 'dayjs/locale/pa-in'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/pt'
import 'dayjs/locale/rn'
import 'dayjs/locale/ro'
import 'dayjs/locale/sd'
import 'dayjs/locale/si'
import 'dayjs/locale/se'
import 'dayjs/locale/sk'
import 'dayjs/locale/sl'
import 'dayjs/locale/sq'
import 'dayjs/locale/sr-cyrl'
import 'dayjs/locale/sr'
import 'dayjs/locale/ss'
import 'dayjs/locale/sv-fi'
import 'dayjs/locale/sv'
import 'dayjs/locale/sw'
import 'dayjs/locale/ta'
import 'dayjs/locale/te'
import 'dayjs/locale/tg'
import 'dayjs/locale/tet'
import 'dayjs/locale/th'
import 'dayjs/locale/tk'
import 'dayjs/locale/tl-ph'
import 'dayjs/locale/tlh'
import 'dayjs/locale/tr'
import 'dayjs/locale/tzl'
import 'dayjs/locale/tzm-latn'
import 'dayjs/locale/ug-cn'
import 'dayjs/locale/tzm'
import 'dayjs/locale/uk'
import 'dayjs/locale/ur'
import 'dayjs/locale/uz-latn'
import 'dayjs/locale/vi'
import 'dayjs/locale/uz'
import 'dayjs/locale/yo'
import 'dayjs/locale/x-pseudo'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-hk'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/zh'
import 'dayjs/locale/rw'
import 'dayjs/locale/ru'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from '@/utils/dayjs-timezone'
import utc from 'dayjs/plugin/utc'
import i18n from 'i18next'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Takes a date string and returns a date object
 * @param dateString
 * @returns Date
 */
export const stringToDate = (dateString: string): Date => {
  return dayjs(dateString).toDate()
}

/**
 * Takes a date string and returns a date object
 * @param dateString
 * @returns Date
 */
export const dateToString = (date: Date): string => {
  return dayjs(date).toISOString()
}

/**
 * Takes a date string and formats it
 * @param dateString
 * @param format
 * @param tz
 * @returns string
 */
export const formatTimestamp = (dateString: string, format: string = 'D MMM'): string => {
  return dayjs(dateString).format(format)
}

/**
 * Change the timezone of a date
 */
export const changeTimezone = (dateString: string, tz: string): string => {
  return dayjs(dateString).tz(tz, true).toISOString()
}

/**
 * Returns a number between 1 and 31
 * @param dateString
 * @returns number
 */
export const getDay = (dateString: string): number => {
  return dayjs(dateString).date()
}

/**
 * Returns a number between 0 and 11
 * @param dateString
 * @returns number
 */
export const getMonth = (dateString: string): number => {
  return dayjs(dateString).month()
}

/**
 * Returns a four digit year
 * @param dateString
 * @returns number
 */
export const getYear = (dateString: string): number => {
  return dayjs(dateString).year()
}

/**
 * Returns a date string with the time set to 00:00:00
 * @returns string
 */
export const getToday = (tz: string = 'UTC'): string => {
  return dayjs().tz(tz).startOf('day').toISOString()
}

/**
 * Returns a date string with the current time
 * @returns string
 */
export const getNow = (): string => {
  return dayjs().toISOString()
}

/**
 * Extract the date part from a datetime string
 * @param datetime
 * @returns string
 */
export const extractDate = (datetime: string): string => {
  return dayjs(datetime).format('YYYY-MM-DD') // Extract the date part
}

/**
 * Combine the date part with the new time and convert to ISO string
 * @param datetime
 * @param date
 * @returns string
 */
export const setDate = (datetime: string, date: string): string => {
  const dateObj = dayjs(date)
  const year = dateObj.year()
  const month = dateObj.month()
  const day = dateObj.date()

  console.log(datetime, dateObj, year, month, day)

  return dayjs(datetime).year(year).month(month).date(day).toISOString()
}

/**
 * Extract the time part from a datetime string
 * @param datetime
 * @returns string
 */
export const extractTime = (datetime: string): string => {
  return dayjs(datetime).format('HH:mm') // Extract the time part
}

/**
 * Combine the date part with the new time and convert to ISO string
 * @param datetime
 * @param time
 * @returns string
 */
export const setTime = (datetime: string, time: string): string => {
  const [hour, minute] = time.split(':').map(Number)
  return dayjs(datetime).hour(hour).minute(minute).second(0).toISOString()
}

/**
 * Takes a date string and a number of days, appends the number of days, and returns the new date string
 * @param dateString
 * @param days
 * @returns string
 */
export const addDays = (dateString: string, days: number): string => {
  return dayjs(dateString).add(days, 'day').toISOString()
}

/**
 * Takes two date strings and returns the number of days between them
 * @param date1
 * @param date2
 * @returns number
 */
export const getDaysBetween = (date1: string, date2: string): number => {
  return dayjs(date2).diff(dayjs(date1), 'day')
}

/**
 * Takes a date string and returns the number of days since that date
 * @param dateString
 * @returns number
 */
export const getDaysSince = (dateString: string): number => {
  return dayjs().diff(dayjs(dateString), 'day')
}

/**
 * Takes two date strings and returns true if dateA is before dateB
 * @param dateA
 * @param dateB
 * @returns boolean
 */
export const isBefore = (dateA: string, dateB: string): boolean => {
  return dayjs(dateA).isBefore(dayjs(dateB))
}

export const formatSecondsAsTime = (seconds: number): string => {
  if (seconds < 60) {
    return i18n.t('genericSecond', {
      count: Math.round(seconds),
    })
  } else if (seconds < 3600) {
    const minutes = Math.round(seconds / 60)
    return i18n.t('genericMinute', {
      count: Math.round(minutes),
    })
  } else {
    const hours = Math.round(seconds / 3600)
    return i18n.t('genericHour', {
      count: Math.round(hours),
    })
  }
}

export const formatDateForShopify = (datetime: string | null | undefined, timezone: string) => {
  return datetime
    ? dayjs(datetime).year() === dayjs().year()
      ? dayjs(datetime)
          .tz(timezone)
          .format(`D MMM [${i18n.t('datetimeAt')}] h:mma`)
      : dayjs(datetime)
          .tz(timezone)
          .format(`D MMM YYYY [${i18n.t('datetimeAt')}] h:mma`)
    : '-'
}

export default dayjs
