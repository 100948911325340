import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormLayout, Card, Select } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import { DateLocales } from '@/common/types'
import dayjs from '@/common/datetime'
import { useEffect, useState } from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'

const formats = ['YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'D MMMM YYYY', 'MMM D, YYYY', 'MMMM D, YYYY']

const DateSettings = () => {
  const { t } = useTranslation()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useFormContext()

  const dateLocale = watch('dateLocale')

  const [dateFormatOptions, setDateFormatOptions] = useState<Record<'value' | 'label', string>[]>(
    formats.map((f) => {
      return {
        value: f,
        label: dayjs().tz(settings.timezone).locale(dateLocale).format(f),
      }
    })
  )

  useEffect(() => {
    setDateFormatOptions(
      formats.map((f) => {
        return {
          value: f,
          label: dayjs().tz(settings.timezone).locale(dateLocale).format(f),
        }
      })
    )
  }, [settings.timezone, dateLocale])

  const dateLocaleOptions = DateLocales.map((locale) => {
    return {
      value: locale,
      label: t(`dateLocale-${locale}`),
    }
  }).sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }))

  return (
    <Card>
      <CardHeader title={t('localisationDateCardTitle')} description={t('localisationDateCardDescription')} />
      <Box paddingBlockStart="300">
        <FormLayout>
          <Controller
            name="dateFormat"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                label={t('localisationFieldDateFormat')}
                options={dateFormatOptions}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isSubmitting}
                value={value ?? 'D MMMM YYYY'}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="dateLocale"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                label={t('localisationFieldDateLocale')}
                options={dateLocaleOptions}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isSubmitting}
                value={value ?? 'en'}
                error={error?.message}
              />
            )}
          />
        </FormLayout>
      </Box>
    </Card>
  )
}

export { DateSettings }
