import { MetricBar } from '@/components/shared/MetricBar'
import { DataSeries } from '@shopify/polaris-viz-core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { chartTitleFormatter, chartTooltipTitleFormatter, chartValueFormatter, formatNumber } from '@/common/functions.ts'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQueries } from '@tanstack/react-query'
import { campaignReportsOrdersQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Campaign } from '@/common/types.ts'
import { CampaignProductInfo } from '@/components/preorders/CampaignProductInfo'
import { Card } from '@shopify/polaris'
import { TooltipContent } from '@shopify/polaris-viz'

type CampaignChartProps = {
  uuid?: string
  campaign?: Campaign
}

const CampaignChart = ({ uuid, campaign }: CampaignChartProps) => {
  const [i18n] = useI18n()
  const { t } = useTranslation()
  const [filterStartDate, _] = useState(undefined)
  const [filterEndDate, __] = useState(undefined)

  const [{ data: settings }, { data: campaignReport }] = useSuspenseQueries({
    queries: [settingsQueryOptions(), { ...campaignReportsOrdersQueryOptions({ uuid, from: filterStartDate, to: filterEndDate }), refetchInterval: 60000 }],
  })

  const chartData: DataSeries[] = []
  let ordersTotal = 0

  campaignReport &&
    campaignReport.length &&
    campaignReport.map((group) => {
      chartData.push({
        /*isComparison: group.currency !== settingsQuery.data?.currency,*/
        name: t('campaignsMetricsRevenue', {
          currency: group.currency,
        }),
        data: group.data.map((d) => {
          return {
            key: d.key,
            value: d.amount,
          }
        }),
      })

      // Keep track of total orders (across all currencies)
      ordersTotal += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
    })

  const itemCount = (campaign?.productIds?.length ?? 0) + (campaign?.variantIds?.length ?? 0)
  const productsInfo = campaign?.products && campaign?.products.length > 0 && <CampaignProductInfo products={campaign.products} itemCount={itemCount} />

  return (
    <Card padding="400">
      <MetricBar
        blocks={[
          ...[
            campaignReport.length === 0 && (
              <MetricBar.ValueBlock
                title={t('campaignsMetricsRevenue', {
                  currency: settings.currency,
                })}
                value={i18n.formatCurrency(0, { currency: settings.currency })}
              />
            ),
          ],
          ...campaignReport.map((group) => (
            <MetricBar.ValueBlock
              title={t('campaignsMetricsRevenue', {
                currency: group.currency,
              })}
              value={i18n.formatCurrency(
                group.data.reduce((total, current) => total + (current.amount ?? 0), 0),
                { currency: group.currency }
              )}
            />
          )),
          <MetricBar.ValueBlock title={t('campaignsMetricsPreorders')} value={formatNumber(ordersTotal)} />,
          ...[
            campaign?.products && campaign?.products.length && (
              <MetricBar.ValueBlock title={t('campaignsMetricsProduct', { count: campaign?.products.length ?? 0 })} value={productsInfo} />
            ),
          ],
        ]}
        chart={
          <MetricBar.Chart
            data={chartData}
            xAxisOptions={{
              labelFormatter: chartTitleFormatter,
            }}
            yAxisOptions={{
              labelFormatter: (value) => chartValueFormatter(value, settings.currency),
            }}
            tooltipOptions={{
              titleFormatter: chartTooltipTitleFormatter,
              renderTooltipContent: (data) => {
                return (
                  <TooltipContent
                    theme="Light"
                    data={[
                      {
                        name: chartTooltipTitleFormatter(data?.title ?? ''),
                        data: data.data[0].data.map((d) => {
                          const label = d.key.toString()
                          const lineCurrency = label.split('(').pop()?.replace(')', '') ?? settings.currency
                          const formattedValue = chartValueFormatter(d.value, lineCurrency, 2)
                          return {
                            isComparison: d.isComparison,
                            isHidden: d.isHidden,
                            color: d.color,
                            key: label,
                            value: formattedValue,
                          }
                        }),
                        shape: 'Line',
                      },
                    ]}
                  />
                )
              },
            }}
          />
        }
      ></MetricBar>
    </Card>
  )
}

export { CampaignChart }
