import { Layout, SkeletonPage } from '@shopify/polaris'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { SkeletonChart } from '@/components/shared/SkeletonChart'
import { useTranslation } from 'react-i18next'

const BackordersFallback = () => {
  const { t } = useTranslation()
  return (
    <SkeletonPage title={t('backordersDashTitle')} primaryAction>
      <Layout>
        <Layout.Section>
          <SkeletonChart />
        </Layout.Section>
        <Layout.Section>
          <SkeletonTable />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

export { BackordersFallback }
