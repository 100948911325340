import { formatSecondsAsTime } from '@/common/datetime.ts'
import { Campaign } from '@/common/types'
import { Checklist } from '@/components/shared/Checklist'
import { useChecklist } from '@/hooks/useChecklist'
import { Layout } from '@shopify/polaris'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const QUEUE_STATUS = ['SCHEDULED', 'QUEUEING', 'SENDING', 'SENDING_COMPLETE', 'ERROR']

type Props = {
  status: Campaign['latestPreorderDelayed']['queueStatus']
  orderCount: number
}

/**
 * Manage the status checkist for a campaign
 * @returns
 */
const CampaignFulfillmentDelayedQueue = ({ status, orderCount }: Props) => {
  const { t } = useTranslation()
  const flow = useRef<'PUBLISHING' | undefined>(undefined)
  const { items, addItems, updateItem, clearItems, title, setTitle, description, setDescription, isAllItemsComplete } = useChecklist()

  const onDismiss = () => {
    clearItems()
    flow.current = undefined
  }

  /**
   * Check the current status and update the checklist item status based on if they came before or after the current status
   */
  const updateListItemStatus = () => {
    if (!flow.current) return

    const statusIndex = QUEUE_STATUS.indexOf(status)

    items.forEach(({ id }) => {
      const itemIndex = QUEUE_STATUS.indexOf(id)

      switch (true) {
        case itemIndex < statusIndex:
        case statusIndex === -1:
          updateItem(id, { status: 'COMPLETE' })
          break
        case itemIndex === statusIndex:
          updateItem(id, { status: 'IN_PROGRESS' })
          break
        default:
          updateItem(id, { status: 'INCOMPLETE' })
          break
      }
    })
  }

  /**
   * Populate the checklist based on the current flow
   */
  useEffect(() => {
    clearItems()

    const estQueueTime = formatSecondsAsTime(orderCount * 0.2 + 5)
    const estSendTime = formatSecondsAsTime(orderCount * 0.1 + 5)

    switch (flow.current) {
      case 'PUBLISHING':
        setTitle(t('campaignDelayedChecklistTitle'))
        addItems([
          {
            id: 'SCHEDULED',
            title: t('campaignDelayedChecklistScheduling'),
            status: 'INCOMPLETE',
          },
          {
            id: 'QUEUEING',
            title: t('campaignDelayedChecklistQueueing', {
              count: orderCount,
              eta: estQueueTime,
            }),
            status: 'INCOMPLETE',
          },
          {
            id: 'SENDING',
            title: t('campaignDelayedChecklistProcessing', {
              count: orderCount,
              eta: estSendTime,
            }),
            status: 'INCOMPLETE',
          },
          {
            id: 'SENDING_COMPLETE',
            title: t('campaignDelayedChecklistCompleted'),
            status: 'INCOMPLETE',
          },
        ])
        break
      default:
        break
    }
  }, [flow.current])

  /**
   * If we don't know the flow, determine it based on the status
   */
  useEffect(() => {
    // We already have a flow
    if (flow.current) {
      return
    }

    if (QUEUE_STATUS.includes(status)) {
      flow.current = 'PUBLISHING'
    }
  }, [status, flow.current])

  /**
   * If we don't know the flow, determine it based on the status
   */
  useEffect(() => {
    // We don't have a flow or items
    if (!flow.current || !items.length) return

    updateListItemStatus()
  }, [status, flow.current, items.length])

  /**
   * When the items change, check if they are all complete
   */
  useEffect(() => {
    // If there's no flow, exit
    if (!flow.current || !items.length) return

    // If any of the items are no complete, exit
    if (isAllItemsComplete) {
      setTitle(t('campaignDelayedChecklistCompletedTitle'))
      setDescription('')
    }
  }, [items])

  if (items.length <= 0) {
    return null
  }

  return (
    <Layout.Section>
      <Checklist title={title} description={description} items={items} onDismiss={onDismiss} />
    </Layout.Section>
  )
}

export { CampaignFulfillmentDelayedQueue }
