import { Controller, useFormContext } from 'react-hook-form'

import { Card, TextField, BlockStack, FormLayout } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions.tsx'

const Labels = () => {
  const { t } = useTranslation()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useFormContext()

  const dateFormat = watch('dateFormat')
  const dateLocale = watch('dateLocale')

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader title={t('localisationLabelsCardTitle')} description={t('localisationLabelsCardDescription')} />
        <FormLayout>
          <Controller
            name="purchaseOptionLabel"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldPurchaseOptionLabel')}
                helpText={t('localisationFieldPurchaseOptionLabelHelp')}
                placeholder={t('localisationFieldPurchaseOptionLabelPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="preorderLabel"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldPreorderLabel')}
                helpText={t('localisationFieldPreorderLabelHelp')}
                placeholder={t('localisationFieldPreorderLabelPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="backorderLabel"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldBackorderLabel')}
                helpText={t('localisationFieldBackorderLabelHelp')}
                placeholder={t('localisationFieldBackorderLabelPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="expectedShipDatePrefix"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldExpectedShipDatePrefix')}
                helpText={t('localisationFieldExpectedShipDatePrefixHelp', {
                  date: dayjs().tz(settings.timezone).locale(dateLocale).format(dateFormat),
                })}
                placeholder={t('localisationFieldExpectedShipDatePrefixPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="quickAddButtonCta"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldQuickAddButtonCta')}
                helpText={t('localisationFieldQuickAddButtonCtaHelp')}
                placeholder={t('localisationFieldQuickAddButtonCtaPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="discountPrefix"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldDiscountPrefix')}
                helpText={t('localisationFieldDiscountPrefixHelp')}
                placeholder={t('localisationFieldDiscountPrefixPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="asapLabel"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldAsapLabel')}
                helpText={t('localisationFieldAsapLabelHelp')}
                placeholder={t('localisationFieldAsapLabelPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="unknownLabel"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('localisationFieldUnknownLabel')}
                helpText={t('localisationFieldUnknownLabelHelp')}
                placeholder={t('localisationFieldUnknownLabelPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? undefined}
                maxLength={256}
                disabled={isSubmitting}
                error={error?.message}
              />
            )}
          />
        </FormLayout>
      </BlockStack>
    </Card>
  )
}

export { Labels }
