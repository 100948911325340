import { Controller, useFormContext } from 'react-hook-form'
import { FormLayout, TextField, BlockStack } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const ComingSoonWidgetSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <BlockStack gap="400">
      <FormLayout>
        <BlockStack gap="200">
          <Controller
            name="comingSoonAddToCartButtonText"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('displayComingSoonButtonLabel')}
                placeholder={t('displayComingSoonButtonPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="comingSoonNote"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('displayComingSoonNoteLabel')}
                value={value ?? ''}
                onChange={onChange}
                multiline={2}
                error={error?.message}
              />
            )}
          />
        </BlockStack>
      </FormLayout>
    </BlockStack>
  )
}

export { ComingSoonWidgetSettings }
