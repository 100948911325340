import { Badge, Icon, Tooltip } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { AlertTriangleIcon, InfoIcon } from '@shopify/polaris-icons'

const RestockStatusBadge = ({
  status,
  errorCode,
  isAutomaticSend,
  nextBatchScheduledAt,
}: {
  status: 'ERROR' | 'COMPLETE' | 'SENDING' | 'SCANNING' | 'WAITING_NEXT_BATCH' | null
  errorCode: string | null
  isAutomaticSend: boolean
  nextBatchScheduledAt?: string | null
}) => {
  const { t } = useTranslation()

  let label: string
  let tone: 'info' | 'success' | 'warning' | 'critical' | undefined
  let tooltip: string | null = null

  switch (status) {
    case 'SCANNING':
    case 'SENDING':
      label = t('backInStockRestockTableStatusSending')
      tone = 'info'
      break
    case 'WAITING_NEXT_BATCH':
      label = t('backInStockRestockTableStatusWaitingNextBatch')
      tooltip = nextBatchScheduledAt
        ? t('backInStockRestockTableWaitingNextBatchTooltip', {
            date: nextBatchScheduledAt,
          })
        : null
      tone = undefined
      break
    case 'COMPLETE':
      label = t('backInStockRestockTableStatusComplete')
      tone = undefined
      break
    case 'ERROR':
      label = t('backInStockRestockTableStatusError')
      tone = 'critical'
      break
    default:
      label = ''
      tone = undefined
  }

  if (status === 'ERROR') {
    switch (errorCode) {
      case 'EXCEEDED_USAGE':
        tooltip = t('backInStockRestockTableErrorCodeExceededUsage')
        break
      case 'PARTIAL_EXCEEDED_USAGE':
        tooltip = t('backInStockRestockTableErrorCodePartialExceededUsage')
        break
      case 'GENERIC':
      default:
        tooltip = t('backInStockRestockTableErrorCodeGeneric')
        break
    }
  } else if (status === 'COMPLETE') {
    switch (errorCode) {
      case 'NON_ACTIVE_CAMPAIGN':
        tooltip = t('backInStockRestockTableErrorCodeNonActiveCampaign')
        break
      case 'NO_VARIANTS_IN_STOCK':
        tooltip = isAutomaticSend ? t('backInStockRestockTableErrorCodeNoStockAuto') : t('backInStockRestockTableErrorCodeNoStockManual')
        break
      case 'NO_CONTACTS_WAITING':
        tooltip = t('backInStockRestockTableErrorCodeNoContacts')
        break
      default:
        tooltip = null
        break
    }
  }

  return (
    <>
      {label ? <Badge tone={tone}>{label}</Badge> : null}
      {tooltip ? (
        <div className="inlineTableIcon">
          <Tooltip content={tooltip}>
            <Icon
              source={status === 'WAITING_NEXT_BATCH' || status === 'COMPLETE' ? InfoIcon : AlertTriangleIcon}
              tone={status === 'WAITING_NEXT_BATCH' || status === 'COMPLETE' ? 'base' : 'critical'}
              accessibilityLabel={tooltip}
            />
          </Tooltip>
        </div>
      ) : null}
    </>
  )
}

export { RestockStatusBadge }
