import { Controller, useFormContext } from 'react-hook-form'

import { Card, BlockStack, Select, InlineGrid } from '@shopify/polaris'

import { DatePickerField } from '@/components/shared/DatePickerField'
import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'
import { useEffect } from 'react'
import { Disabled } from '../shared/Disabled'
import { TimeSelectField } from '../shared/TimeSelectField'

const CampaignFulfillment = () => {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const fulfillmentTrigger = watch('fulfillmentTrigger')
  const preorderTrigger = watch('preorderTrigger')

  useEffect(() => {
    if (preorderTrigger === 'IN_STOCK') {
      setValue('inventoryReserve', 'ON_SALE')
    }
  }, [preorderTrigger])

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader title={t('campaignFulfillmentCardTitle')} />
        <Controller
          name="fulfillmentTrigger"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              label={t('campaignFieldFulfillmentTrigger')}
              helpText={t('campaignFieldFulfillmentTriggerDescription')}
              options={[
                {
                  label: t('campaignFieldFulfillmentTriggerExactTime'),
                  value: 'EXACT_TIME',
                },
                {
                  label: t('campaignFieldFulfillmentTriggerAsap'),
                  value: 'ASAP',
                },
                {
                  label: t('campaignFieldFulfillmentTriggerUnknown'),
                  value: 'UNKNOWN',
                },
              ]}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        {fulfillmentTrigger === 'EXACT_TIME' && (
          <Controller
            name="fulfillmentDate"
            control={control}
            render={({ field, fieldState: { error }, formState: {} }) => {
              return (
                <>
                  <InlineGrid columns={2} gap="400">
                    <DatePickerField
                      label={t('campaignFieldFulfillmentDate')}
                      date={field.value}
                      displayTimezone={settings.timezone}
                      onChange={(val) => field.onChange(val)}
                      error={error}
                    />
                    <TimeSelectField
                      label={t('campaignFieldFulfillmentTime')}
                      date={field.value}
                      displayTimezone={settings.timezone}
                      onChange={(val) => field.onChange(val)}
                      disabled={!field.value}
                    />
                  </InlineGrid>
                </>
              )
            }}
          />
        )}
        <Disabled isDisabled={preorderTrigger === 'IN_STOCK'} message={t('campaignFieldInventoryReserveDisabledBanner')}>
          <Controller
            name="inventoryReserve"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label={t('campaignFieldInventoryReserve')}
                options={[
                  {
                    label: t('campaignFieldInventoryReserveOnSale'),
                    value: 'ON_SALE',
                  },
                  {
                    label: t('campaignFieldInventoryReserveOnFulfillment'),
                    value: 'ON_FULFILLMENT',
                  },
                ]}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Disabled>
      </BlockStack>
    </Card>
  )
}

export { CampaignFulfillment }
