import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'
import { useI18n } from '@shopify/react-i18n'

import { bisListContactQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Banner, Box, Card, Layout, SkeletonBodyText, SkeletonPage } from '@shopify/polaris'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { useSuspenseQuery } from '@tanstack/react-query'
import { BackInStockEmailEventsTable } from '@/components/backInStock/BackInStockEmailEventsTable'
import { EmailPreview } from '@/components/shared/EmailPreview'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { ContactBisStatusBadge } from '@/components/shared/ContactBisStatusBadge'
import { MenuActionDescriptor } from '@shopify/polaris/build/ts/src/types'
import { useMutateRemoveContact } from '@/hooks/useMutateRemoveContact'

export const Route = createFileRoute('/back-in-stock/products/$productId/contacts/$contactId')({
  loader: async ({ params: { productId, contactId }, context: { queryClient }, route: { path } }) => {
    console.log([productId, contactId])
    try {
      queryClient.ensureQueryData(settingsQueryOptions())
      queryClient.ensureQueryData(bisListContactQueryOptions({ id: contactId }))
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const [i18n] = useI18n()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productId, contactId } = useParams({ strict: false }) as { productId: string; contactId: string }

  const { data } = useSuspenseQuery(bisListContactQueryOptions({ id: contactId }))
  const removeContact = useMutateRemoveContact()

  const customerName = data.customer?.firstName ? `${data.customer.firstName} ${data.customer?.lastName}` : data.customer?.email
  const isEmailSent = !!data.email_send
  const isOrderPlaced = !!data.bis
  const shopifyOrderUrl = isOrderPlaced ? `shopify://admin/orders/${data.bis.orderId.split('/').pop()}` : ''
  const shopifyCustomerUrl = `shopify://admin/customers/${data.customer.id.split('/').pop()}`

  let primaryActions = undefined
  const secondaryActions: MenuActionDescriptor[] | React.ReactNode = [
    {
      content: t('backInStockEditCustomerCta'),
      disabled: !data?.customer,
      url: shopifyCustomerUrl,
      target: '_blank',
    },
  ]

  if (!isEmailSent && !isOrderPlaced) {
    secondaryActions.push({
      content: t('backInStockContactDeleteCta'),
      destructive: true,
      disabled: removeContact.isPending,
      loading: removeContact.isPending,
      onAction: async () => {
        await removeContact.mutateAsync({ id: contactId })
        navigate({ to: `/back-in-stock/products/${productId}` })
      },
    })
  }

  if (shopifyOrderUrl) {
    primaryActions = {
      content: t('backInStockContactConvertedCta'),
      url: shopifyOrderUrl,
    }
  }

  return (
    <PageFrame
      title={customerName}
      titleMetadata={
        <>
          <ContactBisStatusBadge status={data.email_send?.status ?? null} />
        </>
      }
      backAction={{
        content: t('backInStockDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({
            to: `/back-in-stock/products/$productId`,
            params: { productId },
          })
        },
      }}
      primaryAction={primaryActions}
      secondaryActions={secondaryActions}
    >
      {isOrderPlaced && (
        <Layout.Section>
          <Banner
            tone="success"
            title={t('backInStockContactConvertedTitle', { name: customerName })}
            action={
              shopifyOrderUrl
                ? {
                    content: t('backInStockContactConvertedCta'),
                    url: shopifyOrderUrl,
                  }
                : undefined
            }
          >
            <p>
              {data.bis?.amount
                ? t('backInStockContactConvertedDescriptionWithAmount', {
                    amount: i18n.formatCurrency(data.bis.amount ?? 0, {
                      currency: data.bis.currency ?? 'USD',
                    }),
                  })
                : t('backInStockContactConvertedDescription')}
            </p>
          </Banner>
        </Layout.Section>
      )}
      {isEmailSent ? (
        <>
          <Layout.Section variant="oneHalf">
            <SectionTitle title={t('backInStockEmailEventsTableTitle')} description={t('backInStockEmailEventsTableSubtitle')} />
            <BackInStockEmailEventsTable contactId={contactId} />
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <SectionTitle title={t('backInStockEmailPreviewTitle')} description={t('backInStockEmailPreviewSubtitle')} />
            <Card>
              <EmailPreview
                subject={data.email_send?.apiData?.subject ?? ''}
                htmlContent={data.email_send?.apiData?.html ?? ''}
                from={data.email_send?.apiData?.from ?? undefined}
                to={data.email_send?.apiData?.to && data.email_send?.apiData?.to.length ? data.email_send?.apiData?.to[0] : undefined}
                replyTo={data.email_send?.apiData?.reply_to && data.email_send?.apiData?.reply_to.length ? data.email_send?.apiData?.reply_to[0] : undefined}
              />
            </Card>
          </Layout.Section>
        </>
      ) : (
        <Layout.Section>
          <Banner tone="info">
            <p>{t('backInStockContactNoEmailDescription')}</p>
          </Banner>
        </Layout.Section>
      )}
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()
  return (
    <SkeletonPage title={t('genericLoading')} primaryAction backAction>
      <Layout>
        <Layout.Section variant="oneHalf">
          <SectionTitle title={t('backInStockEmailEventsTableTitle')} description={t('backInStockEmailEventsTableSubtitle')} />
          <SkeletonTable rows={3} narrow={true} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <SectionTitle title={t('backInStockEmailPreviewTitle')} description={t('backInStockEmailPreviewSubtitle')} />
          <Card>
            <Box borderColor="border-secondary" borderWidth="025">
              <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                <SkeletonBodyText lines={3} />
              </Box>
              <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                <SkeletonBodyText lines={1} />
              </Box>
              <Box padding="400">
                <Box minHeight="400px" background="bg-fill-tertiary" />
              </Box>
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
