import { SectionTitle } from '@/components/shared/SectionTitle'
import { BlockStack, Box, Card, Grid, InlineStack, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import dayjs from '@/common/datetime'

const PlansFallback = () => {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('appPlansTitle')}>
      <Layout>
        <Layout.Section>
          <SectionTitle
            title={t('usageStatsTitle')}
            description={t('usageStatsSubtitle', {
              from: dayjs.utc().startOf('month').format('MMMM D'),
              to: dayjs.utc().endOf('month').format('MMMM D'),
            })}
          />
          <Card padding="400">
            <InlineStack gap="400" blockAlign="stretch" align="space-between">
              <InlineStack gap="400" blockAlign="center">
                <Box minWidth="80px">
                  <BlockStack gap="100">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Box>
                <Box minWidth="80px">
                  <BlockStack gap="100">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Box>
                <Box minWidth="160px">
                  <BlockStack gap="100">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Box>
              </InlineStack>
            </InlineStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Box paddingBlockEnd="200">
            <InlineStack align="space-between">
              <SectionTitle title={t('appPlansAvailableTitle')} description={t('appPlansAvailableSubtitle')} />
            </InlineStack>
          </Box>
          <Grid columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

export { PlansFallback }
