import { reportsBisVariantsQueryOptions, settingsQueryOptions, shopifyProductQueryOptions, shopifyProductVariantsQueryOptions } from '@/common/queryOptions'
import { Card, IndexFilters, IndexTable, useSetIndexFiltersMode, Text, IndexFiltersMode, InlineStack, Thumbnail } from '@shopify/polaris'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useState, useTransition } from 'react'
import { formatNumber, formatPrice } from '@/common/functions'
import { ConversionRatePercentage } from '../shared/ConversionRatePercentage'
import { ProductIcon } from '@shopify/polaris-icons'

const noop = () => {}

function BackInStockProductVariantsTable({ productId }: { productId: string }) {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const [isPending, startTransition] = useTransition()
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering)

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const {
    data: { data: products = [], hasNext, total },
  } = useSuspenseQuery(reportsBisVariantsQueryOptions({ id: productId }))

  const variantGids = products.flatMap((product) => (product.variantId ? [product.variantId] : [])) ?? []

  const {
    data: { nodes: shopifyProductVariants },
  } = useSuspenseQuery(shopifyProductVariantsQueryOptions({ variantGids }))

  const {
    data: { product: shopifyProduct },
  } = useSuspenseQuery(shopifyProductQueryOptions({ productId: `gid://shopify/Product/${productId}` }))

  const rows: TableRowProps[] =
    shopifyProductVariants
      ?.map((variant, index: number) => {
        const variantRow = products.find((p) => p.variantId === variant.id)
        if (variantRow) {
          return {
            ...variantRow,
            title: variant.title,
            index,
            rowType: 'data',
            image: variant.image?.url,
          }
        }
        return null
      })
      .filter((row): row is TableRowProps => row !== null) ?? []

  const productRow = products.find((p) => p.variantId === null)

  if (productRow) {
    rows.unshift({
      ...productRow,
      title: t('backInStockProductVariantsTableAnyVariant'),
      index: -1,
      rowType: 'data',
      image: shopifyProduct.featuredImage?.url,
    })
  }

  return (
    <Card padding="0">
      <IndexFilters
        selected={0}
        onQueryChange={noop}
        onQueryClear={noop}
        canCreateNewView={false}
        filters={[]}
        onClearAll={() => {}}
        mode={mode}
        setMode={setMode}
        loading={isPending}
        hideFilters
        hideQueryField
        tabs={[]}
        disableStickyMode
      />
      <IndexTable
        selectable={false}
        resourceName={{ singular: t('campaignsTableResourceName'), plural: t('campaignsTableResourceNamePlural') }}
        itemCount={total}
        headings={[
          { title: t('backInStockProductTableFieldVariant') },
          { title: t('backInStockProductTableFieldSignups') },
          {
            title: t('backInStockProductTableFieldConversionRate'),
            tooltipWidth: 'wide',
            tooltipContent: t('backInStockConversionRateTooltip'),
            alignment: 'end',
          },
          {
            title: t('backInStockProductTableFieldRevenue', {
              currency: settings.currency,
            }),
            alignment: 'end',
          },
        ]}
        hasMoreItems={hasNext}
        pagination={{
          hasNext,
          onNext: () => startTransition(() => setCurrentPage((prev) => prev + 1)),
          hasPrevious: currentPage > 1,
          onPrevious: () => startTransition(() => setCurrentPage((prev) => prev - 1)),
        }}
      >
        {rows.map(({ variantId, title, signups, waiting, sent, totalAmount, conversionRate, index, image, rowType }) => (
          <IndexTable.Row id={variantId} key={variantId} position={index} rowType={rowType}>
            <IndexTable.Cell className="wrap-text">
              <InlineStack blockAlign="center" gap="200" wrap={false}>
                <Thumbnail source={image ?? ProductIcon} size="small" alt="Small document" />
                <Text as="span" fontWeight="medium">
                  {title}
                </Text>
              </InlineStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p">
                {t('backInStockProductTableContactsCount', {
                  count: signups,
                  signups: formatNumber(signups),
                })}
              </Text>
              <Text as="span" variant="bodyXs" tone="subdued">
                {t('backInStockProductTableWaitingCount', {
                  waiting: formatNumber(waiting),
                })}
              </Text>{' '}
              <Text as="span" variant="bodyXs" tone="subdued">
                /
              </Text>{' '}
              <Text as="span" variant="bodyXs" tone="subdued">
                {t('backInStockProductTableSentCount', {
                  sent: formatNumber(sent),
                })}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="span" alignment="end">
                <ConversionRatePercentage conversionRate={conversionRate} />
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="span" alignment="end" numeric>
                {formatPrice(totalAmount, settings.currency)}
              </Text>
            </IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>
    </Card>
  )
}

interface TableRowProps {
  productId?: string
  variantId: string
  title: string
  signups: number
  waiting: number
  sent: number
  totalAmount: number
  conversionRate: number
  index: number
  image: string
  rowType: 'data' | 'child'
}

export { BackInStockProductVariantsTable }
