import { buildAPIUrl } from '@/common/functions'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export interface BisTriggerRestockQueryParams {
  id: string
}

export interface BisTriggerRestockQueryResponse {
  data: {
    uuid: string
    shopId: string
    productId: string
    isAutomaticSend: boolean
    status: 'ERROR' | 'COMPLETE' | 'SENDING' | 'SCANNING' | 'WAITING_NEXT_BATCH'
    lastError: string | null
    currentSendCount: number
    completedAt: string
    createdAt: string
  }[]
  total: number
  hasNext: boolean
}

export const useMutateBisRestock = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const searchParams = new URLSearchParams()

  return useMutation<BisTriggerRestockQueryResponse, unknown, BisTriggerRestockQueryParams>({
    mutationFn: () => {
      searchParams.set('id', id)
      const requestURL = buildAPIUrl('bisRestocks/restock', searchParams)

      return fetch(requestURL, {
        body: JSON.stringify({
          id,
        }),
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            shopify.toast.show(t('backInStockRestockStartedToast'), {
              duration: 2000,
            })

            return res.json()
          } else {
            shopify.toast.show(t('genericErrorToast'), {
              duration: 2000,
              isError: true,
            })
            throw 'Error triggering BIS restock'
          }
        })
        .then((res) => res.message)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'bisRestocks',
      })
    },
  })
}
