import { buildAPIUrl } from '@/common/functions'
import { emailTemplatesQueryOptions } from '@/common/queryOptions'
import { FetchEmailTemplatesResponse, MutateEmailTemplatesPayload } from '@/common/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useMutateEmailTemplates = () => {
  const queryClient = useQueryClient()

  const requestURL = buildAPIUrl('emailTemplates')

  return useMutation<FetchEmailTemplatesResponse, unknown, MutateEmailTemplatesPayload>({
    mutationFn: (data) =>
      fetch(requestURL, {
        body: JSON.stringify(data),
        method: 'PUT',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error updating emails'
          }
        })
        .then((res) => res.message),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: emailTemplatesQueryOptions().queryKey,
      })
    },
  })
}
