import { buildAPIUrl } from '@/common/functions'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// TODO: This is the only place we import the type like this
import type { CampaignFormFieldsWithDeleted } from '@/components/preorders/CampaignForm'

export const useMutateCampaign = (uuid?: string) => {
  const queryClient = useQueryClient()
  const searchParams = new URLSearchParams()
  if (uuid) searchParams.set('id', uuid)

  const requestURL = buildAPIUrl('campaign', searchParams)

  return useMutation({
    mutationFn: (data: Partial<CampaignFormFieldsWithDeleted> = {}) => {
      const payload = uuid ? { ...data, uuid } : data

      return fetch(requestURL, {
        body: JSON.stringify(payload),
        method: uuid ? 'PUT' : 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error updating campaign'
          }
        })
        .then((res) => res.message)
    },
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: ['campaign'],
      })

      // if (uuid) {
      //   queryClient.invalidateQueries({
      //     queryKey: ['campaign', { uuid }],
      //     exact: true,
      //   })
      // }
    },
  })
}
