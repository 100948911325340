import { BlockStack, Card, Text, InlineStack, Box, Button, Badge, ButtonGroup } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { AppPlan } from '@/common/types'
import { formatNumber, formatPrice, getBasePlanName } from '@/common/functions.ts'

export const PlanCard = ({
  plan,
  isCurrent,
  button,
  frequency,
}: {
  plan: AppPlan
  isCurrent: boolean
  button: {
    content: string
    props: {
      variant: 'primary' | 'secondary'
      onClick: () => void
      loading?: boolean
      disabled?: boolean
    }
  }
  frequency: string
}) => {
  const { t } = useTranslation()

  const planName = getBasePlanName(plan.name)

  const title = t(`appPlans${planName}Title`)
  const description = t(`appPlans${planName}Description`)
  const priceFormatted = formatPrice(plan.price, 'USD')

  const monthlyOrderLimit = plan.monthlyOrderLimit !== null ? formatNumber(plan.monthlyOrderLimit) : t('genericUnlimited')
  const monthlyEmailLimit = plan.monthlyEmailLimit !== null ? formatNumber(plan.monthlyEmailLimit) : t('genericUnlimited')

  return (
    <div
      style={{
        borderRadius: '.75rem',
        position: 'relative',
        zIndex: '0',
      }}
    >
      {title === 'Launch' && (
        <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '100' }}>
          <Badge size="large" tone="success">
            {t('appPlansPopularPlan')}
          </Badge>
        </div>
      )}
      <Box borderColor={isCurrent ? 'border-emphasis' : undefined} borderWidth={isCurrent ? '050' : undefined} borderRadius={isCurrent ? '300' : undefined}>
        <Card>
          <BlockStack gap="400">
            <BlockStack gap="200" align="start">
              <Text as="h3" variant="headingLg">
                {title}
              </Text>
              {description ? (
                <Text as="p" variant="bodySm" tone="subdued">
                  {description}
                </Text>
              ) : null}
            </BlockStack>

            <InlineStack blockAlign="end" gap="100" align="start">
              <Text as="h2" variant="headingXl">
                {priceFormatted}
              </Text>
              <Box paddingBlockEnd="200">
                <Text as="p" variant="bodySm">
                  / {frequency}
                </Text>
              </Box>
            </InlineStack>

            <BlockStack gap="100">
              <Text tone="subdued" as="p" variant="bodyMd">
                {t('appPlansPreordersCount', {
                  limit: monthlyOrderLimit,
                })}
              </Text>
              <Text tone="subdued" as="p" variant="bodyMd">
                {t('appPlansEmailsCount', {
                  limit: monthlyEmailLimit,
                })}
              </Text>

              {plan.key === 'FREE' && (
                <>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansFreeFeature1')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansFreeFeature2')}
                  </Text>
                </>
              )}
              {plan.key === 'LAUNCH' && (
                <>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansLaunchFeature1')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansLaunchFeature2')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansLaunchFeature3')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansLaunchFeature4')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansLaunchFeature5')}
                  </Text>
                </>
              )}

              {plan.key === 'GROW' && (
                <>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansGrowFeature1')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansGrowFeature2')}
                  </Text>
                </>
              )}

              {plan.key === 'SCALE' && (
                <>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansScaleFeature1')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansScaleFeature2')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansScaleFeature3')}
                  </Text>
                </>
              )}

              {plan.key === 'PARTNER' && (
                <>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansPartnerFeature1')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansPartnerFeature2')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansPartnerFeature3')}
                  </Text>
                  <Text tone="subdued" as="p" variant="bodyMd">
                    {t('appPlansPartnerFeature4')}
                  </Text>
                </>
              )}
            </BlockStack>

            <Box paddingBlockStart="200">
              {!isCurrent ? (
                <ButtonGroup fullWidth>
                  <Button {...button.props}>{button.content}</Button>
                </ButtonGroup>
              ) : (
                <ButtonGroup fullWidth>
                  <Button variant="secondary" disabled>
                    {t('appPlansCurrentPlan')}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </BlockStack>
        </Card>
      </Box>
    </div>
  )
}
