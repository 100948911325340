import { Text, BlockStack, InlineStack, Box } from '@shopify/polaris'

type Props = {
  title: string
  description?: string
}

const SectionTitle = ({ title, description }: Props) => {
  return (
    <Box
      padding="0"
      paddingBlockEnd="300"
      paddingInline={{
        xs: '400',
        sm: '0',
        md: '0',
        lg: '0',
        xl: '0',
      }}
    >
      <BlockStack>
        <InlineStack gap="200" blockAlign="center">
          <Text as="h2" variant="headingSm">
            {title}
          </Text>
        </InlineStack>
        {description && (
          <Text as="p" variant="bodyMd" tone="subdued">
            {description}
          </Text>
        )}
      </BlockStack>
    </Box>
  )
}

export { SectionTitle }
