import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormLayout, Card, ChoiceList } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import { Disabled } from '@/components/shared/Disabled'

const ProductSettings = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext()

  const backorderEnabled = watch('backorderEnabled')

  return (
    <Card>
      <CardHeader title={t('backorderSelectionTypeCardTitle')} description={t('backorderSelectionTypeCardDescription')} />
      <Box paddingBlockStart="300">
        <Disabled isDisabled={backorderEnabled} message={t('backorderFieldSelectionTypeDisabledBanner')}>
          <FormLayout>
            <Controller
              name="backorderSelectionType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ChoiceList
                  title=""
                  choices={[
                    { label: t('backorderFieldSelectionTypeExclude'), value: 'EXCLUDE' },
                    { label: t('backorderFieldSelectionTypeInclude'), value: 'INCLUDE' },
                  ]}
                  onChange={(values) => onChange(values[0])}
                  selected={[value]}
                />
              )}
            />
          </FormLayout>
        </Disabled>
      </Box>
    </Card>
  )
}

export { ProductSettings }
