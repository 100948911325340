import { BlockStack, Box, Card, Grid, Layout, SkeletonBodyText, SkeletonDisplayText, Tabs } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { PreorderWidgetPreview } from './PreorderWidgetPreview'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { ComingSoonWidgetSettings } from '@/components/display/ComingSoonWidgetSettings'
import { ComingSoonWidgetPreview } from '@/components/display/ComingSoonWidgetPreview'
import { SoldOutWidgetPreview } from '@/components/display/SoldOutWidgetPreview'
import { SoldOutWidgetSettings } from '@/components/display/SoldOutWidgetSettings'
import { useState } from 'react'
import { PreorderWidgetSettings } from './PreorderWidgetSettings'

const PreorderWidget = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)

  const TABS = [
    { id: '0', content: t('displayPreorderTabPreorder') },
    { id: '1', content: t('displayPreorderTabComingSoon') },
    { id: '2', content: t('displayPreorderTabSoldOut') },
  ]

  return (
    <Layout.Section>
      <Box paddingBlockStart="200" paddingBlockEnd="400" paddingInline="0">
        <SectionTitle title={t('displayPreorderTitle')} description={t('displayPreorderDescription')} />
        <BlockStack gap="400">
          <Grid columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
              <Card padding="0">
                <Tabs tabs={TABS} selected={activeTab} onSelect={setActiveTab} fitted></Tabs>
                <Box padding="400">
                  <Disabled isDisabled={isDisabled}>
                    {activeTab === 0 && <PreorderWidgetSettings />}
                    {activeTab === 1 && <ComingSoonWidgetSettings />}
                    {activeTab === 2 && <SoldOutWidgetSettings />}
                  </Disabled>
                </Box>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
              <Card padding="0">
                <Box padding="400">
                  <div
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    <Box>
                      <SkeletonDisplayText size="small" />
                      <Box minHeight="10px" />
                      <Box width="80px">
                        <SkeletonBodyText lines={1} />
                      </Box>
                      <Box minHeight="20px" />
                      <SkeletonBodyText lines={3} />
                      <Box minHeight="20px" />
                      {activeTab === 0 && <PreorderWidgetPreview />}
                      {activeTab === 1 && <ComingSoonWidgetPreview />}
                      {activeTab === 2 && <SoldOutWidgetPreview />}
                    </Box>
                  </div>
                </Box>
              </Card>
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </Box>
    </Layout.Section>
  )
}

export { PreorderWidget }
