import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { campaignReportsOrdersQueryOptions, campaignQueryOptions, settingsQueryOptions } from '@/common/queryOptions'

import { Button, Layout } from '@shopify/polaris'
import { CampaignChart } from '@/components/preorders/CampaignChart'
import { CampaignTable } from '@/components/preorders/CampaignTable'
import { CampaignsFallback } from '@/components/preorders/CampaignsFallback'

export const Route = createFileRoute('/preorders/')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(
          campaignQueryOptions({
            status: undefined,
            page: 1,
          })
        ),
        queryClient.ensureQueryData(
          campaignReportsOrdersQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
      ])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <CampaignsFallback />
  },
  component: Index,
})

function Index() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <PageFrame
      title={t('campaignsTitle')}
      subtitle={t('campaignsSubtitle')}
      primaryAction={
        <Button
          variant="primary"
          onClick={() => {
            navigate({ to: '/preorders/create' })
          }}
        >
          {t('campaignsCta')}
        </Button>
      }
    >
      <Layout.Section>
        <CampaignChart />
      </Layout.Section>
      <Layout.Section>
        <CampaignTable />
      </Layout.Section>
    </PageFrame>
  )
}
