import { Tooltip, Icon } from '@shopify/polaris'
import { CheckCircleIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

const EmailConvertedIcon = ({ orderId }: { orderId: string | null }) => {
  const { t } = useTranslation()

  if (!orderId) {
    return
  }

  const tooltip = t('backInStockStatusConvertedTooltip', {
    orderId,
  })

  return (
    <div className="inlineTableIcon">
      <Tooltip content={tooltip}>
        <Icon source={CheckCircleIcon} tone="success" accessibilityLabel={tooltip} />
      </Tooltip>
    </div>
  )
}

export { EmailConvertedIcon }
