import { Box, Text } from '@shopify/polaris'
import { useFormContext } from 'react-hook-form'

const ComingSoonWidgetPreview = () => {
  const { watch } = useFormContext()

  const comingSoonAddToCartButtonText = watch('comingSoonAddToCartButtonText')
  const addToCartButtonTextColor = watch('addToCartButtonTextColor')
  const addToCartButtonBackgroundColor = watch('addToCartButtonBackgroundColor')
  const comingSoonNote = watch('comingSoonNote')

  return (
    <>
      {comingSoonNote && (
        <>
          <Box minHeight="10px" />
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <Text as="p">{comingSoonNote}</Text>
          </div>
        </>
      )}
      <Box minHeight="20px" />
      <div
        style={{
          cursor: 'default',
          padding: '0.8rem',
          border: addToCartButtonBackgroundColor ? undefined : '1px solid #000',
          backgroundColor: addToCartButtonBackgroundColor ?? '#ffffff',
          color: addToCartButtonTextColor ?? '#000000',
          opacity: 0.5,
        }}
      >
        <Text as="span" variant="bodyLg" alignment="center">
          {comingSoonAddToCartButtonText ? comingSoonAddToCartButtonText : 'Coming soon'}
        </Text>
      </div>
    </>
  )
}

export { ComingSoonWidgetPreview }
