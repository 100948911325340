import { SearchSchemaInput, createFileRoute, redirect } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import {
  settingsCheckAppEmbedQueryOptions,
  reportsBackordersQueryOptions,
  reportsBisListQueryOptions,
  campaignBySellingPlanIdQueryOptions,
  campaignReportsOrdersQueryOptions,
  checkoutProfilesQueryOptions,
  settingsQueryOptions,
  reportsUsageQueryOptions,
  campaignQueryOptions,
} from '@/common/queryOptions'
import dayjs from '@/common/datetime'

import { PageFrame } from '@/components/shared/PageFrame'
import { UsageStats } from '@/components/shared/UsageStats'
import { CampaignMetrics } from '@/components/dashboard/CampaignMetrics'
import { ThingsToDo } from '@/components/dashboard/ThingsToDo'
import { AppEmbedBlock } from '@/components/dashboard/AppEmbedBlock'
import { LegacyThemeBanner } from '@/components/dashboard/LegacyThemeBanner'
import { BlockStack, Box, Card, InlineGrid, InlineStack, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Spinner, Text } from '@shopify/polaris'
import { SectionTitle } from '@/components/shared/SectionTitle'

export const Route = createFileRoute('/')({
  validateSearch: (search: { sellingPlanGroupId?: string } & SearchSchemaInput) => {
    // validate and parse the search params into a typed state
    return {
      sellingPlanGroupId: search?.sellingPlanGroupId,
    }
  },
  beforeLoad: async ({ search }) => {
    return {
      search,
    }
  },
  loader: async ({ context: { queryClient, search }, route: { path } }) => {
    try {
      if (search.sellingPlanGroupId) {
        const campaign = await queryClient.ensureQueryData(campaignBySellingPlanIdQueryOptions({ sellingPlanGroupId: search.sellingPlanGroupId }))
        throw redirect({
          to: '/preorders/$uuid/settings',
          params: {
            uuid: campaign.uuid,
          },
        })
      }

      Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(reportsUsageQueryOptions()),
        queryClient.ensureQueryData(
          campaignReportsOrdersQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
        queryClient.ensureQueryData(
          reportsBackordersQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
        queryClient.ensureQueryData(
          reportsBisListQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
        queryClient.ensureQueryData(campaignQueryOptions()),
        queryClient.ensureQueryData(settingsCheckAppEmbedQueryOptions()),
        queryClient.ensureQueryData(checkoutProfilesQueryOptions()),
      ])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()

  return (
    <PageFrame title={t('dashTitle')} subtitle={t('dashSubtitle')}>
      <AppEmbedBlock />
      <LegacyThemeBanner />
      <UsageStats />
      <CampaignMetrics />
      <ThingsToDo />
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('dashTitle')}>
      <Layout>
        <Layout.Section>
          <SectionTitle
            title={t('usageStatsTitle')}
            description={t('usageStatsSubtitle', {
              from: dayjs.utc().startOf('month').format('MMMM D'),
              to: dayjs.utc().endOf('month').format('MMMM D'),
            })}
          />
          <Card padding="400">
            <InlineStack gap="400" blockAlign="stretch" align="space-between">
              <InlineStack gap="400" blockAlign="center">
                <Box minWidth="80px">
                  <BlockStack gap="100">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Box>
                <Box minWidth="80px">
                  <BlockStack gap="100">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Box>
                <Box minWidth="160px">
                  <BlockStack gap="100">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Box>
              </InlineStack>
            </InlineStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('keyMetricsTitle')} description={t('keyMetricsSubtitle')} />
          <InlineGrid
            gap="400"
            columns={{
              xs: 1,
              sm: 2,
              md: 2,
              lg: 4,
            }}
          >
            <Card>
              <Text as="h3" variant="bodyMd" fontWeight="medium">
                {t('keyMetricsBackorderRevenueTitle')}
              </Text>
              <SkeletonDisplayText size="large" />
              <Box paddingBlockStart="400" paddingBlockEnd="100">
                <InlineStack align="center" blockAlign="center">
                  <Box>
                    <Spinner size="small" />
                  </Box>
                </InlineStack>
              </Box>
            </Card>

            <Card>
              <Text as="h3" variant="bodyMd" fontWeight="medium">
                {t('keyMetricsPreorderRevenueTitle')}
              </Text>
              <SkeletonDisplayText size="large" />
              <Box paddingBlockStart="400" paddingBlockEnd="100">
                <InlineStack align="center" blockAlign="center">
                  <Box>
                    <Spinner size="small" />
                  </Box>
                </InlineStack>
              </Box>
            </Card>
            <Card>
              <Text as="h3" variant="bodyMd" fontWeight="medium">
                {t('keyMetricsBackInStockRevenueTitle')}
              </Text>
              <SkeletonDisplayText size="large" />
              <Box paddingBlockStart="400" paddingBlockEnd="100">
                <InlineStack align="center" blockAlign="center">
                  <Box>
                    <Spinner size="small" />
                  </Box>
                </InlineStack>
              </Box>
            </Card>
            <Card>
              <Text as="h3" variant="bodyMd" fontWeight="medium">
                {t('keyMetricsBackInStockTitle')}
              </Text>
              <SkeletonDisplayText size="large" />
              <Box paddingBlockStart="400" paddingBlockEnd="100">
                <InlineStack align="center" blockAlign="center">
                  <Box>
                    <Spinner size="small" />
                  </Box>
                </InlineStack>
              </Box>
            </Card>
          </InlineGrid>
        </Layout.Section>
        <Layout.Section>
          <SectionTitle title={t('thingsToDoTitle')} />
          <BlockStack gap="300">
            <Card padding="400">
              <BlockStack gap="300">
                <SkeletonDisplayText size="large" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card padding="400">
              <BlockStack gap="300">
                <SkeletonDisplayText size="large" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card padding="400">
              <BlockStack gap="300">
                <SkeletonDisplayText size="large" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card padding="400">
              <BlockStack gap="300">
                <SkeletonDisplayText size="large" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
