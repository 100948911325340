import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormLayout, Card, ChoiceList, Banner } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'

const InventoryPolicy = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext()

  const inventoryPolicy = watch('backorderIsContinueSellingManaged')

  return (
    <Card>
      <CardHeader title={t('backorderInventoryPolicyCardTitle')} description={t('backorderInventoryPolicyCardDescription')} />
      <Box paddingBlockStart="300">
        {!inventoryPolicy && (
          <Box paddingBlockEnd="300">
            <Banner tone="warning">
              <p>{t('backordersInventoryPolicyInlineWarning')}</p>
            </Banner>
          </Box>
        )}
        <FormLayout>
          <Controller
            name="backorderIsContinueSellingManaged"
            control={control}
            render={({ field: { onChange, value } }) => {
              const stringValue = value.toString()
              return (
                <ChoiceList
                  title=""
                  choices={[
                    { label: t('backorderFieldIsContinueSellingManagedNo'), value: 'false' },
                    { label: t('backorderFieldIsContinueSellingManagedYes'), value: 'true' },
                  ]}
                  onChange={(values) => onChange(values[0] === 'true')}
                  selected={[stringValue]}
                />
              )
            }}
          />
        </FormLayout>
      </Box>
    </Card>
  )
}

export { InventoryPolicy }
