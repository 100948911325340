import { FormLayout, TextField } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

const BisModalWidgetSettingsError = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <FormLayout>
      <Controller
        name="bisFormErrorMessage"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBisFormErrorMessageLabel')}
            placeholder={t('displayBisFormErrorMessagePlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error?.message}
          />
        )}
      />
    </FormLayout>
  )
}

export { BisModalWidgetSettingsError }
