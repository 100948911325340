// External libraries
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Layout, SkeletonPage } from '@shopify/polaris'

// Internal components
import { CampaignStatusBadge } from '@/components/shared/CampaignStatusBadge'
import { PageFrame } from '@/components/shared/PageFrame'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { CampaignUnitsSold } from '@/components/preorders/CampaignUnitsSold'

// Utility functions and configurations
import { campaignQueryOptions, reportsUnitsSoldQueryOptions } from '@/common/queryOptions'

export const Route = createFileRoute('/preorders/$uuid/units-sold')({
  loader: async ({ params: { uuid }, context: { queryClient }, route: { path } }) => {
    try {
      const campaignPromise = queryClient.ensureQueryData(campaignQueryOptions({ uuid }))
      const campaignUnitsSoldPromise = queryClient.ensureQueryData(reportsUnitsSoldQueryOptions({ uuid }))

      await Promise.all([campaignPromise, campaignUnitsSoldPromise])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { uuid } = useParams({ strict: false }) as { uuid: string }

  const {
    data: {
      data: [campaign],
    },
  } = useSuspenseQuery(campaignQueryOptions({ uuid: uuid }))

  return (
    <PageFrame
      title={campaign.name}
      subtitle={t('campaignDisplaySubtitle')}
      titleMetadata={<CampaignStatusBadge status={campaign.status} withProgress={false} />}
      backAction={{
        content: campaign.name,
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/preorders/$uuid`, params: { uuid } })
        },
      }}
    >
      <Layout.Section>
        <CampaignUnitsSold />
      </Layout.Section>
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('genericLoading')} backAction>
      <Layout>
        <Layout.Section>
          <SkeletonTable rows={5} />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
