import { queryOptions } from '@tanstack/react-query'
import {
  CampaignQueryParams,
  campaignQueryFunction,
  CampaignBySellingPlanIdQueryParams,
  campaignBySellingPlanIdQueryFunction,
  bisSettingQueryFunction,
  campaignReportsOrdersQueryFunction,
  campaignOrdersQueryFunction,
  reportsUnitsSoldQueryFunction,
  backordersQueryFunction,
  reportsBackordersQueryFunction,
  campaignUnavailableProductsQueryFunction,
  BackordersQueryParams,
  CampaignReportsOrdersQueryParams,
  CampaignOrdersQueryParams,
  ReportsBackordersQueryParams,
  checkoutProfilesQueryFunction,
  dnsQueryFunction,
  installStatusQueryFunction,
  appPlanQueryFunction,
  settingsQueryFunction,
  localisationQueryFunction,
  emailTemplatesQueryFunction,
  WidgetQueryParams,
  widgetQueryFunction,
  ShopifyResourcePickerQueryParams,
  BisSettingQueryParams,
  reportsBisProductsQueryFunction,
  ReportsBisProductsQueryParams,
  reportsBisVariantsQueryFunction,
  ReportsBisVariantsQueryParams,
  shopifyProductVariantsQueryFunction,
  ShopifyProductQueryParams,
  _shopifyProductQueryFunction,
  ShopifyProductVariantsQueryParams,
  shopifyResourcePickerQueryFunction,
  ShopifyGetProductsQueryParams,
  shopifyGetProductsQueryFunction,
  BisListQueryParams,
  bisListQueryFunction,
  BisRestocksQueryParams,
  bisRestocksQueryFunction,
  ReportsBisListQueryParams,
  reportsBisListQueryFunction,
  ReportsBisQueryParams,
  reportsBisQueryFunction,
  BisListContactQueryParams,
  bisListContactQueryFunction,
  reportsBisAggregateMetricsQueryFunction,
  ReportsBisAggregateMetricsQueryParams,
  reportsUsageQueryFunction,
  ReportsUnitsSoldQueryParams,
  CampaignUnavailableProductsQueryParams,
  settingsCheckAppEmbedQueryFunction,
  AppPlanQueryParams,
} from './queryFunctions'
import { QUERY_STALE_TIME } from './constants'
import { ResourcePickerProduct } from './types'

// Campaign Queries
export const campaignQueryOptions = (params?: CampaignQueryParams) =>
  queryOptions({
    queryKey: ['campaign', params],
    queryFn: () => campaignQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const campaignBySellingPlanIdQueryOptions = (params: CampaignBySellingPlanIdQueryParams) =>
  queryOptions({
    queryKey: ['campaign/bySellingPlanGroupId', params],
    queryFn: () => campaignBySellingPlanIdQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const campaignOrdersQueryOptions = (params?: CampaignOrdersQueryParams) =>
  queryOptions({
    queryKey: ['campaign/orders', params],
    queryFn: () => campaignOrdersQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const campaignReportsOrdersQueryOptions = (params?: CampaignReportsOrdersQueryParams) =>
  queryOptions({
    queryKey: ['campaign/reports/orders', params],
    queryFn: () => campaignReportsOrdersQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const reportsUnitsSoldQueryOptions = (params: ReportsUnitsSoldQueryParams) =>
  queryOptions({
    queryKey: ['reports/unitsSold', params],
    queryFn: () => reportsUnitsSoldQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const campaignUnavailableProductsQueryOptions = (params: CampaignUnavailableProductsQueryParams) =>
  queryOptions({
    queryKey: ['campaign/unavailableProducts', params],
    queryFn: () => campaignUnavailableProductsQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
    refetchOnMount: 'always',
  })

// Backorder Queries
export const backordersQueryOptions = (params?: BackordersQueryParams) =>
  queryOptions({
    queryKey: ['backorders', params],
    queryFn: () => backordersQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const reportsBackordersQueryOptions = (params?: ReportsBackordersQueryParams) =>
  queryOptions({
    queryKey: ['reports/backorders', params],
    queryFn: () => reportsBackordersQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

// Back in stock queries
export const bisSettingQueryOptions = (params: BisSettingQueryParams) => {
  return queryOptions({
    queryKey: ['bisSetting', params],
    queryFn: () => bisSettingQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })
}

export const reportsBisProductsQueryOptions = (params?: ReportsBisProductsQueryParams) => {
  return queryOptions({
    queryKey: ['reports/bisProducts', params],
    queryFn: () => reportsBisProductsQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })
}

export const reportsBisQueryOptions = (params?: ReportsBisQueryParams) =>
  queryOptions({
    queryKey: ['reports/bis', params],
    queryFn: () => reportsBisQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const reportsBisListQueryOptions = (params?: ReportsBisListQueryParams) =>
  queryOptions({
    queryKey: ['reports/bisList', params],
    queryFn: () => reportsBisListQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const reportsBisAggregateMetricsQueryOptions = (params?: ReportsBisAggregateMetricsQueryParams) =>
  queryOptions({
    queryKey: ['reports/bisAggregateMetrics', params],
    queryFn: () => reportsBisAggregateMetricsQueryFunction(params),
  })

export const reportsBisVariantsQueryOptions = (params: ReportsBisVariantsQueryParams) => {
  return queryOptions({
    queryKey: ['reports/bisVariants', params],
    queryFn: () => reportsBisVariantsQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })
}

export const bisListQueryOptions = (params: BisListQueryParams) => {
  return queryOptions({
    queryKey: ['bisList', params],
    queryFn: () => bisListQueryFunction(params),
  })
}

export const bisListContactQueryOptions = (params: BisListContactQueryParams) => {
  return queryOptions({
    queryKey: ['bisListContact', params],
    queryFn: () => bisListContactQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })
}

export const bisRestocksQueryOptions = (params: BisRestocksQueryParams) => {
  return queryOptions({
    queryKey: ['bisRestocks', params],
    queryFn: () => bisRestocksQueryFunction(params),
  })
}

// Shop Queries
export const settingsCheckAppEmbedQueryOptions = () =>
  queryOptions({
    queryKey: ['settings/checkAppEmbed'],
    queryFn: () => settingsCheckAppEmbedQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const checkoutProfilesQueryOptions = () =>
  queryOptions({
    queryKey: ['checkout-profiles'],
    queryFn: () => checkoutProfilesQueryFunction(),
    retry: false,
  })

export const dnsQueryOptions = () =>
  queryOptions({
    queryKey: ['dns'],
    queryFn: () => dnsQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const installStatusQueryOptions = () =>
  queryOptions({
    queryKey: ['install-status'],
    queryFn: () => installStatusQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const appPlanQueryOptions = (params?: AppPlanQueryParams) =>
  queryOptions({
    queryKey: ['appPlan', params],
    queryFn: () => appPlanQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const emailTemplatesQueryOptions = () =>
  queryOptions({
    queryKey: ['emailTemplates'],
    queryFn: () => emailTemplatesQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const localisationQueryOptions = () =>
  queryOptions({
    queryKey: ['localisation'],
    queryFn: () => localisationQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const settingsQueryOptions = () =>
  queryOptions({
    queryKey: ['settings'],
    queryFn: () => settingsQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const reportsUsageQueryOptions = () =>
  queryOptions({
    queryKey: ['reports/usage'],
    queryFn: () => reportsUsageQueryFunction(),
    staleTime: QUERY_STALE_TIME,
  })

export const widgetQueryOptions = (params: WidgetQueryParams) =>
  queryOptions({
    queryKey: ['widget', params],
    queryFn: () => widgetQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

// Shopify GraphQL Queries
export const shopifyResourcePickerQueryOptions = (params: ShopifyResourcePickerQueryParams) =>
  queryOptions({
    queryKey: ['shopify-resource-picker', params],
    queryFn: () => shopifyResourcePickerQueryFunction(params),
    select: (data) => {
      const productMap = new Map<string, ResourcePickerProduct>()

      // Process products
      data.products.nodes.forEach(({ id, title, variantsCount, featuredImage }) => {
        productMap.set(id, {
          id,
          title,
          totalVariants: variantsCount.count,
          featuredImage: featuredImage ? { url: featuredImage.url } : undefined,
          variants: [],
        })
      })

      // Process variants
      data.productVariants.nodes.forEach(({ id, title, product }) => {
        let productData = productMap.get(product.id)
        if (!productData) {
          productData = {
            id: product.id,
            title: product.title,
            totalVariants: product.variantsCount.count,
            featuredImage: product.featuredImage ? { url: product.featuredImage.url } : undefined,
            variants: [],
          }
          productMap.set(product.id, productData)
        }
        productData.variants.push({ id, title })
      })

      return Array.from(productMap.values())
    },
    staleTime: QUERY_STALE_TIME,
  })

// EXPERIMENTAL
export const shopifyProductQueryOptions = (params: ShopifyProductQueryParams) =>
  queryOptions({
    queryKey: ['shopify-product', params],
    queryFn: () => _shopifyProductQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const shopifyGetProductsQueryOptions = (params: ShopifyGetProductsQueryParams) =>
  queryOptions({
    queryKey: ['shopifyGetProducts', params],
    queryFn: () => shopifyGetProductsQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })

export const shopifyProductVariantsQueryOptions = (params: ShopifyProductVariantsQueryParams) =>
  queryOptions({
    queryKey: ['shopify-product-variants', params],
    queryFn: () => shopifyProductVariantsQueryFunction(params),
    staleTime: QUERY_STALE_TIME,
  })
