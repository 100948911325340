import { MetricBar } from '@/components/shared/MetricBar'
import { useTranslation } from 'react-i18next'
import { useI18n } from '@shopify/react-i18n'
import { useSuspenseQueries } from '@tanstack/react-query'
import { bisRestocksQueryOptions, reportsBisAggregateMetricsQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Card } from '@shopify/polaris'
import { FunnelChart } from '@shopify/polaris-viz'
import { formatNumber } from '@/common/functions'
import { RestockStatusBadge } from '../shared/RestockStatusBadge'
import { ConversionRatePercentage } from '@/components/shared/ConversionRatePercentage'
import { formatDateForShopify } from '@/common/datetime.ts'

const BackInStockProductChart = ({ productId }: { productId: string }) => {
  const [i18n] = useI18n()
  const { t } = useTranslation()

  const [{ data: settings }, { data: bisReportProduct }, { data: bisRestocks }] = useSuspenseQueries({
    queries: [
      settingsQueryOptions(),
      reportsBisAggregateMetricsQueryOptions({
        productId,
      }),
      bisRestocksQueryOptions({ id: productId, page: 1, limit: 5 }),
    ],
  })

  const { signups, sent, opens, conversionRate, totalAmount, totalOrders } = bisReportProduct[0]

  const latestRestock = bisRestocks.data.length ? bisRestocks.data[0] : null

  const placeholderData = [
    {
      data: [
        {
          value: signups,
          key: t('backInStockMetricsSignups'),
        },
        {
          value: sent,
          key: t('backInStockMetricsNotifications'),
        },
        {
          value: opens,
          key: t('backInStockMetricsOpens'),
        },
        {
          value: totalOrders,
          key: t('backInStockMetricsOrders'),
        },
      ],
    },
  ]

  return (
    <Card padding="400">
      <MetricBar
        blocks={[
          <MetricBar.ValueBlock title={t('backInStockMetricsSignups')} value={formatNumber(signups)} />,
          <MetricBar.ValueBlock title={t('backInStockMetricsNotifications')} value={formatNumber(sent)} />,

          <MetricBar.ValueBlock
            title={t('backInStockMetricsRevenue', {
              currency: settings.currency,
            })}
            value={i18n.formatCurrency(totalAmount, { currency: settings.currency })}
          />,

          <MetricBar.ValueBlock title={t('backInStockMetricsConversionRate')} value={<ConversionRatePercentage conversionRate={conversionRate} />} />,
          latestRestock && (
            <MetricBar.ValueBlock
              title={t('backInStockMetricsLatestRestock')}
              value={
                <RestockStatusBadge
                  status={latestRestock.status}
                  errorCode={latestRestock.errorCode}
                  isAutomaticSend={latestRestock.isAutomaticSend}
                  nextBatchScheduledAt={formatDateForShopify(latestRestock.nextBatchScheduledAt, settings.timezone)}
                />
              }
            />
          ),
        ]}
        chart={<FunnelChart data={placeholderData} />}
      ></MetricBar>
    </Card>
  )
}

export { BackInStockProductChart }
