import { Controller, useFormContext } from 'react-hook-form'
import { FormLayout, TextField } from '@shopify/polaris'
import { ColorPicker } from '@/components/shared/ColorPicker'
import { useTranslation } from 'react-i18next'

const BackorderWidgetSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <FormLayout>
      <Controller
        name="backorderAddToCartButtonText"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBackorderButtonLabel')}
            placeholder={t('displayBackorderButtonPlaceholder')}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error?.message}
          />
        )}
      />
      <FormLayout.Group>
        <Controller
          name="backorderAddToCartButtonBackgroundColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayBackorderButtonBackgroundColorLabel')}
              placeholder="#ffffff"
              defaultColor="#ffffff"
              onChange={onChange}
              color={value}
              error={error?.message}
              allowInherit={true}
            />
          )}
        />

        <Controller
          name="backorderAddToCartButtonTextColor"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ColorPicker
              label={t('displayBackorderButtonTextColorLabel')}
              placeholder="#000000"
              defaultColor="#000000"
              onChange={onChange}
              color={value ?? ''}
              error={error?.message}
              allowInherit={true}
            />
          )}
        />
      </FormLayout.Group>
      <Controller
        name="backorderNote"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('displayBackorderNoteLabel')}
            helpText={t('displayBackorderNoteDescription')}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error?.message}
            multiline={2}
          />
        )}
      />
    </FormLayout>
  )
}

export { BackorderWidgetSettings }
