import { Box, SkeletonBodyText, SkeletonDisplayText, Text } from '@shopify/polaris'
import { useFormContext } from 'react-hook-form'

const BackorderWidgetPreview = () => {
  const { watch } = useFormContext()

  const backorderAddToCartButtonText = watch('backorderAddToCartButtonText')
  const backorderAddToCartButtonTextColor = watch('backorderAddToCartButtonTextColor')
  const backorderAddToCartButtonBackgroundColor = watch('backorderAddToCartButtonBackgroundColor')
  const backorderNote = watch('backorderNote')

  return (
    <div
      style={{
        fontSize: '14px',
      }}
    >
      <Box>
        <SkeletonDisplayText size="small" />
        <Box minHeight="10px" />
        <Box width="80px">
          <SkeletonBodyText lines={1} />
        </Box>
        <Box minHeight="20px" />
        <SkeletonBodyText lines={3} />
        <Box minHeight="20px" />
        {backorderNote && (
          <>
            <Box minHeight="20px" />
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <Text as="p">{backorderNote}</Text>
            </div>
          </>
        )}
        <Box minHeight="20px" />
        <div
          style={{
            cursor: 'pointer',
            padding: '0.8rem',
            border: backorderAddToCartButtonBackgroundColor ? undefined : '1px solid #000',
            backgroundColor: backorderAddToCartButtonBackgroundColor ?? '#ffffff',
            color: backorderAddToCartButtonTextColor ?? '#000000',
          }}
        >
          <Text as="span" variant="bodyLg" alignment="center">
            {backorderAddToCartButtonText ? backorderAddToCartButtonText : 'Add back-order to cart'}
          </Text>
        </div>
      </Box>
    </div>
  )
}

export { BackorderWidgetPreview }
