import { Badge, BadgeProgressValue } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const CampaignStatusBadge = ({ status = '', withProgress = true }) => {
  const { t } = useTranslation()

  let progress: BadgeProgressValue
  let label: string
  let tone: 'info' | 'success' | 'warning' | 'attention' | undefined

  switch (status) {
    case 'ACTIVE':
      progress = BadgeProgressValue.Complete
      label = t('campaignStatusActive')
      tone = 'success'
      break
    case 'DRAFT':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('campaignStatusDraft')
      tone = undefined
      break
    case 'PAUSED':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('campaignStatusPaused')
      tone = 'attention'
      break
    case 'SCHEDULED':
      progress = BadgeProgressValue.Complete
      label = t('campaignStatusScheduled')
      tone = 'info'
      break
    case 'PROCESSING':
    case 'PROCESSING_PRODUCTS':
    case 'PROCESSING_CAMPAIGN':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('campaignStatusProcessing')
      tone = 'attention'
      break
    case 'ARCHIVED':
      progress = BadgeProgressValue.Complete
      label = t('campaignStatusArchived')
      tone = undefined
      break
    default:
      progress = BadgeProgressValue.PartiallyComplete
      label = ''
      tone = undefined
  }

  return label ? (
    <Badge progress={withProgress ? progress : undefined} tone={tone}>
      {label}
    </Badge>
  ) : null
}

export { CampaignStatusBadge }
