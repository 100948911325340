import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormLayout, Card, ChoiceList, TextField, BlockStack, Banner, Icon, Text, Tooltip } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import { CardSection } from '../CardSection'
import { InfoIcon } from '@shopify/polaris-icons'

const SendMethod = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext()

  const isAutomaticSend = watch('isAutomaticSend')

  const renderAutomaticYesChildren = (isSelected: boolean) => {
    return (
      isSelected && (
        <Controller
          name="automaticSendThreshold"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Box>
                <TextField
                  autoComplete="off"
                  label={
                    <div className="inlineFieldIcon">
                      <Text as="span">{t('backInStockFieldAutomaticSendThresholdLabel')}</Text>
                      <Tooltip content={t('backInStockSendMethodAutomaticInfoTooltip')}>
                        <Icon source={InfoIcon} tone="base" accessibilityLabel={t('backInStockSendMethodAutomaticInfoTooltip')} />
                      </Tooltip>
                    </div>
                  }
                  type="number"
                  min={1}
                  max={2147483647}
                  helpText={t('backInStockFieldAutomaticThresholdDescription')}
                  value={field.value?.toString() ?? undefined}
                  onChange={(val) => {
                    const parsed = parseInt(val, 10)
                    field.onChange(isNaN(parsed) ? null : parsed)
                  }}
                  error={error?.message}
                />
              </Box>
            )
          }}
        />
      )
    )
  }

  return (
    <Card>
      <BlockStack gap="400">
        <CardSection>
          <CardHeader title={t('backInStockSendMethodCardTitle')} description={t('backInStockSendMethodCardDescription')} />
          {!isAutomaticSend && (
            <Banner tone="warning">
              <p>{t('backInStockSendMethodManualWarningBanner')}</p>
            </Banner>
          )}
          <FormLayout>
            <Controller
              name="isAutomaticSend"
              control={control}
              render={({ field: { onChange, value } }) => {
                const stringValue = value.toString()
                return (
                  <ChoiceList
                    title=""
                    choices={[
                      {
                        label: t('backInStockSendMethodAutomaticYes'),
                        value: 'true',
                        renderChildren: renderAutomaticYesChildren,
                      },
                      { label: t('backInStockSendMethodAutomaticNo'), value: 'false' },
                    ]}
                    onChange={(values) => onChange(values[0] === 'true')}
                    selected={[stringValue]}
                  />
                )
              }}
            />
          </FormLayout>
        </CardSection>
      </BlockStack>
    </Card>
  )
}

export { SendMethod }
