declare global {
  interface Window {
    Beacon: any
    onYouTubeIframeAPIReady: () => void
  }
}

/**
 * App Plan Types
 */
export type AppPlan = {
  uuid: string
  key: string
  name: string
  price: number
  interval: 'EVERY_30_DAYS' | 'ANNUAL'
  monthlyOrderLimit: number | null
  monthlyEmailLimit: number | null
  monthlySmsLimit: number | null
  featurePartialPayments: boolean
  featureCustomEmailDomain: boolean
  featureCustomEmailTemplates: boolean
  createdAt: string
  isCustom: boolean
  app_plan_code?: AppPlanCode
}

export type FetchAppPlansResponse = AppPlan[]

export type AppPlanCode = {
  uuid: string
  appPlanId: string
  code: string
  redeemed: number
  total: number | null
  trialDays: number | null
  discountPercentage: number | null
  discountDuration: number | null
  createdAt: string
  app_plan: AppPlan
}

/**
 * Campaign Types
 */
export type Campaign = {
  amount: number
  createdAt: string
  endDate?: string
  financialStatus: FinancialStatus
  fulfillmentDate: string | null
  fulfillmentStatus: FulfillmentStatus
  fulfillmentTrigger: 'UNKNOWN' | 'EXACT_TIME' | 'ASAP'
  hasEndDate: boolean
  hasStockLimit: boolean
  isActive: boolean
  inventoryReserve: 'ON_SALE' | 'ON_FULFILLMENT'
  isContinueSellingManaged: boolean
  archivedAt: string | null
  isFulfillmentDelayedPending: boolean
  isPublished: boolean
  jobId: string | null
  name: string
  orderId: string
  paymentDueOn: string | null
  paymentMandateId: string | null
  preorderTrigger: 'ALWAYS' | 'IN_STOCK'
  products: {
    id: string
    variants: {
      id: string
    }[]
  }[]
  orderCount?: number
  primaryRevenue?: number | null
  productIds: string[]
  scheduleStatus: 'SCHEDULED' | 'UPDATING_PRODUCTS' | 'UPDATING_CAMPAIGN' | 'COMPLETE'
  sellingPlans: {
    full: SellingPlan
    partial: SellingPlan
  }
  shopId: string
  startDate: string
  status: 'DRAFT' | 'ACTIVE' | 'PAUSED' | 'ARCHIVED' | 'SCHEDULED' | 'PROCESSING' | 'PROCESSING_PRODUCTS' | 'PROCESSING_CAMPAIGN'
  stockLimit: number | null
  publishedAt: string | null
  uuid: string
  variantIds: string[]
  widgetId: string
  latestPreorderDelayed: {
    uuid: string
    shopId: string
    emailTemplateId: string
    parentType: 'PRE_ORDER' | 'BACK_IN_STOCK'
    parentId: string
    createdAt: string
    queueStatus: 'SCHEDULED' | 'QUEUEING' | 'SENDING' | 'COMPLETE' | 'ERROR'
    messagesQueuedCount: number
  }
  preordersCount: number
}

export type FetchCampaignsResponse = Campaign[]
export type FetchCampaignResponse = Campaign
export type MutateCampaignPayload = Omit<Campaign, 'shopId' | 'createdAt' | 'deleted'> & {
  deleted: {
    products: {
      id: string
      variants: {
        id: string
      }[]
    }[]
  }
}
export type MutateCampaignResponse = Campaign

/**
 * Email Types
 */
export type EmailTemplate = {
  uuid: string
  shopId: string
  type: 'PRE_ORDER_DELAYED' | 'BACK_IN_STOCK'
  subject: string | null
  title: string | null
  body: string | null
  primaryButtonText: string | null
  defaultTemplateHtml: string
  hasCustomTemplate: boolean
  templateHtml: string | null
  createdAt: string
}

export type FetchEmailTemplatesResponse = Record<'preOrderDelayed' | 'backInStock', EmailTemplate>
export type MutateEmailTemplatesPayload = Record<
  'preOrderDelayed' | 'backInStock',
  Omit<EmailTemplate, 'shopId' | 'defaultTemplateHtml' | 'type' | 'createdAt'>
>
export type MutateEmailTemplatesResponse = FetchEmailTemplatesResponse

/**
 * Selling Plan Types
 */
export type SellingPlan = {
  uuid: string
  shopId: string
  sellingPlanId: string
  campaignId: string
  createdAt: string
  name: string
  isActive: boolean
  partialAmount: number
  partialType: 'PERCENTAGE' | 'FIXED'
  finalPaymentTrigger: 'EXACT_TIME' | 'TIME_AFTER_CHECKOUT'
  paymentDueDate: string
  numberOfDays: number
  hasDiscount: boolean
  discountAmount: number
  discountType: 'PERCENTAGE' | 'FIXED'
}

export type FetchSellingPlansResponse = SellingPlan[]

/**
 * Settings Types
 */
export type Settings = {
  uuid: string
  shopId: string
  shopName: string
  enabled: boolean
  onboarded: boolean
  themeId: string
  themeName: string
  themeVersion: string
  timezone: string
  currency: string
  createdAt: string
  widgetId: string
  dateFormat: string | null
  customCss: string | null
  hasCustomDomain: boolean
  monthlyEmailLimit: number
  monthlyOrderLimit: number
  monthlySmsLimit: number
  productLimit: number
  emailFromName: string | null
  emailFromEmail: string | null
  emailReplyToEmail: string | null
  emailDomainId: string | null
  emailDomainStatus: string | null
  variantFieldSelector: string | null
  quantityFieldSelector: string | null
  addToCartSelector: string | null
  buyItNowSelector: string | null
  productPriceSelector: string | null
  collectionItemsSelector: string | null
  collectionButtonSelector: string | null
  collectionItemImageSelector: string | null
  backorderEnabled: boolean
  backorderIsContinueSellingManaged: boolean
  backorderSelectionType: 'INCLUDE' | 'EXCLUDE'
  backorderProductIds: string[] | null
  backorderStatus:
    | 'COMPLETE'
    | 'ANALYZING'
    | 'ANALYZING_MIXED'
    | 'DISCOVERING'
    | 'UPLOADING'
    | 'UPDATING_PRODUCTS_ON'
    | 'UPDATING_PRODUCTS_OFF'
    | 'UPDATING_PRODUCTS_MIXED'
    | 'ERROR'
  backorderCurrentProductCount: number | null
  backorderTotalProductCount: number | null
  backorderBulkMutationId: string | null
  backorderLastError: string | null
  dismissFlags: Record<string, boolean> | null
  featurePartialPayments: boolean
  featureCustomEmailDomain: boolean
  featureCustomEmailTemplates: boolean
  usagePreorderLimitStatus: 'ACTIVE' | 'APPROACHING' | 'GRACE' | 'EXCEEDED'
  usageEmailLimitStatus: 'ACTIVE' | 'APPROACHING' | 'GRACE' | 'EXCEEDED'
  bisEnabled: boolean
  bisSettingsId: string
  bisProductIds: string[] | null
  bisSelectionType: 'INCLUDE' | 'EXCLUDE'
}

export type FetchSettingsResponse = Settings
export type MutateSettingsPayload = Omit<Settings, 'uuid' | 'shopId' | 'enabled' | 'themeId' | 'themeName' | 'themeVersion' | 'createdAt' | 'widgetId'>
export type MutateSettingsResponse = Settings

export type BISSettings = {
  uuid: string
  shopId?: string
  isAutomaticSend: boolean
  automaticSendThreshold: number | null
  batchMultiplier: number
  batchDelay: number
  sortingMethod: 'OLDEST' | 'NEWEST'
}

export type FetchBISSettingsResponse = BISSettings

export type MutateBISPayload = {
  bisEnabled?: boolean
  bisSettingsId?: string | null
  bisProductIds: string[] | null
  bisSelectionType: 'INCLUDE' | 'EXCLUDE'
  bis_setting: BISSettings
}

/**
 * Widget Types
 */
export type PreorderWidgetSettings = {
  addToCartButtonText: string | null
  addToCartButtonTextColor: string | null
  addToCartButtonBackgroundColor: string | null
  sellingPlanBadgeTextColor: string | null
  sellingPlanBadgeBackgroundColor: string | null
  partialPaymentNote: string | null
  fulfilmentDateNote: string | null
  comingSoonAddToCartButtonText: string | null
  soldOutAddToCartButtonText: string | null
  comingSoonNote: string | null
  soldOutNote: string | null
}

export type BackorderWidgetSettings = {
  backorderAddToCartButtonText: string | null
  backorderAddToCartButtonTextColor: string | null
  backorderAddToCartButtonBackgroundColor: string | null
  backorderNote: string | null
}

export type BisWidgetSettings = {
  bisButtonTextColor: string | null
  bisButtonBackgroundColor: string | null
  bisButtonText: string | null
  bisModalTextColor: string | null
}

export type Widget = {
  uuid: string
  shopId: string
  campaignId: string
  createdAt: string
  showPreorderCollectionBadge: boolean
  showBackorderCollectionBadge: boolean
  purchaseOptionBadgeTextColor: string | null
  purchaseOptionBadgeBackgroundColor: string | null
} & PreorderWidgetSettings &
  BackorderWidgetSettings &
  BisWidgetSettings

export type FetchWidgetResponse = Widget
export type MutateWidgetPayload = Omit<Widget, 'uuid' | 'shopId' | 'campaignId' | 'createdAt'>
export type MutateWidgetResponse = Widget

export type DNSRecord = {
  record: string
  name: string
  type: string
  ttl: string
  status: string
  value: string
  priority?: number
}

export type FetchDNSResponse = {
  records: DNSRecord[]
}

export type FetchAppEmbedResponse = {
  message: boolean
}

export type FetchCampaignUnavailableProductsResponse = {
  products: {
    uuid: string
    productId: string
    campaignId: string
    campaignName: string
  }[]
  variants: {
    uuid: string
    variantId: string
    campaignId: string
    campaignName: string
  }[]
}

export type FetchShopifyProducts = {
  products: {
    nodes: {
      id: string
      title: string
      featuredImage: {
        url: string
      }
      variantsCount: {
        count: number
      }
    }[]
  }
}

export type FetchShopifyCampaignProducts = {
  products: {
    nodes: {
      id: string
      title: string
      featuredImage: {
        url: string
      }
      variantsCount: {
        count: number
      }
    }[]
  }
  productVariants: {
    nodes: {
      id: string
      title: string
      product: {
        id: string
        title: string
        featuredImage: {
          url: string
        }
        variantsCount: {
          count: number
        }
      }
    }[]
  }
}

export type Order = {
  apiData: {
    id: string
    legacyResourceId: string
    name: string
    totalPriceSet: {
      shopMoney: {
        amount: number
        currencyCode: string
      }
    }
    displayFulfillmentStatus: FulfillmentStatus
    fullyPaid?: boolean
    customer: Customer
    lineItems: {
      nodes: {
        id: string
        currentQuantity: number
        refundableQuantity: number
      }[]
    }
    displayFinancialStatus: FinancialStatus
  }
  order: {
    uuid: string
    shopId: string
    orderId: string
    name: string
    amount: number
    currency: string | null
    createdAt: string
    paymentStatus: PaymentStatus
    paymentMandateId: string | null
    paymentDueOn: string | null
    jobId: string | null
    fulfillmentStatus: FulfillmentStatus
    financialStatus: FinancialStatus
    currentSubtotalAmount: number
    totalAmount: number
    itemCount: number
  }
}

export type Backorder = {
  apiData: {
    id: string
    legacyResourceId: string
    name: string
    totalPriceSet: {
      shopMoney: {
        amount: number
        currencyCode: string
      }
    }
    displayFulfillmentStatus: FulfillmentStatus
    fullyPaid?: boolean
    customer: Customer
  }
  order: {
    uuid: string
    shopId: string
    orderId: string
    name: string
    amount: number
    createdAt: string
    preorder_line_item?: any[] // Replace 'any' with the actual type if known
    backorder_line_item?: any[] // Replace 'any' with the actual type if known
  }
}

export type Customer = {
  id: string
  legacyResourceId: string
  firstName: string
  lastName: string
  email: string
  phone: string | null
  defaultAddress: {
    address1: string
    formattedArea: string
  }
}

export type FetchOrdersResponse = {
  data: Order[]
  total: number
  hasNext: boolean
}

export type FetchProductResponse = {
  product: {
    id: string
    title: string
    legacyResourceId: string
    featuredImage: {
      altText?: string
      url: string
    }
  }
}

export type FetchFulfillmentOrdersResponse = {
  order: {
    legacyResourceId: string
    fulfillmentOrders: {
      nodes: {
        id: string
        status: FulfillmentStatus
        assignedLocation: {
          name: string
        }
        deliveryMethod: {
          methodType: 'LOCAL' | 'NONE' | 'PICK_UP' | 'RETAIL' | 'SHIPPING'
        }
        lineItems: {
          nodes: {
            id: string
            sku: string
            productTitle: string
            variantTitle: string | null
            image: {
              altText?: string
              url: string
            }
            remainingQuantity: number
            totalQuantity: number
          }[]
        }
      }[]
    }
  }
}

export type FulfillmentStatus =
  | 'UNFULFILLED'
  | 'SCHEDULED'
  | 'RESTOCKED'
  | 'PENDING_FULFILLMENT'
  | 'PARTIALLY_FULFILLED'
  | 'OPEN'
  | 'ON_HOLD'
  | 'IN_PROGRESS'
  | 'FULFILLED'
  | 'CLOSED'

export type FinancialStatus = 'PAID' | 'PARTIALLY_PAID' | 'PARTIALLY_REFUNDED' | 'PENDING' | 'REFUNDED' | 'VOIDED' | 'AUTHORIZED' | 'EXPIRED'
export type PaymentStatus = 'AWAITING_DEFERRED' | 'QUEUED' | 'PROCESSING' | 'PAID' | 'ERROR'

export type PreorderTableTab = {
  slug: 'all' | 'unfulfilled' | 'fulfilled' | 'refunded' | 'scheduled' | 'on-hold' | 'partially-paid'
  label: string
}

export type BackorderTableTab = {
  slug: 'all' | 'unfulfilled' | 'fulfilled' | 'refunded' | 'scheduled' | 'on-hold'
  label: string
}

export type TableTab = {
  index: number
  slug: string
  label: string
}

export type ResourcePickerProduct = {
  id: string
  title: string
  featuredImage?: {
    url: string
  }
  variants: {
    id: string
    title: string
  }[]
  totalVariants?: number
}

export type ShopifyProduct = {
  id: string
  title: string
  featuredImage?: {
    url: string
  }
  variants?: {
    id: string
  }[]
  totalVariants?: number
}

export type FetchInstallStatusResponse = {
  status: boolean
  message: string
}

export type EmailEventType =
  | 'email.sent'
  | 'email.delivered'
  | 'email.delivery_delayed'
  | 'email.complained'
  | 'email.bounced'
  | 'email.opened'
  | 'email.clicked'

/**
 * Localisation Types
 */

export const DateLocales = [
  'af',
  'am',
  'ar-dz',
  'ar-iq',
  'ar-kw',
  'ar-ly',
  'ar-ma',
  'ar-sa',
  'ar-tn',
  'ar',
  'az',
  'be',
  'bg',
  'bi',
  'bm',
  'bn-bd',
  'bn',
  'bo',
  'br',
  'bs',
  'ca',
  'cs',
  'cv',
  'cy',
  'da',
  'de-at',
  'de-ch',
  'de',
  'dv',
  'el',
  'en-au',
  'en-ca',
  'en-gb',
  'en-ie',
  'en-il',
  'en-in',
  'en-nz',
  'en-sg',
  'en-tt',
  'en',
  'eo',
  'es-do',
  'es-mx',
  'es-pr',
  'es-us',
  'es',
  'et',
  'eu',
  'fa',
  'fi',
  'fo',
  'fr-ca',
  'fr-ch',
  'fr',
  'fy',
  'ga',
  'gd',
  'gl',
  'gom-latn',
  'gu',
  'hi',
  'he',
  'hr',
  'ht',
  'hu',
  'hy-am',
  'id',
  'is',
  'it-ch',
  'it',
  'ja',
  'jv',
  'ka',
  'kk',
  'km',
  'kn',
  'ko',
  'ku',
  'ky',
  'lb',
  'lo',
  'lt',
  'lv',
  'me',
  'mi',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms-my',
  'ms',
  'mt',
  'my',
  'nb',
  'ne',
  'nl-be',
  'nl',
  'nn',
  'oc-lnc',
  'pa-in',
  'pl',
  'pt-br',
  'pt',
  'rn',
  'ro',
  'sd',
  'si',
  'se',
  'sk',
  'sl',
  'sq',
  'sr-cyrl',
  'sr',
  'ss',
  'sv-fi',
  'sv',
  'sw',
  'ta',
  'te',
  'tg',
  'tet',
  'th',
  'tk',
  'tl-ph',
  'tlh',
  'tr',
  'tzl',
  'tzm-latn',
  'ug-cn',
  'tzm',
  'uk',
  'ur',
  'uz-latn',
  'vi',
  'uz',
  'yo',
  'x-pseudo',
  'zh-cn',
  'zh-hk',
  'zh-tw',
  'zh',
  'rw',
  'ru',
] as const

export type Localization = {
  uuid: string
  shopId: string
  dateFormat: string
  dateLocale: (typeof DateLocales)[number] // sorcery, but it works
  purchaseOptionLabel: string | null
  preorderLabel: string | null
  backorderLabel: string | null
  expectedShipDatePrefix: string | null
  quickAddButtonCta: string | null
  discountPrefix: string | null
  asapLabel: string | null
  unknownLabel: string | null
  createdAt: string
}

export type FetchLocalizationResponse = Localization
export type MutateLocalizationPayload = Omit<Localization, 'uuid' | 'shopId' | 'createdAt'>
