import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { Layout } from '@shopify/polaris'
import { SaveBar } from '@shopify/app-bridge-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { MutateSettingsPayload } from '@/common/types'
import { z } from 'zod'
import { useMutateSettings } from '@/hooks/useMutateSettings'
import { AdvancedSettings } from './AdvancedSettings'
import { EmailSettings } from './EmailSettings'
import { useTranslation } from 'react-i18next'
import { Disabled } from '../shared/Disabled'
import useScrollToError from '@/hooks/useScrollToError'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'

const schema = z.object({
  customCss: z.string().nullable().default(null),
  hasCustomDomain: z.boolean().default(false),
  emailFromName: z.string().trim().nullable().default(null),
  emailFromEmail: z.string().trim().nullable().default(null),
  emailReplyToEmail: z.string().trim().nullable().default(null),
  emailDomainId: z.string().nullable().default(null),
  emailDomainStatus: z.string().nullable().default(null),
  variantFieldSelector: z.string().trim().nullable().default(null),
  quantityFieldSelector: z.string().trim().nullable().default(null),
  addToCartSelector: z.string().trim().nullable().default(null),
  buyItNowSelector: z.string().trim().nullable().default(null),
  productPriceSelector: z.string().trim().nullable().default(null),
  collectionItemsSelector: z.string().trim().nullable().default(null),
  collectionButtonSelector: z.string().trim().nullable().default(null),
  collectionItemImageSelector: z.string().trim().nullable().default(null),
})

export type FormSchema = z.infer<typeof schema>

const SettingsForm = () => {
  const { t } = useTranslation()
  const { data } = useSuspenseQuery(settingsQueryOptions())

  const form = useForm<MutateSettingsPayload>({
    defaultValues: schema.parse(data ?? {}),
    resolver: zodResolver(
      schema.refine(
        (data) => {
          // If hasCustomDomain is true, emailFromEmail must be a valid email
          if (data.hasCustomDomain) {
            return z.string().email().safeParse(data.emailFromEmail).success
          }

          return true
        },
        {
          // Custom error message
          message: t('settingsValidationFromEmailRequired'),
          path: ['emailFromEmail'],
        }
      )
    ),
    mode: 'onChange',
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const mutateSettings = useMutateSettings()

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onError: SubmitErrorHandler<MutateSettingsPayload> = () => {
    setCanFocus(true)

    shopify.toast.show(t('genericErrorToast'), {
      duration: 2000,
      isError: true,
    })
  }

  const onSubmit: SubmitHandler<MutateSettingsPayload> = async (formValues) => {
    try {
      await mutateSettings.mutateAsync(formValues, {
        onSuccess: (data) => {
          reset(schema.parse(data))

          shopify.toast.show(t('settingsUpdatedToast'), {
            duration: 2000,
          })

          return data
        },
      })
    } catch (error) {
      console.log(error)
      shopify.toast.show(t('genericErrorToast'), {
        duration: 2000,
        isError: true,
      })
    }
  }

  return (
    <FormProvider {...form}>
      <SaveBar open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={mutateSettings.isPending}
          loading={mutateSettings.isPending ? '' : undefined}
        />
        <button onClick={() => reset()} disabled={mutateSettings.isPending} />
      </SaveBar>
      <Layout.AnnotatedSection title={t('settingsEmailTitle')} description={t('settingsEmailDescription')}>
        <Disabled isDisabled={mutateSettings.isPending}>
          <EmailSettings />
        </Disabled>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={t('settingsAdvancedTitle')} description={t('settingsAdvancedDescription')}>
        <Disabled isDisabled={mutateSettings.isPending}>
          <AdvancedSettings />
        </Disabled>
      </Layout.AnnotatedSection>
    </FormProvider>
  )
}

export { SettingsForm }
